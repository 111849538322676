import React, { useState, useEffect, useMemo } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingBox, MessageBox } from '../../../components'
import Select from 'react-select'
import { createService } from '../../../actions/adminActions/serviceActions/createService'
import { SERVICE_PROVIDER_CREATE_RESET } from '../../../constants/adminConstants/serviceConstants'
import { AllBenefits } from '../../../actions/adminActions/benefitsActions/allBenefits'
import { listManufacuturers } from '../../../actions/adminActions/manufactuerActions/listManufacturers'
import NaijaStates from 'naija-state-local-government'
import states from '../../../utilis'
import { generatePassword } from '../../../components/functions'
import { allServiceCategory } from '../../../actions/adminActions/serviceActions/allServiceCategory'

export default function AddNewPage() {
  const [uniqueCode, setUniqueCode] = useState('')
  const [name, setName] = useState('')
  const [benefit, setBenefit] = useState([])
  const [category, setCategory] = useState([])
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [manufacturer, setManufacturer] = useState('')
  const [state, setState] = useState('')
  const [origin, setOrigin] = useState('')
  const [lga, setLga] = useState('')
  const [inputList, setinputList] = useState([{ lga: '', details: '' }])
  const [password, setPassword] = useState('')

  useEffect(() => {
    dispatch(AllBenefits())
    dispatch(listManufacuturers())
    dispatch(allServiceCategory())

    if (newService) {
      setUniqueCode(`FBSP-${Math.floor(Math.random() * 9000) + 1000}`)
      setTimeout(() => {
        dispatch({ type: SERVICE_PROVIDER_CREATE_RESET })
      }, 2500)
    }
  }, [dispatch, newService])

  const handleinputchange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    list[index]['state'] = state
    setinputList(list)
  }

  const handleremove = (index) => {
    const list = [...inputList]
    list.splice(index, 1)
    setinputList(list)
  }

  const handleaddclick = () => {
    setinputList([...inputList, { lga: '', details: '' }])
  }

  const dispatch = useDispatch()

  // Benefit State
  const benefitsList = useSelector((state) => state.benefitsList)
  const {
    loading: loadingBenefit,
    error: benefitsErr,
    allbenefits,
  } = benefitsList

  // New Benefit State
  const serviceCreate = useSelector((state) => state.serviceCreate)
  const {
    loading: loadingCreate,
    newService,
    error: errorCreate,
  } = serviceCreate

  const manufacturersList = useSelector((state) => state.manufacturersList)
  const { loading: loadingMaufacturer, manufacturers } = manufacturersList

  const serviceCategory = useSelector((state) => state.serviceCategory)
  const { loading: loadingCategory, categorylist } = serviceCategory

  const createBenefitHandler = (e) => {
    e.preventDefault()

    if (inputList && inputList[0] && inputList[0].lga === '') {
      dispatch(
        createService(
          uniqueCode,
          manufacturer,
          name,
          category,
          benefit,
          email,
          phone,
          address,
          origin,
          lga,
          password,
        ),
      )
      setPassword('')
    } else {
      dispatch(
        createService(
          uniqueCode,
          manufacturer,
          name,
          category,
          benefit,
          email,
          phone,
          address,
          origin,
          lga,
          password,
          inputList,
        ),
      )
      setPassword('')
    }
  }

  const myFunction = () => {
    var x = document.getElementById('visible')
    var y = document.getElementById('visibleText')
    if (x.type === 'password') {
      x.type = 'text'
      y.textContent = 'Hide Password'
    } else {
      x.type = 'password'
      y.textContent = 'Show Password'
    }
  }

  const handlePassword = (e) => {
    e.preventDefault()
    setPassword(generatePassword())
  }

  useMemo(() => {
    setUniqueCode(`FBSP-${Math.floor(Math.random() * 9000) + 1000}`)
  }, [])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">New Service Provider</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Service Providers</a>
                      </li>
                      <li className="breadcrumb-item active">
                        New Service Provider
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {loadingCreate ? (
              <LoadingBox />
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  {benefitsErr ? (
                    <MessageBox variant="success">
                      {benefitsErr.message}
                    </MessageBox>
                  ) : null}
                  {newService ? (
                    <MessageBox variant="success">
                      {newService.message}
                    </MessageBox>
                  ) : null}
                  {errorCreate ? (
                    <MessageBox variant="danger">{errorCreate}</MessageBox>
                  ) : null}
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={createBenefitHandler}>
                        <div className="mb-1">
                          <div className="row">
                            <div className="col-md-6">
                              <label
                                className="form-label"
                                forHtml="ProductName"
                              >
                                Select Organisation
                              </label>
                              <select
                                onChange={(e) =>
                                  setManufacturer(e.target.value)
                                }
                                className="select2 form-select"
                                required
                              >
                                <option>Select</option>
                                {loadingMaufacturer ? (
                                  'Loading...'
                                ) : manufacturers.length > 0 ? (
                                  <>
                                    {manufacturers.map((item) => (
                                      <option value={item._id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </>
                                ) : (
                                  'There is no organisation'
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="mb-1">
                          <div className="row">
                            <div className="col-md-6">
                              <label
                                className="form-label"
                                forHtml="ProductName"
                              >
                                Service Category
                              </label>
                              {loadingCategory ? (
                                'Loading...'
                              ) : categorylist.length === 0 ? (
                                <div className='p-2 bg-soft-danger r-4'>Please create a category</div>
                              ) : (
                                <Select
                                  onChange={(opt) => setCategory(opt)}
                                  required
                                  isMulti
                                  options={categorylist.map((opt) => ({
                                    label: opt.name,
                                    value: opt._id,
                                  }))}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-12">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Unique Code
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Unique Code"
                              id="example-text-input"
                              onChange={(e) => setUniqueCode(e.target.value)}
                              value={uniqueCode}
                              required
                              disabled
                            />
                          </div>

                          <div className="col-md-12">
                            <label
                              htmlFor="example-text-input2"
                              className="col-md-12 col-form-label"
                            >
                              Name
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Name"
                              id="example-text-input2"
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </div>

                          <div className="col-md-12">
                            <label
                              htmlFor="example-text-input3"
                              className="col-md-12 col-form-label"
                            >
                              Service Benefit
                            </label>
                            <Select
                              onChange={(opt) => setBenefit(opt)}
                              required
                              isMulti
                              options={
                                loadingBenefit ? (
                                  'Loading...'
                                ) : allbenefits.length === 0 ? (
                                  <div>Please create a benefit</div>
                                ) : (
                                  allbenefits.map((opt) => ({
                                    label: opt.benefit,
                                    value: opt._id,
                                  }))
                                )
                              }
                            />
                          </div>

                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input4"
                              className="col-md-12 col-form-label"
                            >
                              Email
                            </label>
                            <input
                              className="form-control"
                              type="email"
                              placeholder="Email"
                              id="example-text-input4"
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>

                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input5"
                              className="col-md-12 col-form-label"
                            >
                              Phone
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Phone"
                              id="example-text-input5"
                              onChange={(e) => setPhone(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input6"
                              className="col-md-12 col-form-label"
                            >
                              Select a state
                            </label>
                            <select
                              className="form-select form-control select2"
                              required
                              placeholder="Select a state"
                              onChange={(e) => setOrigin(e.target.value)}
                            >
                              <option>Select a state</option>
                              {states.map((item) => (
                                <option value={item}>{item}</option>
                              ))}
                            </select>
                          </div>

                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input7"
                              className="col-form-label"
                            >
                              Select a local government area
                            </label>
                            <select
                              className="form-select form-control select2"
                              required
                              name="lga"
                              placeholder="Select a local government area"
                              onChange={(e) => setLga(e.target.value)}
                            >
                              {origin && origin === 'Select a state' ? (
                                <option>Select a local government area</option>
                              ) : origin ? (
                                <>
                                  <option>
                                    Select a local government area
                                  </option>
                                  {NaijaStates.lgas(origin).lgas.map((item) => (
                                    <option value={item}>{item}</option>
                                  ))}
                                </>
                              ) : (
                                <option>Select a local government area</option>
                              )}
                            </select>
                          </div>

                          <div className="col-md-12">
                            <label
                              className="col-md-12 col-form-label"
                              forHtml="address"
                            >
                              Address
                            </label>
                            <input
                              id="address"
                              name="address"
                              type="text"
                              className="form-control form-control"
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-4">
                            <div className="mb-3">
                              <label className="form-label" htmlFor="password">
                                Password
                              </label>
                              <input
                                id="visible"
                                name="password"
                                type="password"
                                className="form-control"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 ">
                            <span
                              className="form-control btn btn-sm btn-password toggleText"
                              id="visibleText"
                              onClick={myFunction}
                            >
                              Show Password
                            </span>

                            <div className="mt-2">
                              <button
                                className="form-control btn btn-sm btn-success"
                                onClick={handlePassword}
                              >
                                Generate Password
                              </button>
                            </div>
                          </div>
                        </div>

                        <h4 className="card-title">SERVICE COVERAGE</h4>

                        <div className="col-md-6 mb-3 mt-1">
                          <label htmlFor="example-text-input8">
                            Select a state
                          </label>
                          <select
                            className="form-select form-control select2"
                            required
                            placeholder="Select a state"
                            onChange={(e) => setState(e.target.value)}
                          >
                            <option>Select a state</option>
                            {states.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                        </div>

                        {inputList.map((x, i) => {
                          return (
                            <div className="row mb-3">
                              <div className="form-group col-md-8 mb-2">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-12 col-form-label"
                                >
                                  Select a local government area
                                </label>
                                <select
                                  className="form-select form-control select2"
                                  required
                                  name="lga"
                                  placeholder="Select a local government area"
                                  onChange={(e) => handleinputchange(e, i)}
                                >
                                  {state && state === 'Select a state' ? (
                                    <option>
                                      Select a local government area
                                    </option>
                                  ) : state ? (
                                    <>
                                      <option>
                                        Select a local government area
                                      </option>
                                      {NaijaStates.lgas(state).lgas.map(
                                        (item) => (
                                          <option value={item}>{item}</option>
                                        ),
                                      )}
                                    </>
                                  ) : (
                                    <option>
                                      Select a local government area
                                    </option>
                                  )}
                                </select>
                              </div>
                              <div className="form-group col-md-8">
                                <textarea
                                  type="text"
                                  name="details"
                                  className="form-control"
                                  onChange={(e) => handleinputchange(e, i)}
                                  placeholder="Address"
                                />
                              </div>
                              <div className="form-group col-md-4 mt-4">
                                {inputList.length !== 1 && (
                                  <button
                                    className="btn btn-danger mx-1"
                                    type="button"
                                    onClick={() => handleremove(i)}
                                  >
                                    -
                                  </button>
                                )}
                                {inputList.length - 1 === i && (
                                  <button
                                    className="btn btn-success"
                                    onClick={handleaddclick}
                                  >
                                    +
                                  </button>
                                )}
                              </div>
                            </div>
                          )
                        })}

                        <button
                          className="btn btn-success btn-bg"
                          type="submit"
                          onSubmit={createBenefitHandler}
                        >
                          Create Service Prodvider
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
