import {
  VERIFY_WALLET_FUND_FAIL,
  VERIFY_WALLET_FUND_REQUEST,
  VERIFY_WALLET_FUND_SUCCESS,
  WALLET_BALANCE_FAIL,
  WALLET_BALANCE_REQUEST,
  WALLET_BALANCE_SUCCESS,
  WALLET_FUND_FAIL,
  WALLET_FUND_REQUEST,
  WALLET_FUND_RESET,
  WALLET_FUND_SUCCESS,
  SEND_FUND_REQUEST,
  SEND_FUND_SUCCESS,
  SEND_FUND_FAIL,
  SEND_FUND_RESET,
  WALLET_TRANSACTIONS_REQUEST,
  WALLET_TRANSACTIONS_SUCCESS,
  WALLET_TRANSACTIONS_FAIL,
  VERIFY_WALLET_USERID_REQUEST,
  VERIFY_WALLET_USERID_SUCCESS,
  VERIFY_WALLET_USERID_FAIL,
  VERIFY_WALLET_USERID_RESET,
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_SUCCESS,
  VERIFY_ACCOUNT_FAIL,
  VERIFY_ACCOUNT_RESET,
  WITHDRAW_FUND_REQUEST,
  WITHDRAW_FUND_SUCCESS,
  WITHDRAW_FUND_FAIL,
  WITHDRAW_FUND_RESET,
  INITIATE_ESCROW_TRANSFER_REQUEST,
  INITIATE_ESCROW_TRANSFER_SUCCESS,
  INITIATE_ESCROW_TRANSFER_FAIL,
  INITIATE_ESCROW_TRANSFER_RESET,
  SECURE_TRANSFER_REQUEST,
  SECURE_TRANSFER_SUCCESS,
  SECURE_TRANSFER_FAIL,
  ALL_SECURE_FUNDS_REQUEST,
  ALL_SECURE_FUNDS_SUCCESS,
  ALL_SECURE_FUNDS_FAIL,
} from '../../constants/authConstants/walletConstants'

export const walletBalanceReducer = (state = {}, action) => {
  switch (action.type) {
    case WALLET_BALANCE_REQUEST:
      return { loading: true }
    case WALLET_BALANCE_SUCCESS:
      return { loading: false, wallet: action.payload }
    case WALLET_BALANCE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const walletFundReducer = (state = {}, action) => {
  switch (action.type) {
    case WALLET_FUND_REQUEST:
      return { loading: true }
    case WALLET_FUND_SUCCESS:
      return { loading: false, codeId: action.payload }
    case WALLET_FUND_FAIL:
      return { loading: false, error: action.payload }
    case WALLET_FUND_RESET:
      return {}
    default:
      return state
  }
}

export const verifyWalletFundReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_WALLET_FUND_REQUEST:
      return { loading: true }
    case VERIFY_WALLET_FUND_SUCCESS:
      return { loading: false, success: true }
    case VERIFY_WALLET_FUND_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const sendFundReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_FUND_REQUEST:
      return { loading: true }
    case SEND_FUND_SUCCESS:
      return { loading: false, sent: action.payload }
    case SEND_FUND_FAIL:
      return { loading: false, error: action.payload }
    case SEND_FUND_RESET:
      return {}
    default:
      return state
  }
}

export const walletTransactionReducer = (state = {}, action) => {
  switch (action.type) {
    case WALLET_TRANSACTIONS_REQUEST:
      return { loading: true }
    case WALLET_TRANSACTIONS_SUCCESS:
      return { loading: false, transactions: action.payload }
    case WALLET_TRANSACTIONS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const verifyUserIDReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_WALLET_USERID_REQUEST:
      return { loading: true }
    case VERIFY_WALLET_USERID_SUCCESS:
      return { loading: false, verified: action.payload }
    case VERIFY_WALLET_USERID_FAIL:
      return { loading: false, error: action.payload }
    case VERIFY_WALLET_USERID_RESET:
      return {}
    default:
      return state
  }
}

export const verifyAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_ACCOUNT_REQUEST:
      return { loading: true }
    case VERIFY_ACCOUNT_SUCCESS:
      return { loading: false, accountDetail: action.payload }
    case VERIFY_ACCOUNT_FAIL:
      return { loading: false, error: action.payload }
    case VERIFY_ACCOUNT_RESET:
      return {}
    default:
      return state
  }
}

export const withdrawFundReducer = (state = {}, action) => {
  switch (action.type) {
    case WITHDRAW_FUND_REQUEST:
      return { loading: true }
    case WITHDRAW_FUND_SUCCESS:
      return { loading: false, withdrawn: action.payload }
    case WITHDRAW_FUND_FAIL:
      return { loading: false, error: action.payload }
    case WITHDRAW_FUND_RESET:
      return {}
    default:
      return state
  }
}

export const escrowTransferReducer = (state = {}, action) => {
  switch (action.type) {
    case INITIATE_ESCROW_TRANSFER_REQUEST:
      return { loading: true }
    case INITIATE_ESCROW_TRANSFER_SUCCESS:
      return { loading: false, initiated: action.payload }
    case INITIATE_ESCROW_TRANSFER_FAIL:
      return { loading: false, error: action.payload }
    case INITIATE_ESCROW_TRANSFER_RESET:
      return {}
    default:
      return state
  }
}

export const secureTransfersReducer = (state = { loading: true, transfers: [] }, action) => {
  switch (action.type) {
    case SECURE_TRANSFER_REQUEST:
      return { loading: true }
    case SECURE_TRANSFER_SUCCESS:
      return { loading: false, transfers: action.payload }
    case SECURE_TRANSFER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const claimTransfersReducer = (state = { loading: true, claimTransfers: [] }, action) => {
  switch (action.type) {
    case ALL_SECURE_FUNDS_REQUEST:
      return { loading: true }
    case ALL_SECURE_FUNDS_SUCCESS:
      return { loading: false, claimTransfers: action.payload }
    case ALL_SECURE_FUNDS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

