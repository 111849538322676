import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addBenefit,
  addToActivity,
  bulkAddToActivity,
  clear,
  clearActivity,
  removeFromActivity,
} from '../../../actions/adminActions/activityActions/activities'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { v4 as uuidv4 } from 'uuid'
import { createPackage } from '../../../actions/adminActions/packagesActions/createPackage'
import { LoadingBox, MessageBox } from '../../../components'
import { PACKAGE_CREATE_RESET } from '../../../constants/adminConstants/packageConstants'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Link } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { AllBenefits } from '../../../actions/adminActions/benefitsActions/allBenefits'
import { allPackageCategory } from '../../../actions/adminActions/packagesActions/allPackageCategory'
import { listManufacuturers } from '../../../actions/adminActions/manufactuerActions/listManufacturers'
import { organisationProducts } from '../../../actions/adminActions/productActions/organisationProducts'
import {
  productCategory,
  protocolDuration,
  protocolPoint,
  tagPointConfig,
} from '../../../components/functions'
import { allAssignedPC } from '../../../actions/adminActions/productActions/allAssignedPC'
import SweetAlert from 'react-bootstrap-sweetalert'
import csvtojson from 'csvtojson'
import { allEnterpriseList } from '../../../actions/adminActions/enterpriseActions/allEnterpriseList'

function AddPackagePage(props) {
  const [open, setOpen] = useState(false)
  const [itemId, setItemId] = useState('')
  const [farmType, setFarmType] = useState('')
  const [name, setName] = useState('')
  const [enterpriseId, setEnterpriseId] = useState('')
  const [enterpriseName, setEnterpriseName] = useState('')
  const [packageName, setPackageName] = useState('')
  const [minUnit, setMinUnit] = useState('')
  const [measuringUnit, setMeasuringUnit] = useState('')
  const [averageLoss, setAverageLoss] = useState('')
  const [averageLossMoU, setAverageLossMoU] = useState('')
  const [activity, setActivity] = useState('')
  const [tagColor, setTagColor] = useState('')
  const [farmActivity, setScanFarmActivity] = useState('')
  const [expectedTask, setScanExpectedTask] = useState('')
  const [timelineFrom, setScanTimelineFrom] = useState('')
  const [timelineTo, setScanTimelineTo] = useState('')
  const [point, setScanPoint] = useState('')
  const [benefitName, setBenefitName] = useState('')
  const [benefit, setBenefit] = useState('')
  const [benefitFrom, setBenefitFrom] = useState('')
  const [benefitTo, setBenefitTo] = useState('')
  const [minPointToQualify, setMinPointToQualify] = useState('')
  const [packageImg, setpackageImg] = useState()
  const [productId, setProductId] = useState('')
  const [productName, setProductName] = useState('')
  const [productObject, setProductObject] = useState({})
  const [manufacturer, setManufacturer] = useState('')
  const [manufacturerName, setManufacturerName] = useState('')
  const [tagPoint, setTagPoint] = useState('')
  const [totalPoint, setTotalPoint] = useState(0)
  const [totalDuration, setTotalDuration] = useState(0)
  const [productCategoryId, setProductCategoryId] = useState('')
  const [alert, setAlert] = useState(null)
  const [file, setFile] = useState(null)
  const [activityList, setActivityList] = useState([])
  const [benefitList, setBenefitList] = useState([])

  const dispatch = useDispatch()

  const compliance = useSelector((state) => state.compliance)
  const { activityItems, benefitsItems } = compliance

  const packageCreate = useSelector((state) => state.packageCreate)
  const { loading, success, error } = packageCreate

  const benefitsList = useSelector((state) => state.benefitsList)
  const { loading: benefitsLoading, allbenefits } = benefitsList

  const packageCategoryList = useSelector((state) => state.packageCategoryList)
  const { loading: loadingCategory, categoryList } = packageCategoryList

  const organisationProductList = useSelector(
    (state) => state.organisationProductList,
  )
  const { loading: loadingProduct, products } = organisationProductList

  const manufacturersList = useSelector((state) => state.manufacturersList)
  const { loading: loadingMaufacturer, manufacturers } = manufacturersList

  const allEnterprise = useSelector((state) => state.allEnterprise)
  const { loading: loadingEnterprises, enterprises } = allEnterprise

  const allProductCategoryPoint = useSelector(
    (state) => state.allProductCategoryPoint,
  )

  useEffect(() => {
    dispatch(AllBenefits())
    dispatch(allPackageCategory())
    dispatch(listManufacuturers())
    dispatch(allAssignedPC())
    dispatch(allEnterpriseList())

    if (success) {
      setTimeout(() => {
        dispatch({ type: PACKAGE_CREATE_RESET })
        window.location.reload()
      }, 2000)
    }
  }, [dispatch, props.history, success])

  useEffect(() => {
    if (manufacturer) {
      dispatch(organisationProducts(manufacturer))
    }
  }, [dispatch, manufacturer])

  const { assignedPC } = allProductCategoryPoint

  let row = 1
  let col = 1
  let textNum = 1

  useEffect(() => {
    if (activityItems) {
      setActivityList(activityItems)
    }
    if (benefitsItems) {
      setBenefitList(benefitsItems)
    }
  }, [activityItems, benefitsItems])

  const clearState = () => {
    setScanFarmActivity('')
    setScanExpectedTask('')
    setScanTimelineFrom('')
    setScanTimelineTo('')
    setScanPoint('')
    setTagColor('')
  }

  const handleScanSubmit = (e) => {
    e.preventDefault()
    let _id = uuidv4()
    if (
      (activity === 'Scan Activity' && !tagColor) ||
      !farmActivity ||
      !expectedTask ||
      !timelineFrom ||
      !timelineTo
    ) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty Fields"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Kindly fill all fields!!!
        </SweetAlert>,
      )
    } else if (
      (activity === 'Record Activity' && !farmActivity) ||
      !expectedTask ||
      !timelineFrom ||
      !timelineTo
    ) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty Fields"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Kindly fill all fields!!!
        </SweetAlert>,
      )
    } else {
      dispatch(
        addToActivity(
          _id,
          activity,
          tagColor,
          farmActivity,
          expectedTask,
          timelineFrom,
          timelineTo,
          point,
        ),
      )
      clearState()
      if (activity === 'Scan Activity') {
        setScanPoint(tagPoint)
      }
    }
  }

  const editItem = (e) => {
    e.preventDefault()
    if (itemId) {
      dispatch(
        addToActivity(
          itemId,
          activity,
          tagColor,
          farmActivity,
          expectedTask,
          timelineFrom,
          timelineTo,
          point,
        ),
      )
    }
    setOpen(false)
    clearState()
  }

  const handleClear = (e) => {
    dispatch(clearActivity())
    setTotalPoint(0)
    setTotalDuration(0)
    setActivityList([])
    setBenefitList([])
  }

  const clearActivities = () => {
    dispatch(clear())
    setTotalPoint(0)
    setTotalDuration(0)
    setActivityList([])
  }

  const removeFromActivityHandler = (id) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => (setAlert(null), dispatch(removeFromActivity(id)))}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You can not undo this!!!
      </SweetAlert>,
    )
  }

  const handleClickOpen = (item) => {
    setOpen(true)
    setItemId(item._id)
    setActivity(item.activity)
    setScanFarmActivity(item.farmActivity)
    setScanExpectedTask(item.expectedTask)
    setScanTimelineFrom(item.timeLineFrom)
    setScanTimelineTo(item.timeLineTo)
    setScanPoint(item.point)
  }

  const handleClose = () => {
    setOpen(false)
    clearState()
  }

  function clearBenefitState() {
    setBenefit('')
    setBenefitFrom('')
    setBenefitTo('')
    setMinPointToQualify('')
  }

  const addBenefitHandler = (e) => {
    e.preventDefault()
    if (
      !benefitName ||
      !benefit ||
      !benefitFrom ||
      !benefitTo ||
      !minPointToQualify
    ) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty Fields"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Kindly fill all fields!!!
        </SweetAlert>,
      )
    } else {
      dispatch(
        addBenefit(
          benefitName,
          benefit,
          benefitFrom,
          benefitTo,
          minPointToQualify,
        ),
      )
      clearBenefitState()
    }
  }

  const handleSavePackage = () => {
    if (activityItems.length === 0) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty activities"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Compliance activities can't be empty!!!
        </SweetAlert>,
      )
    }
    if (benefitsItems.length === 0) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty activities"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Compliance benefits can't be empty!!!
        </SweetAlert>,
      )
    }

    if (
      totalDuration !== Number(productObject && productObject.cycleDuration)
    ) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Error"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Ensure total protocol duration is correct!!!
        </SweetAlert>,
      )
    }

    if (totalPoint !== Number(productObject && productObject.expectedPoint)) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Error"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Ensure total point is correct!!!
        </SweetAlert>,
      )
    }

    if (
      activityItems.length > 0 &&
      benefitsItems.length > 0 &&
      totalPoint === Number(productObject && productObject.expectedPoint) &&
      totalDuration === Number(productObject && productObject.cycleDuration)
    ) {
      const removedIdActivities = activityItems.map((item) => {
        delete item._id
        return item
      })

      const removeBenefitName = benefitsItems.map((item) => {
        delete item.benefitName
        return item
      })
      dispatch(
        createPackage({
          farmType,
          enterpriseId,
          packageName,
          averageLoss,
          averageLossMoU,
          packageMinUnit: { minUnit, measuringUnit },
          activityItems: removedIdActivities,
          benefitsItems: removeBenefitName,
          packageImg,
          manufacturer,
          productId,
          productCategoryId,
        }),
      )
    }
  }

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  }

  const activeStyle = {
    borderColor: '#2196f3',
  }

  const acceptStyle = {
    borderColor: '#00e676',
  }

  const rejectStyle = {
    borderColor: '#ff1744',
  }

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((element) => {
        let file = element
        let reader = new FileReader()
        reader.onloadend = function () {
          setpackageImg(reader.result)
        }
        reader.readAsDataURL(file)
      })
    },
  })

  const fileRejectionItems = fileRejections.map(
    ({ file, errors: fileErrors }) => (
      <>
        {fileErrors.map((e) => (
          <>
            <div
              className={`alert alert-danger alert-dismissible`}
              role="alert"
              key={file.path}
            >
              {e.message}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          </>
        ))}
      </>
    ),
  )

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [
      isDragActive,
      isDragReject,
      isDragAccept,
      activeStyle,
      acceptStyle,
      rejectStyle,
    ],
  )

  useEffect(() => {
    if (productId) {
      setProductObject(
        productCategory(products, productId, [
          '_id',
          'name',
          'expectedPoint',
          'cycleDuration',
        ]),
      )
    }
  }, [productId])

  useEffect(() => {
    if (productObject && productObject._id && assignedPC) {
      setTagPoint(tagPointConfig(assignedPC, productObject._id))
      setProductCategoryId(productObject._id)
    }
  }, [productId, productObject, assignedPC])

  useEffect(() => {
    if (activityItems && activityItems.length > 0) {
      setTotalPoint(protocolPoint(activityItems))
      setTotalDuration(protocolDuration(activityItems))
    }
  }, [activityItems])

  const handleFileChange = (event) => {
    setFile(event.target.files[0])
  }

  const handleUpload = async (event) => {
    event.preventDefault()

    if (file) {
      const reader = new FileReader()
      reader.onload = async function (e) {
        //  Read Excel File to Json Data
        let arrayToInsert = []
        const contents = e.target.result
        const source = await csvtojson().fromString(contents)

        if (source.length != 0) {
          for (var i = 0; i < source.length; i++) {
            var singleRow = {
              _id: uuidv4(),
              activity: source[i]['activity'],
              tagColor: source[i]['tagColor'],
              farmActivity: source[i]['farmActivity'],
              expectedTask: source[i]['expectedTask'],
              timeLineFrom: source[i]['timeLineFrom'],
              timeLineTo: source[i]['timeLineTo'],
              point: source[i]['point'],
            }
            arrayToInsert.push(singleRow)
          }
        }
        if (arrayToInsert.length != 0) {
          dispatch(bulkAddToActivity(arrayToInsert))
        }
      }
      reader.readAsText(file)
    }
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {success && (
              <MessageBox variant="success">
                Package successfully created
              </MessageBox>
            )}
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Add New Protocol</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmsured</a>
                      </li>
                      <li className="breadcrumb-item active">Add Protocol</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            {loading ? (
              <LoadingBox />
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  {error && <MessageBox variant="danger">{error}</MessageBox>}
                  <div className="card">
                    <div className="card-body">
                      <div
                        id="addproduct-nav-pills-wizard"
                        className="twitter-bs-wizard"
                      >
                        <ul className="twitter-bs-wizard-nav">
                          <li className="nav-item">
                            <Link
                              to="#basic-info"
                              className="nav-link"
                              data-toggle="tab"
                            >
                              <span className="step-number">01</span>
                              <span className="step-title">
                                Basic Information
                              </span>
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                            <Link
                              to="#package-img"
                              className="nav-link"
                              data-toggle="tab"
                            >
                              <span className="step-number">02</span>
                              <span className="step-title">
                                Add Protocol Image
                              </span>
                            </Link>
                          </li> */}
                          <li className="nav-item">
                            <Link
                              to="#product-img"
                              className="nav-link"
                              data-toggle="tab"
                            >
                              <span className="step-number">02</span>
                              <span className="step-title">Activity</span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#metadata"
                              className="nav-link"
                              data-toggle="tab"
                            >
                              <span className="step-number">03</span>
                              <span className="step-title">
                                Assign Benefits to Farm Protocol
                              </span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#review"
                              className="nav-link"
                              data-toggle="tab"
                            >
                              <span className="step-number">04</span>
                              <span className="step-title">
                                Information Review
                              </span>
                            </Link>
                          </li>
                        </ul>

                        <div className="tab-content twitter-bs-wizard-tab-content">
                          {/* First phase */}
                          <div className="tab-pane" id="basic-info">
                            <h4 className="card-title">Basic Information</h4>
                            <p className="card-title-desc">
                              Fill all information below
                            </p>

                            <form>
                              <div className="row">
                                <div className="col-lg-8">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="productname"
                                    >
                                      Enterprise Package
                                    </label>
                                    <select
                                      className="form-select form-control select2"
                                      onChange={(e) => (
                                        setEnterpriseId(e.target.value),
                                        setEnterpriseName(
                                          e.target.options[
                                            e.target.selectedIndex
                                          ].text,
                                        )
                                      )}
                                    >
                                      <option>
                                        Select the enterprise package
                                      </option>
                                      {loadingEnterprises ? (
                                        <>Loading...</>
                                      ) : enterprises &&
                                        enterprises.length === 0 ? (
                                        <>
                                          <option>
                                            Please create an enterprise
                                          </option>
                                        </>
                                      ) : (
                                        enterprises &&
                                        enterprises.map((item) => (
                                          <option value={item._id}>
                                            {item.name}
                                          </option>
                                        ))
                                      )}
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-8">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="productname"
                                  >
                                    Package Name
                                  </label>
                                  <input
                                    className="form-control"
                                    onChange={(e) =>
                                      setPackageName(e.target.value)
                                    }
                                    placeholder="Package name"
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-8">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Protocol Categories
                                    </label>
                                    <select
                                      className="form-select form-control select2"
                                      onChange={(e) => (
                                        setFarmType(e.target.value),
                                        setName(
                                          e.target.options[
                                            e.target.selectedIndex
                                          ].text,
                                        )
                                      )}
                                    >
                                      <option>
                                        Select the protocol categories type
                                      </option>
                                      {loadingCategory ? (
                                        <>Loading...</>
                                      ) : categoryList &&
                                        categoryList.length === 0 ? (
                                        <>
                                          <option>
                                            Please create a protocol category
                                          </option>
                                        </>
                                      ) : (
                                        categoryList &&
                                        categoryList.map((item) => (
                                          <option value={item._id}>
                                            {item.name}
                                          </option>
                                        ))
                                      )}
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="price"
                                    >
                                      Protocol Minimum Unit
                                    </label>
                                    <input
                                      id="unit"
                                      name="unit"
                                      type="number"
                                      className="form-control"
                                      placeholder="Protocol Minimum Unit"
                                      onChange={(e) =>
                                        setMinUnit(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="manufacturerbrand"
                                    >
                                      Measuring unit
                                    </label>
                                    <select
                                      className="form-select"
                                      onChange={(e) =>
                                        setMeasuringUnit(e.target.value)
                                      }
                                    >
                                      <option>Select the measuring unit</option>
                                      <option value="Birds">Birds</option>
                                      <option value="Box(es)">Box(es)</option>
                                      <option value="Kg">kg</option>
                                      <option value="Units">Units</option>
                                      <option value="Animals">Animals</option>
                                      <option value="Bags">Bags</option>
                                      <option value="Tonnes">Tonnes</option>
                                      <option value="Litres">Litres</option>
                                      <option value="Items">Items</option>
                                      <option value="Hectares">Hectares</option>
                                      <option value="Acre">Acre</option>
                                    </select>
                                  </div>
                                </div>
                              </div>


                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Aver. Percentage Loss (%)
                                    </label>
                                    <input
                                      className="form-control"
                                      onChange={(e) =>
                                        setAverageLoss(e.target.value)
                                      }
                                      placeholder="Aver. Percentage Loss (%)"
                                    />
                                  </div>
                                </div>

                                <div className="col-md-2">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="manufacturerbrand"
                                    >
                                      Measuring unit
                                    </label>
                                    <select
                                      className="form-select"
                                      onChange={(e) =>
                                        setAverageLossMoU(e.target.value)
                                      }
                                    >
                                      <option>Select the measuring unit</option>
                                      <option value="Birds">Birds</option>
                                      <option value="Box(es)">Box(es)</option>
                                      <option value="Kg">kg</option>
                                      <option value="Units">Units</option>
                                      <option value="Animals">Animals</option>
                                      <option value="Bags">Bags</option>
                                      <option value="Tonnes">Tonnes</option>
                                      <option value="Litres">Litres</option>
                                      <option value="Items">Items</option>
                                      <option value="Hectares">Hectares</option>
                                      <option value="Acre">Acre</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              
                              <div className="row">
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      forhtml="ProductName"
                                    >
                                      Select Organization
                                    </label>
                                    <select
                                      onChange={(e) => (
                                        setManufacturer(e.target.value),
                                        setManufacturerName(
                                          e.target.options[
                                            e.target.selectedIndex
                                          ].text,
                                        )
                                      )}
                                      className="select2 form-select"
                                      required
                                    >
                                      {loadingMaufacturer ? (
                                        'Loading...'
                                      ) : manufacturers.length > 0 ? (
                                        <>
                                          <option>Select manufacturer</option>
                                          {manufacturers.map((item) => (
                                            <option
                                              key={item._id}
                                              value={item._id}
                                            >
                                              {item.name}
                                            </option>
                                          ))}
                                        </>
                                      ) : (
                                        'There is no organization'
                                      )}
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-4">
                                    <label
                                      className="form-label"
                                      htmlFor="manufacturerbrand"
                                    >
                                      Product/Service Name
                                    </label>
                                    <select
                                      className="select2 form-select"
                                      onChange={(e) => (
                                        setProductId(e.target.value),
                                        setProductName(
                                          e.target.options[
                                            e.target.selectedIndex
                                          ].text,
                                        )
                                      )}
                                      required
                                    >
                                      {loadingProduct ? (
                                        'Loading...'
                                      ) : products && products.length > 0 ? (
                                        <>
                                          <option>Select product</option>
                                          {products.map((item) => (
                                            <option
                                              key={item._id}
                                              value={item._id}
                                            >
                                              {item.name}
                                            </option>
                                          ))}
                                        </>
                                      ) : (
                                        <option>No product/service</option>
                                      )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>

                          {/* Second phase */}
                          <div className="tab-pane" id="package-img">
                            {fileRejectionItems}
                            <h4 className="card-title">Protocol Image</h4>
                            <p className="card-title-desc">
                              Upload protocol image
                            </p>
                            <div className="row">
                              <div className="col-md-6">
                                <section className="container">
                                  <div
                                    {...getRootProps({
                                      style,
                                      className:
                                        'dropzone display-9 text-muted ri-upload-cloud-2-line',
                                    })}
                                  >
                                    <input {...getInputProps()} />
                                    <p className="display-8 ">
                                      Drag 'n' drop some files here, or click to
                                      select files
                                    </p>
                                    <em className="display-8 ">
                                      (Only *.jpeg and *.png images will be
                                      accepted)
                                    </em>
                                  </div>
                                </section>
                              </div>
                              <div className="col-md-6">
                                {setpackageImg ? (
                                  <img
                                    src={
                                      packageImg ||
                                      'https://via.placeholder.com/300'
                                    }
                                    className="packageImg"
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>

                          {/* Third phase */}
                          <div className="tab-pane" id="product-img">
                            <div className="">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="card">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-3">
                                          <div className="d-flex mt-4 mt-md-0">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-24">
                                                <i className="ri-exchange-line"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6>Enterprise Package</h6>
                                              <p className="text-muted mb-0">
                                                {enterpriseName ||
                                                  'Input protocol name'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="d-flex">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-24">
                                                <i className="ri-checkbox-circle-line"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6 className="light">
                                                Protocol Type
                                              </h6>
                                              <p className="text-muted mb-0">
                                                {name || 'Select farm type'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="d-flex mt-4 mt-md-0">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                                <i className="fal fa-weight-hanging"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6>Minimum Unit</h6>
                                              <p className="text-muted mb-0">
                                                {minUnit ||
                                                  'Select minimum unit'}{' '}
                                                {measuringUnit}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="d-flex mt-4 mt-md-0">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                                <i className="fal fa-store"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6>Organisation</h6>
                                              <p className="text-muted mb-0">
                                                {manufacturerName ||
                                                  'Select an organisation'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row mt-3">
                                        <div className="col-md-3">
                                          <div className="d-flex mt-4 mt-md-0">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                                <i className="fal fa-box-check"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6>Product</h6>
                                              <p className="text-muted mb-0">
                                                {productName ||
                                                  'Select product'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="d-flex mt-4 mt-md-0">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                                <i className="fal fa-folder-tree"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6>Product Category</h6>
                                              <p className="text-muted mb-0">
                                                {(productObject &&
                                                  productObject.name) ||
                                                  'Select product category'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="d-flex mt-4 mt-md-0">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                                <i className="fal fa-coins"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6>Expected Point</h6>
                                              <p className="text-muted mb-0">
                                                {productObject &&
                                                  productObject.expectedPoint
                                                  ? productObject.expectedPoint
                                                  : 'Expected Point'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="d-flex mt-4 mt-md-0">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                                <i className="fal fa-clock"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6>Cycle Duration</h6>
                                              <p className="text-muted mb-0">
                                                {(productObject &&
                                                  productObject.cycleDuration &&
                                                  `${productObject.cycleDuration} Hrs`) ||
                                                  'Select cycle duration'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <!-- end row --> */}
                            </div>
                            <div className="">
                              <h4 className="card-title mt-3">
                                Farm Activities
                              </h4>
                              <p className="card-title-desc mb-3">
                                Create Farm Protocol Standards
                              </p>

                              {/* Protocol File Upload Starts here */}
                              <section className="mb-3">
                                <div
                                  className="d-sm-flex align-items-center justify-content-between p-3"
                                  style={{
                                    color: 'white',
                                    backgroundColor: 'green',
                                    padding: '7px',
                                    borderRadius: '5px',
                                    marginBottom: '30px',
                                    width: '60%',
                                  }}
                                >
                                  <h4 className="text-white font-size-14 mb-0">
                                    Download the Farmsured Protocol System
                                    Template
                                  </h4>

                                  <div className="">
                                    <a
                                      href={process.env.REACT_APP_FILE_LINK}
                                      download
                                      className="btn btn-outline-light btn-custom-outline btm-sm"
                                    >
                                      <i className="fal fa-dowload"></i>{' '}
                                      Download
                                    </a>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-7">
                                    <form onSubmit={handleUpload}>
                                      <div className="form-group files users-upload">
                                        <label>
                                          Upload Your Farmsured Protocol File
                                          (Format: CSV){' '}
                                        </label>
                                        <input
                                          type="file"
                                          className="form-control mb-3"
                                          onChange={handleFileChange}
                                          accept=".csv"
                                        />
                                        <small
                                          className="card-title-desc"
                                          style={{
                                            color: 'white',
                                            backgroundColor: 'red',
                                            padding: '7px',
                                            borderRadius: '5px',
                                          }}
                                        >
                                          Download the Farmsured Protocol System
                                          template, and modify to suit your
                                          need, ensure you only upload a CSV
                                          format file.
                                        </small>
                                      </div>
                                      <div className="center mt-4">
                                        <button
                                          type="submit"
                                          className="btn btn-success btn-bg"
                                          style={{
                                            backgroundColor: 'green',
                                          }}
                                          onClick={() => handleUpload}
                                        >
                                          Upload File
                                        </button>
                                      </div>
                                    </form>

                                    <div className="center mb-3">
                                      <p>
                                        or Manually add Farmsured Protocol
                                        System{' '}
                                      </p>
                                    </div>

                                    {/* Manual protocol adding starts here */}
                                    <section>
                                      <h5 className="font-size-14">
                                        Select activity :
                                      </h5>
                                      <div
                                        className="row"
                                        onChange={(e) =>
                                          setActivity(e.target.value)
                                        }
                                      >
                                        <div
                                          className="col-lg-6 col-sm-6"
                                          onClick={() => {
                                            setScanPoint(tagPoint)
                                          }}
                                        >
                                          <div>
                                            <label className="form-label card-radio-label mb-3">
                                              <input
                                                type="radio"
                                                name="pay-method"
                                                id="pay-methodoption1"
                                                className="card-radio-input"
                                                value="Scan Activity"
                                              />

                                              <span className="card-radio">
                                                <i className="fab fa fa-qrcode font-size-24 align-middle me-2"></i>
                                                <span>Scan Activity</span>
                                              </span>
                                            </label>
                                          </div>
                                        </div>

                                        <div
                                          className="col-lg-6 col-sm-6"
                                          onClick={() => (
                                            setTagColor(''), setScanPoint('')
                                          )}
                                        >
                                          <div>
                                            <label className="form-label card-radio-label mb-3">
                                              <input
                                                type="radio"
                                                name="pay-method"
                                                id="pay-methodoption3"
                                                className="card-radio-input"
                                                value="Record Activity"
                                              />

                                              <span className="card-radio">
                                                <i className="far fa-money-bill-alt font-size-24 align-middle me-2"></i>
                                                <span>Record Activity</span>
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-12">
                                          <div
                                            className="card"
                                            id="activityModel"
                                            style={{ display: 'none' }}
                                          >
                                            <div className="card-body">
                                              <h4 className="card-title activityTitile">
                                                Scan Activity
                                              </h4>
                                              <p className="card-title-desc">
                                                Provide farm activities, which
                                                are to be carried out on a
                                                specified day or week.
                                              </p>
                                              <form
                                                className="needs-validation"
                                                noValidate
                                              >
                                                <div
                                                  className="row"
                                                  id="scanActivity"
                                                  style={{ display: 'none' }}
                                                  // value={tagColor}
                                                  onChange={(e) =>
                                                    setTagColor(e.target.value)
                                                  }
                                                >
                                                  <div className="col-md-6">
                                                    <div className="form-check mb-3">
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="formRadios"
                                                        id="formRadios1"
                                                        value="Verify (RED Label)"
                                                        checked={
                                                          tagColor ===
                                                          'Verify (RED Label)'
                                                        }
                                                        required
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor="formRadios1"
                                                        style={{ color: 'red' }}
                                                      >
                                                        <b>Verify </b>
                                                        <span>
                                                          <b>(RED Label)</b>
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-check">
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="formRadios"
                                                        id="formRadios2"
                                                        value="Activate (GREEN Label)"
                                                        checked={
                                                          tagColor ===
                                                          'Activate (GREEN Label)'
                                                        }
                                                        required
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor="formRadios2"
                                                        style={{
                                                          color: 'green',
                                                        }}
                                                      >
                                                        <b>Activate </b>
                                                        <span>
                                                          <b>(GREEN Label)</b>
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="mb-3">
                                                      <label
                                                        htmlFor="validationCustom01"
                                                        className="form-label"
                                                      >
                                                        Farm Activity
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        value={farmActivity}
                                                        placeholder="Enter farm activity title"
                                                        required
                                                        onChange={(e) =>
                                                          setScanFarmActivity(
                                                            e.target.value,
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="mb-3">
                                                      <label
                                                        htmlFor="validationCustom02"
                                                        className="form-label"
                                                      >
                                                        Expected Task
                                                      </label>
                                                      <textarea
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom02"
                                                        placeholder="Activity to perform"
                                                        value={expectedTask}
                                                        required
                                                        onChange={(e) =>
                                                          setScanExpectedTask(
                                                            e.target.value,
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="mb-3">
                                                      <label
                                                        htmlFor="validationCustom04"
                                                        className="form-label"
                                                      >
                                                        Timeline
                                                      </label>
                                                      <ul className="timeline-input">
                                                        <li>
                                                          <input
                                                            type="number"
                                                            className="form-control"
                                                            id="validationCustom04"
                                                            placeholder="0.00hrs"
                                                            value={timelineFrom}
                                                            required
                                                            onChange={(e) =>
                                                              setScanTimelineFrom(
                                                                e.target.value,
                                                              )
                                                            }
                                                          />
                                                        </li>
                                                        <li className="timelinedash">
                                                          -
                                                        </li>
                                                        <li>
                                                          <input
                                                            type="number"
                                                            className="form-control"
                                                            id="validationCustom04"
                                                            placeholder="0.00hrs"
                                                            value={timelineTo}
                                                            required
                                                            onChange={(e) =>
                                                              setScanTimelineTo(
                                                                e.target.value,
                                                              )
                                                            }
                                                          />
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>

                                                  {activity ===
                                                    'Scan Activity' ? (
                                                    <div
                                                      className="col-md-6"
                                                      id="scanPointAllocation"
                                                    >
                                                      <div className="mb-3">
                                                        <label
                                                          htmlFor="validationCustom05"
                                                          className="form-label"
                                                        >
                                                          Allocated Points
                                                        </label>
                                                        <div className="">
                                                          <input
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            disabled
                                                            type="number"
                                                            placeholder="Allocated points"
                                                            value={point}
                                                            onChange={(e) =>
                                                              setScanPoint(
                                                                e.target.value,
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className="col-md-4"
                                                      id="pointAllocation"
                                                    >
                                                      <div className="mb-3">
                                                        <label
                                                          htmlFor="validationCustom05"
                                                          className="form-label"
                                                        >
                                                          Allocated Points
                                                        </label>
                                                        <input
                                                          type="number"
                                                          className="form-control"
                                                          id="validationCustom05"
                                                          placeholder="Allocated points"
                                                          value={point}
                                                          required
                                                          onChange={(e) =>
                                                            setScanPoint(
                                                              e.target.value,
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>

                                                <div>
                                                  <button
                                                    className="btn btn-success float-end"
                                                    type="submit"
                                                    onClick={handleScanSubmit}
                                                  >
                                                    Save
                                                  </button>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                    {/* Manual protocol adding ends here */}
                                  </div>

                                  {/* Second Column - Compliance Activities List */}
                                  <div className="col-md-5">
                                    <div className="card" id="standards">
                                      <div className="card-body">
                                        <div className="col-12">
                                          <div className="d-sm-flex align-items-center justify-content-between">
                                            <h4 className="card-title">
                                              Farmsured Protocol
                                            </h4>

                                            <div className="page-title-right">
                                              <button
                                                className="btn btn-sm btn-outline-danger"
                                                onClick={clearActivities}
                                              >
                                                Clear Activities
                                              </button>
                                            </div>
                                          </div>
                                        </div>

                                        <p className="card-title-desc">
                                          Extend the default collapse to view
                                          farm protocol activities.
                                        </p>

                                        <div
                                          id="accordion"
                                          className="custom-accordion scroll"
                                        >
                                          {activityList.length === 0 ? (
                                            <div>Activity is empty.</div>
                                          ) : (
                                            <>
                                              {activityList.map((item) => (
                                                <>
                                                  <div
                                                    className="card mb-1 shadow-none"
                                                    key={item._id}
                                                  >
                                                    <a
                                                      href={`#collapse${row++}`}
                                                      className="text-black"
                                                      data-bs-toggle="collapse"
                                                      aria-expanded="true"
                                                      aria-controls="collapseOne"
                                                    >
                                                      <div
                                                        className={`card-header ${item.activity ===
                                                          'Scan Activity'
                                                          ? 'card-scan-activity'
                                                          : 'card-record-activity'
                                                          }`}
                                                        id="headingOne"
                                                      >
                                                        <span className="">
                                                          {item.activity ===
                                                            'Scan Activity' ? (
                                                            <i
                                                              className="fa fa-qrcode me-1"
                                                              style={{
                                                                fontSize:
                                                                  '18px',
                                                              }}
                                                            ></i>
                                                          ) : (
                                                            <i
                                                              className="fa fa-file me-1"
                                                              style={{
                                                                fontSize:
                                                                  '18px',
                                                              }}
                                                            ></i>
                                                          )}{' '}
                                                          Compliance Activity #
                                                          {textNum++}
                                                          <div className="float-end">
                                                            Hours:{' '}
                                                            {item.timeLineFrom}{' '}
                                                            - {item.timeLineTo}
                                                            <i
                                                              className="mdi mdi-minus float-end accor-plus-icon"
                                                              style={{
                                                                marginLeft:
                                                                  '20px',
                                                              }}
                                                            ></i>
                                                          </div>
                                                        </span>
                                                      </div>
                                                    </a>
                                                    <div
                                                      id={`collapse${col++}`}
                                                      className="collapse"
                                                      aria-labelledby="headingOne"
                                                      data-bs-parent="#accordion"
                                                    >
                                                      <div className="">
                                                        <div className="container-card">
                                                          <div className="row mt-2">
                                                            <div className="col-md-8">
                                                              <div className="container__text">
                                                                <h1 className="card-title">
                                                                  {
                                                                    item.farmActivity
                                                                  }
                                                                </h1>

                                                                <p>
                                                                  <span className="expected_task">
                                                                    <b>
                                                                      Expected
                                                                      Task:
                                                                    </b>
                                                                  </span>
                                                                  <br />
                                                                  {
                                                                    item.expectedTask
                                                                  }
                                                                </p>
                                                              </div>
                                                            </div>
                                                            <div className="col-md-4 red">
                                                              {item.activity ? (
                                                                <div className="">
                                                                  {item.tagColor ? (
                                                                    <div className="pt-2 container__text__timing_time">
                                                                      {
                                                                        item.tagColor
                                                                      }
                                                                    </div>
                                                                  ) : null}
                                                                  <div className="align">
                                                                    {item.activity ===
                                                                      'Scan Activity' ? (
                                                                      <div className="font-awesome-size">
                                                                        <i className="fa fa-qrcode"></i>
                                                                      </div>
                                                                    ) : (
                                                                      <div className="font-awesome-size mt-3">
                                                                        <i className="fa fa-file"></i>
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              ) : null}
                                                            </div>
                                                          </div>
                                                          <div className="container__text">
                                                            <div className="container__text__timing">
                                                              <div className="container__text__timing_time mr-2">
                                                                <h2>
                                                                  Timeline
                                                                </h2>
                                                                <p>
                                                                  {
                                                                    item.timeLineFrom
                                                                  }{' '}
                                                                  -{' '}
                                                                  {
                                                                    item.timeLineTo
                                                                  }{' '}
                                                                  hrs
                                                                </p>
                                                              </div>
                                                              <div className="container__text__timing_time">
                                                                <h2>
                                                                  Allocated
                                                                  point
                                                                </h2>
                                                                <p>
                                                                  {`${item.point} points`}
                                                                </p>
                                                              </div>
                                                            </div>
                                                            <div className="btn">
                                                              <span
                                                                className=""
                                                                data-bs-toggle="modal"
                                                                data-bs-target=".bs-example-modal-center"
                                                                style={{
                                                                  marginRight:
                                                                    '20px',
                                                                  cursor:
                                                                    'pointer',
                                                                }}
                                                                onClick={() =>
                                                                  handleClickOpen(
                                                                    item,
                                                                  )
                                                                }
                                                              >
                                                                Edit
                                                              </span>
                                                              <span>
                                                                <i
                                                                  className="fa fa-trash"
                                                                  id="sa-warning"
                                                                  style={{
                                                                    color:
                                                                      'red',
                                                                    cursor:
                                                                      'pointer',
                                                                  }}
                                                                  onClick={() =>
                                                                    removeFromActivityHandler(
                                                                      item._id,
                                                                    )
                                                                  }
                                                                ></i>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ))}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              {/* Protocol File Upload Ends Here */}
                            </div>
                          </div>

                          {/* Four phase */}
                          <div className="tab-pane" id="metadata">
                            <div className="">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="card">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-3">
                                          <div className="d-flex mt-4 mt-md-0">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-24">
                                                <i className="ri-exchange-line"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6>Enterprise Package</h6>
                                              <p className="text-muted mb-0">
                                                {enterpriseName ||
                                                  'Input protocol name'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="d-flex">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-24">
                                                <i className="ri-checkbox-circle-line"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6 className="light">
                                                Protocol Type
                                              </h6>
                                              <p className="text-muted mb-0">
                                                {name || 'Select farm type'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="d-flex mt-4 mt-md-0">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                                <i className="fal fa-weight-hanging"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6>Minimum Unit</h6>
                                              <p className="text-muted mb-0">
                                                {minUnit ||
                                                  'Select minimum unit'}{' '}
                                                {measuringUnit}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="d-flex mt-4 mt-md-0">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                                <i className="fal fa-store"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6>Organisation</h6>
                                              <p className="text-muted mb-0">
                                                {manufacturerName ||
                                                  'Select an organisation'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row mt-3">
                                        <div className="col-md-3">
                                          <div className="d-flex mt-4 mt-md-0">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                                <i className="fal fa-box-check"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6>Product</h6>
                                              <p className="text-muted mb-0">
                                                {productName ||
                                                  'Select product'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="d-flex mt-4 mt-md-0">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                                <i className="fal fa-folder-tree"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6>Product Category</h6>
                                              <p className="text-muted mb-0">
                                                {(productObject &&
                                                  productObject.name) ||
                                                  'Select product category'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="d-flex mt-4 mt-md-0">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                                <i className="fal fa-coins"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6>Expected Point</h6>
                                              <p className="text-muted mb-0">
                                                {productObject &&
                                                  productObject.expectedPoint
                                                  ? productObject.expectedPoint
                                                  : 'Expected Point'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="d-flex mt-4 mt-md-0">
                                            <div className="avatar-sm me-3">
                                              <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                                <i className="fal fa-clock"></i>
                                              </span>
                                            </div>
                                            <div className="flex-1 align-self-center overflow-hidden">
                                              <h6>Cycle Duration</h6>
                                              <p className="text-muted mb-0">
                                                {(productObject &&
                                                  productObject.cycleDuration &&
                                                  `${productObject.cycleDuration} Hrs`) ||
                                                  'Select cycle duration'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <!-- end row --> */}
                            </div>
                            <h4 className="card-title">Farmsured Benefits</h4>
                            <p className="card-title-desc">
                              Assign selected benefits to a Farmsured package
                            </p>

                            <form>
                              <div className="container">
                                <div className="table-responsive">
                                  <table className="table table-borderless">
                                    <thead>
                                      <tr>
                                        <th scope="col-8">Benefits</th>
                                        <th scope="col-2">
                                          Min. Points to Qualify
                                        </th>
                                        <th scope="col-3">Timeframe</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {benefitsItems
                                        ? benefitsItems.map((item) => (
                                          <tr key={item._id}>
                                            <td>
                                              <div className="card-title">
                                                {item.benefitName}
                                              </div>
                                            </td>
                                            <td>
                                              <div className="input-group mb-3">
                                                <div className="card-title">
                                                  {item.minPointToQualify}
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <div
                                                className="input-timerange input-group"
                                                id="timepicker6"
                                              >
                                                <div className="card-title card-body-sure">
                                                  <ul>
                                                    <li>
                                                      {item.benefitFrom}
                                                    </li>
                                                    <li>-</li>
                                                    <li>
                                                      {item.benefitTo} {''}
                                                      <span>Hrs</span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        ))
                                        : null}
                                    </tbody>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="card-title">
                                            <select
                                              className="form-select input-group input-group-sm"
                                              required
                                              value={benefit}
                                              onChange={(e) => (
                                                setBenefit(e.target.value),
                                                setBenefitName(
                                                  e.target.options[
                                                    e.target.selectedIndex
                                                  ].text,
                                                )
                                              )}
                                            >
                                              <option>Choose...</option>
                                              {benefitsLoading ? (
                                                <>Loading...</>
                                              ) : allbenefits.length === 0 ? (
                                                <>
                                                  <option>
                                                    Please create a benefit
                                                  </option>
                                                </>
                                              ) : (
                                                allbenefits.map((item) => (
                                                  <option
                                                    value={item._id}
                                                    key={item._id}
                                                  >
                                                    {item.benefit}
                                                  </option>
                                                ))
                                              )}
                                            </select>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="input-group mb-3">
                                            <input
                                              type="number"
                                              className="form-control"
                                              aria-label="Sizing example input"
                                              aria-describedby="inputGroup-sizing-sm"
                                              value={minPointToQualify}
                                              placeholder="0.00"
                                              required
                                              onChange={(e) =>
                                                setMinPointToQualify(
                                                  e.target.value,
                                                )
                                              }
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            className="input-timerange input-group"
                                            id="timepicker6"
                                          >
                                            <input
                                              type="number"
                                              className="form-control"
                                              name="start"
                                              placeholder="0.00"
                                              required
                                              time-format="HH:mm"
                                              value={benefitFrom}
                                              onChange={(e) =>
                                                setBenefitFrom(e.target.value)
                                              }
                                            />
                                            <input
                                              type="number"
                                              className="form-control"
                                              name="end"
                                              required
                                              placeholder="0.00"
                                              value={benefitTo}
                                              onChange={(e) =>
                                                setBenefitTo(e.target.value)
                                              }
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <button
                                            type="submit"
                                            className="btn btn-success"
                                            onClick={addBenefitHandler}
                                          >
                                            Add
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </form>
                          </div>

                          {/* Five phase */}
                          <div className="tab-pane" id="review">
                            <div className="row">
                              <div className="col-lg-8">
                                <div className="">
                                  {activityList.length === 0 ? (
                                    <div className="centerActivity">
                                      <p>
                                        <i
                                          className="fas fa-ban"
                                          style={{
                                            fontSize: '80px',
                                            color: 'red',
                                            display: 'block',
                                          }}
                                        ></i>
                                        <br />
                                        Activity is empty
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="scroll">
                                      {activityList.map((item) => (
                                        <div className="">
                                          <ul className="card-box mb-2">
                                            <li className="cards__item">
                                              <div className="box_content bg-transparent">
                                                <div className="card__content">
                                                  <div className="mb-1 text-black">
                                                    <div className="row">
                                                      <div className="col-md-8">
                                                        <h5>
                                                          {item.farmActivity}
                                                        </h5>
                                                        <p className="card-text text-dark">
                                                          {item.expectedTask}
                                                        </p>
                                                      </div>
                                                      <div
                                                        className="col-md-4"
                                                        style={{
                                                          textAlign: 'center',
                                                        }}
                                                      >
                                                        {item.activity ? (
                                                          <>
                                                            {' '}
                                                            {item.tagColor ? (
                                                              <div className="container__text__timing_time font-size-11">
                                                                {item.tagColor}
                                                              </div>
                                                            ) : null}{' '}
                                                            {item.activity ===
                                                              'Scan Activity' ? (
                                                              <i
                                                                className="fa fa-qrcode text-dark"
                                                                style={{
                                                                  fontSize:
                                                                    '60px',
                                                                }}
                                                              ></i>
                                                            ) : (
                                                              <i
                                                                className="fa fa-file text-dark center"
                                                                style={{
                                                                  fontSize:
                                                                    '60px',
                                                                }}
                                                              ></i>
                                                            )}
                                                          </>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                            <li className="cards__item">
                                              <div className="card-content">
                                                <div className="card-icons text-white bg-warning mb-1">
                                                  <i className="fa fa-clock me-2"></i>{' '}
                                                  {item.timeLineFrom} -{' '}
                                                  {item.timeLineTo} hrs
                                                </div>
                                                <div className="card-icons text-white bg-dark mb-1">
                                                  <i className="fa fa-coins me-2"></i>{' '}
                                                  {item.point && item.point}{' '}
                                                  points
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="p-3 bg-light mb-2">
                                  <div className="row">
                                    <div className="col-6 mb-1">
                                      <div className="d-flex mt-4 mt-md-0">
                                        <div className="avatar-sm me-3">
                                          <span className="avatar-title bg-light rounded-circle text-success font-size-24">
                                            <i className="ri-exchange-line"></i>
                                          </span>
                                        </div>
                                        <div className="flex-1 align-self-center overflow-hidden">
                                          <h6>Enterprise Name</h6>
                                          <p className="text-muted mb-0">
                                            {enterpriseName ||
                                              'Select enterprise package'}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6 mb-1">
                                      <div className="d-flex mt-4 mt-md-0">
                                        <div className="avatar-sm me-3">
                                          <span className="avatar-title bg-light rounded-circle text-success font-size-24">
                                            <i className="ri-checkbox-circle-line"></i>
                                          </span>
                                        </div>
                                        <div className="flex-1 align-self-center overflow-hidden">
                                          <h6>Protocol Type</h6>
                                          <p className="text-muted mb-0">
                                            {name || 'Select farm type'}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="p-3 bg-light mb-2">
                                  <div className="row">
                                    <div className="col-6 mb-1">
                                      <div className="d-flex mt-4 mt-md-0">
                                        <div className="avatar-sm me-3">
                                          <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                            <i className="fal fa-weight-hanging"></i>
                                          </span>
                                        </div>
                                        <div className="flex-1 align-self-center overflow-hidden">
                                          <h6>Minimum Unit</h6>
                                          <p className="text-muted mb-0">
                                            {minUnit || 'Select minimum unit'}{' '}
                                            {measuringUnit}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6 mb-1">
                                      <div className="d-flex mt-4 mt-md-0">
                                        <div className="avatar-sm me-3">
                                          <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                            <i className="fal fa-store"></i>
                                          </span>
                                        </div>
                                        <div className="flex-1 align-self-center overflow-hidden">
                                          <h6>Organisation</h6>
                                          <p className="text-muted mb-0">
                                            {manufacturerName ||
                                              'Input organisation'}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="p-3 bg-light mb-2">
                                  <div className="row">
                                    <div className="col-6 mb-1">
                                      <div className="d-flex mt-4 mt-md-0">
                                        <div className="avatar-sm me-3">
                                          <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                            <i className="fal fa-box-check"></i>
                                          </span>
                                        </div>
                                        <div className="flex-1 align-self-center overflow-hidden">
                                          <h6>Product</h6>
                                          <p className="text-muted mb-0">
                                            {productName || 'Select product'}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6 mb-1">
                                      <div className="d-flex mt-4 mt-md-0">
                                        <div className="avatar-sm me-3">
                                          <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                            <i className="fal fa-folder-tree"></i>
                                          </span>
                                        </div>
                                        <div className="flex-1 align-self-center overflow-hidden">
                                          <h6>Product Category</h6>
                                          <p className="text-muted mb-0">
                                            {(productObject &&
                                              productObject.name) ||
                                              'Select category'}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="p-3 bg-light mb-2">
                                  <div className="row">
                                    <div className="col-6 mb-1">
                                      <div className="d-flex mt-4 mt-md-0">
                                        <div className="avatar-sm me-3">
                                          <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                            <i className="fal fa-coins"></i>
                                          </span>
                                        </div>
                                        <div className="flex-1 align-self-center overflow-hidden">
                                          <h6>Expected Point</h6>
                                          <p className="text-muted mb-0">
                                            {productObject &&
                                              productObject.expectedPoint
                                              ? productObject.expectedPoint
                                              : 'Expected Point'}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6 mb-1">
                                      <div className="d-flex mt-4 mt-md-0">
                                        <div className="avatar-sm me-3">
                                          <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                            <i className="fal fa-clock"></i>
                                          </span>
                                        </div>
                                        <div className="flex-1 align-self-center overflow-hidden">
                                          <h6>Cycle Duration</h6>
                                          <p className="text-muted mb-0">
                                            {(productObject &&
                                              productObject.cycleDuration &&
                                              `${productObject.cycleDuration} Hrs`) ||
                                              'Cycle duration'}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className=" mt-4">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="card benefit-card">
                                        <div className="card-body">
                                          <h4 className="card-title text-white">
                                            Farmsured Benefits
                                          </h4>
                                          {benefitList.length === 0 ? (
                                            <div className="centerBenefit">
                                              <p>Benefit is empty</p>
                                            </div>
                                          ) : (
                                            <ul className="list-unstyled product-desc-list">
                                              <table className="table table-borderless table-style">
                                                <thead>
                                                  <tr>
                                                    <th
                                                      className="p-0 mt-1"
                                                      scope="col-6"
                                                    >
                                                      Benefits
                                                    </th>
                                                    <th
                                                      className="p-0 mt-1"
                                                      scope="col-3"
                                                    >
                                                      Timeframe(hrs)
                                                    </th>
                                                    <th
                                                      className="p-0 mt-1"
                                                      scope="col-3"
                                                    >
                                                      Points
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {benefitList
                                                    ? benefitList.map(
                                                      (item) => (
                                                        <tr key={item._id}>
                                                          <td>
                                                            <i className="far fa-gem text-white ml-0 me-1 align-middle font-size-16"></i>
                                                            {item.benefitName}
                                                          </td>
                                                          <td>
                                                            {item.benefitFrom}{' '}
                                                            - {item.benefitTo}
                                                          </td>
                                                          <td>
                                                            {
                                                              item.minPointToQualify
                                                            }
                                                          </td>
                                                        </tr>
                                                      ),
                                                    )
                                                    : null}
                                                </tbody>
                                              </table>
                                            </ul>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="mt-3 mb-2">
                                  <div className="row">
                                    <div className="col-6 mb-1">
                                      <div
                                        className={`${totalPoint ===
                                          Number(
                                            productObject &&
                                            productObject.expectedPoint,
                                          )
                                          ? 'protocol-point-success-card'
                                          : 'protocol-point-danger-card'
                                          } point-div p-3`}
                                        style={{ borderRadius: '20px' }}
                                      >
                                        <div className="d-flex mt-4 mt-md-0">
                                          <div className="avatar-sm me-3">
                                            <span className="avatar-title rounded-circle  font-size-20">
                                              <i className="fal fa-coins"></i>
                                            </span>
                                          </div>
                                          <div className="flex-1 align-self-center overflow-hidden">
                                            <h6 className="text-white">
                                              Protocol Point
                                            </h6>
                                            <p className=" mb-0 font-size-24">
                                              <b>{totalPoint || 0}</b>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-6 mb-3">
                                      <div
                                        className={`${totalDuration ===
                                          Number(
                                            productObject &&
                                            productObject.cycleDuration,
                                          )
                                          ? 'protocol-point-success-card'
                                          : ' protocol-point-danger-card'
                                          } point-div p-3`}
                                        style={{ borderRadius: '20px' }}
                                      >
                                        <div className="d-flex mt-4 mt-md-0">
                                          <div className="avatar-sm me-3">
                                            <span className="avatar-title rounded-circle  font-size-20">
                                              <i className="fal fa-clock"></i>
                                            </span>
                                          </div>
                                          <div className="flex-1 align-self-center overflow-hidden">
                                            <h6 className="text-white">
                                              Protocol Duration
                                            </h6>
                                            <p className="font-size-24 mb-0">
                                              <b>{totalDuration || 0}</b>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <span className="text-danger mt-1">
                                    NOTE: **Proceed only when <b>Protocol Point</b> Vs{' '}
                                    <b>Expected Point</b> and {' '}
                                    <b>Protocol Duration</b> Vs{' '}
                                    <b>Cycle Duration</b> repectively
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="text-center mt-4">
                              <button
                                type="submit"
                                className="btn btn-success me-2 waves-effect waves-light"
                                onClick={handleSavePackage}
                              >
                                Save Farmsured package
                              </button>
                              <button
                                type="reset"
                                className="btn btn-light waves-effect"
                                onClick={handleClear}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* page direction */}
                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                          <li className="previous">
                            <Link to="#">Previous</Link>
                          </li>

                          <li className="next">
                            <Link to="#" id="next-button">
                              Next
                            </Link>
                          </li>
                        </ul>

                        {/* Edit Modal */}
                        <Dialog
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="form-dialog-title"
                        >
                          <>
                            <div className="modal-header">
                              <h5 className="modal-title">
                                Edit compliance activity
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                              ></button>
                            </div>
                            <DialogContent className="dialog_content">
                              <div className="">
                                <form>
                                  <div className="row mb-3">
                                    <label
                                      htmlFor="example-text-input"
                                      className="col-md-12 col-form-label"
                                    >
                                      Activity
                                    </label>
                                    <div className="col-md-12">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Activity"
                                        id="example-text-input"
                                        value={activity}
                                        onChange={(e) =>
                                          setActivity(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  {activity && activity === 'Scan Activity' ? (
                                    <div className="row mb-3">
                                      <label
                                        htmlFor="example-text-input"
                                        className="col-md-12 col-form-label"
                                      >
                                        Tag Color
                                      </label>
                                      <div className="col-md-12">
                                        <select
                                          className="form-control"
                                          type="text"
                                          placeholder="Activity"
                                          id="example-text-input"
                                          value={tagColor}
                                          onChange={(e) =>
                                            setTagColor(e.target.value)
                                          }
                                        >
                                          <option value="Verify (RED Label)">
                                            Verify (RED Label)
                                          </option>
                                          <option value="Activate (GREEN Label)">
                                            Activate (GREEN Label)
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="row mb-3">
                                    <label
                                      htmlFor="example-text-input"
                                      className="col-md-12 col-form-label"
                                    >
                                      Farm Activity
                                    </label>
                                    <div className="col-md-12">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        id="example-text-input"
                                        value={farmActivity}
                                        onChange={(e) =>
                                          setScanFarmActivity(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <label
                                      htmlFor="example-text-input"
                                      className="col-md-12 col-form-label"
                                    >
                                      Expected Task
                                    </label>
                                    <div className="col-md-12">
                                      <textarea
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        id="example-text-input"
                                        value={expectedTask}
                                        onChange={(e) =>
                                          setScanExpectedTask(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <label
                                      htmlFor="example-text-input"
                                      className="col-md-12 col-form-label"
                                    >
                                      Timeline
                                    </label>
                                    <div className="col-md-6">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        id="example-text-input"
                                        value={timelineFrom}
                                        onChange={(e) =>
                                          setScanTimelineFrom(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        id="example-text-input"
                                        value={timelineTo}
                                        onChange={(e) =>
                                          setScanTimelineTo(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <label
                                      htmlFor="example-text-input"
                                      className="col-md-12 col-form-label"
                                    >
                                      Point Allocated
                                    </label>

                                    <div className="col-md-12">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Points"
                                        id="example-text-input"
                                        disabled={
                                          activity === 'Scan Activity'
                                            ? true
                                            : false
                                        }
                                        value={point}
                                        onChange={(e) =>
                                          setScanPoint(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <DialogActions>
                                    <button
                                      className="btn btn-success btn-sm"
                                      type="submit"
                                      onClick={editItem}
                                    >
                                      Update
                                    </button>
                                  </DialogActions>
                                </form>
                              </div>
                            </DialogContent>
                          </>
                        </Dialog>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* <!-- end row --> */}
            {alert}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AddPackagePage
