import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { verifyUserID } from '../../../actions/authActions/farmtroveAction/verifyUserID'
import { fundAllocation } from '../../../actions/adminActions/farmtroveActions/fundAllocation'
import { getFundAllocationHistory } from '../../../actions/adminActions/farmtroveActions/getFundAllocationHistory'


import { MessageBox, LoadingBox } from '../../../components'
import { FUND_MAIN_WALLET_RESET, FUND_USER_RESET } from '../../../constants/adminConstants/farmtrove/fundUserConstants'
import { VERIFY_WALLET_USERID_RESET } from '../../../constants/authConstants/walletConstants'
import { MDBDataTableV5 } from 'mdbreact'
import { formatNumber } from '../../../components/functions'
import moment from 'moment'
import { TailSpin } from 'react-loading-icons'
import sendOtpCode from '../../../actions/adminActions/farmtroveActions/sendOtpCode'
import verifyAdminOtp from '../../../actions/adminActions/farmtroveActions/verifyAdminOtp'
import { fundAccount } from '../../../actions/adminActions/farmtroveActions/fundAccount'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { SEND_OTP_CODE_RESET, VERIFY_OTP_CODE_RESET } from '../../../constants/adminConstants/farmtrove/verifyAdminConstants'
import { getMainWalletBalance } from '../../../actions/adminActions/farmtroveActions/getMainWalletBalance'

export default function FundAllocationPage() {
  const [datatable, setDatatable] = useState({})
  const [show, setShow] = useState(false)
  const [phone, setPhone] = useState('')
  const [amount, setAmount] = useState('')
  const [fundAmount, setFundAmount] = useState('')
  const [recipient, setRecipient] = useState('')
  const [remark, setRemark] = useState('')
  const [alert, setAlert] = useState(null)
  const [dotsVisible, setDotsVisible] = useState('')
  const [password, setPassword] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [showFrame1, setShowFrame1] = useState(true)
  const [showFrame2, setShowFrame2] = useState(false)
  const [showFrame3, setShowFrame3] = useState(false)
  const [otp, setOtp] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    const interval = setInterval(() => {
      // Toggle the visibility of dots by adding/removing them
      setDotsVisible((prev) => (prev === '...' ? '' : '...'))
    }, 500) // Change the interval as needed

    return () => {
      clearInterval(interval) // Clear the interval on component unmount
    }
  }, [])

  const verifyId = useSelector((state) => state.verifyId)
  const { loading: loadingVerifyID, verified, error: verifyIDError } = verifyId

  const fundUser = useSelector((state) => state.fundUser)
  const { loading, sent, error } = fundUser

  const fundUserHistory = useSelector((state) => state.fundUserHistory)
  const { loading: loadingTransaction, transactions, error: transactionError } = fundUserHistory


  const sendOtp = useSelector((state) => state.sendOtp)
  const { loading: optLoading, error: otpError, result } = sendOtp

  const verifyOtp = useSelector((state) => state.verifyOtp)
  const { loading: verifyLoading, error: verifyError, verify } = verifyOtp

  const fundMain = useSelector((state) => state.fundMain)
  const { loading: loadingFund, error: fundError, funded } = fundMain

  const adminWalletBalance = useSelector((state) => state.adminWalletBalance)
  const { loading: loadingWallet, error: walletError, wallet } = adminWalletBalance

  const handleInputChange = (e) => {
    const value = e.target.value
    setRecipient(value)
    if (/^\d{10}$/.test(value)) {
      dispatch(verifyUserID(value)) // Replace 'YOUR_ACTION_TYPE' with the actual action type.
    } else if (value.length > 10) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Invalid Recipient"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          User ID can't be more than 10 digits.
        </SweetAlert>,
      )
    } else {
      setRecipient(value)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(fundAllocation(amount, recipient, remark, password))
  }

  useEffect(() => {
    dispatch(getFundAllocationHistory())
    dispatch(getMainWalletBalance())

    if (funded) {
      setTimeout(() => {
        setOpenDialog(false)
        setShowFrame1(true)
        setShowFrame2(false)
        setShowFrame3(false)
        dispatch({ type: FUND_MAIN_WALLET_RESET })
      }, 2500)
    }
  }, [sent, funded])

  useEffect(() => {
    if (result) {
      setTimeout(() => {
        setShowFrame1(false)
        setShowFrame2(true)
        dispatch({ type: SEND_OTP_CODE_RESET })
      }, 2500)
    }

    if (verify) {
      setShowFrame1(false)
      setShowFrame2(false)
      setShowFrame3(true)
      setTimeout(() => {
        dispatch({ type: VERIFY_OTP_CODE_RESET })
      }, 2500)
    }
  }, [result, verify])


  useEffect(() => {
    if (transactions) {
      setDatatable({
        columns: [
          {
            label: 'Wallet ID',
            field: 'walletId',
            width: 100,
          },
          {
            label: 'Recipient',
            field: 'receiver',
            width: 50,
          },
          {
            label: 'amount',
            field: 'amount',
            width: 200,
          },
          {
            label: 'Date',
            field: 'date',
            width: 50,
          },
          {
            label: 'Sender (Admin)',
            field: 'sender',
            width: 50,
          },
          {
            label: 'Transaction Type',
            field: 'type',
          },
          {
            label: 'Status',
            field: 'status',
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: transactions.map((item) => ({
          walletId: item.receiver && item.receiver.phone,
          receiver:
            item.receiver && item.receiver.firstName && item.receiver.lastName
              ? `${item.receiver.firstName} ${item.receiver.lastName}`
              : '',
          amount: item.amount ? `NGN ${formatNumber(item.amount)}` : "NGN0",
          date: moment(item.timestamp).format('Do MMMM YYYY,  h:mm a'),
          sender:
            item.sender && item.sender.firstName && item.sender.lastName
              ? `${item.sender.firstName} ${item.sender.lastName}`
              : '',
          type: <div
            className={`badge badge-soft-${item.type === 'credit'
              ? 'success'
              : item.type === 'debit'
                ? 'danger'
                : ''
              } font-size-13`}
          >
            {item.type}
          </div>,
          status: <div
            className={`badge badge-soft-${item.status === 'pending'
              ? 'primary'
              : item.status === 'successful'
                ? 'success'
                : item.status === 'processing'
                  ? 'warning'
                  : item.status === 'declined'
                    ? 'danger'
                    : ''
              } font-size-13`}
          >
            {item.status}
          </div>,
          action: <a href={`/farmtrove/user-wallet/${item.receiver && item.receiver._id}`} className="btn btn-sm btn-success">Details</a>,
        })),
      })
    }
  }, [transactions])

  useEffect(() => {
    if (sent) {
      dispatch({ type: VERIFY_WALLET_USERID_RESET })
      setAmount('')
      setPassword('')
      setRecipient('')
      setRemark('')
      setTimeout(() => {
        setShow(!show)
        dispatch({ type: FUND_USER_RESET })
      }, 2000)
    }
  }, [sent])

  useEffect(() => {
    if (verifyError) {
      setOtp('')
      dispatch({ type: VERIFY_OTP_CODE_RESET })
    }
  }, [verifyError])


  const sendOtpHandler = (e) => {
    e.preventDefault()
    dispatch(sendOtpCode(phone))
  }

  const verifyHandler = (e) => {
    e.preventDefault()

    dispatch(verifyAdminOtp(otp, phone))
  }

  const handleFundSubmit = (e) => {
    e.preventDefault()

    dispatch(fundAccount(fundAmount, phone))
  }


  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Fund Allocation</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmtrove</a>
                      </li>
                      <li className="breadcrumb-item active">Fund Allocation</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {optLoading ? <LoadingBox /> : null}
            {loadingFund ? <LoadingBox /> : null}
            <div className='row'>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="card bg-soft-success"
                      style={{ borderRadius: '15px' }}
                    >
                      <div className="card-body">
                        <h3 className="card-title font-size-14">
                          <b>Main Wallet</b>
                        </h3>
                        {loadingWallet ? (
                          <div
                            className="tailspin"
                            style={{ height: 'calc(20vh - 40px)' }}
                          >
                            <TailSpin
                              stroke="white"
                              strokeWidth="3"
                              color="white"
                            />
                          </div>
                        ) : walletError ? (<div>
                          <small className="card-desc font-size-14">
                            {walletError}
                          </small>
                        </div>) : (
                          <h3 className="card-title font-size-34 mt-4 wallet-bal" >
                            <b>  NGN{' '}
                              {wallet
                                ? formatNumber(wallet.balance)
                                : '0.00'}</b>
                          </h3>
                        )}
                        <button
                          className="btn btn-sm btn-success mt-4"
                          onClick={() => setOpenDialog(true)}

                        >
                          Fund Wallet
                        </button>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="mb-3">
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => setShow(!show)}
                >
                  <i className="fal fa-plus"></i>{' '}
                  {show ? 'Hide Form' : 'Allocate Fund'}
                </button>
              </div>

              {
                show &&
                <div className='card'>
                  <div className='card-body'>
                    {error ? (
                      <MessageBox variant="danger">{error}</MessageBox>
                    ) : null}
                    {sent ? (
                      <MessageBox variant="success">{sent.message}</MessageBox>
                    ) : null}
                    <div>
                      {
                        loading
                          ?
                          <LoadingBox />
                          :
                          <div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="mb-1 mt-1">
                                  <label className="form-label" forhtml="amount">
                                    Amount
                                  </label>
                                  <input
                                    id="amount"
                                    name="amount"
                                    type="number"
                                    className="form-control"
                                    required
                                    onChange={(e) => setAmount(e.target.value)}
                                    value={amount}
                                    placeholder="NGN 200.00 - 5,000,000.00"
                                  />
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="mb-1 mt-4">
                                  <label className="form-label" forhtml="account">
                                    Recipient Account
                                  </label>
                                  <input
                                    id="account"
                                    name="account"
                                    type="number"
                                    className="form-control"
                                    required
                                    onChange={handleInputChange}
                                    value={recipient}
                                    placeholder="Phone No./Farmsured User ID"
                                  />
                                </div>
                                <small><i>Wallet ID is without the first 0</i></small>
                              </div>

                              {loadingVerifyID ? (
                                <div className="col-md-12">
                                  <div className="card bg-success text-white mb-0">
                                    <div className="card-body p-2">
                                      <span className="p-1 m-0">{dotsVisible}</span>
                                    </div>
                                  </div>
                                </div>
                              ) : verifyIDError ? (
                                <div className="col-md-12">
                                  <div className="card bg-danger text-white mb-0">
                                    <div className="card-body p-2">
                                      <span className="p-1 m-0">{verifyIDError}</span>
                                    </div>
                                  </div>
                                </div>
                              ) : verified ? (
                                <div className="col-md-12">
                                  <div className="card bg-success text-white mb-0">
                                    <div className="card-body p-2">
                                      <span className="p-1 m-0">
                                        {verified && verified.phone && verified.phone}{' '}
                                        {verified &&
                                          verified.firstName &&
                                          verified.lastName
                                          ? `(${verified.firstName} ${verified.lastName})`
                                          : ''}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-12">
                                <div className="mb-2 mt-4">
                                  <label className="form-label" forhtml="remark">
                                    Remark
                                  </label>
                                  <input
                                    id="remark"
                                    name="remark"
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setRemark(e.target.value)}
                                    value={remark}
                                    placeholder="What's this for? (Optional)"
                                  />
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="mb-2 mt-4">
                                  <label className="form-label" forhtml="remark">
                                    Password
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="userpassword"
                                    placeholder="Enter password"
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mb-3">
                              <div className="col-md-12">
                                <div className="d-grid">
                                  <button
                                    className="btn btn-md btn-success mt-2"
                                    type="button"
                                    onClick={handleSubmit}
                                    disabled={
                                      amount === '' || recipient === '' || password === '' ? true : false
                                    }
                                  >
                                    <i className="fal fa-arrow-right me-3"></i> Credit Account
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              }
            </div>


            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title font-size-14">
                    Fund Allocation History
                  </h3>
                  {transactionError && (
                    <MessageBox variant="danger">{transactionError}</MessageBox>
                  )}
                  {loadingTransaction ? (
                    <div
                      className="tailspin"
                      style={{ height: 'calc(50vh - 50px)' }}
                    >
                      <TailSpin
                        stroke="#98ff98"
                        strokeWidth="3"
                        color="#06bcee"
                      />
                    </div>
                  ) : (
                    <div className="table-responsive-xl">
                      <MDBDataTableV5
                        hover
                        entriesOptions={[5, 10, 20, 25, 50]}
                        entries={10}
                        pagesAmount={4}
                        data={datatable}
                        searchTop
                        searchBottom={false}
                        barReverse
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>



            {/* ==================
            ==== Fund Dialog =====
            ====================*/}
            <Dialog
              open={openDialog}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth="lg"
            >
              <div className="modal-header">
                <h5 className="modal-title">Fund Account</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setOpenDialog(false)}
                ></button>
              </div>
              <DialogContent>
                {verifyLoading ? <LoadingBox /> : null}
                {otpError ? (
                  <MessageBox variant="danger">{otpError}</MessageBox>
                ) : null}
                {result ? (
                  <MessageBox variant="success">
                    {result.message}
                  </MessageBox>
                ) : null}
                {verifyError ? (
                  <MessageBox variant="danger" > {verifyError}</MessageBox>
                ) : null}

                {verify ? (
                  <MessageBox variant="success">
                    {verify.message}
                  </MessageBox>
                ) : null}

                {fundError ? (
                  <MessageBox variant="danger">
                    {fundError}
                  </MessageBox>
                ) : null}

                {
                  showFrame1 ?
                    <div>
                      <p className="m-0 font-size-16 card-desc">We need to verify you before you proceed</p>
                      <div className='row'>
                        <div className="col-md-12">
                          <div className="mb-2 mt-4">
                            <label className="form-label" forhtml="remark">
                              Verify the User ID
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="phoneNumber"
                              placeholder="User ID"
                              required
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mt-3 mb-5 d-grid">
                          <button className="btn btn-sm block btn-success" onClick={sendOtpHandler}>
                            Proceed
                          </button>
                        </div>
                      </div>
                    </div>
                    : null
                }


                {
                  showFrame2 ?
                    <div>
                      <h4 className="font-size-18 mt-4">
                        We sent your code !
                      </h4>
                      <p className="text-muted">
                        Enter the confirmation code below
                      </p>

                      <div className="p-2 mt-3">
                        <form className="" onSubmit={verifyHandler}>
                          <div className="mb-3 auth-form-group-custom mb-4">
                            <i className="ri-account-pin-box-line auti-custom-input-icon"></i>
                            <label htmlFor="email">One Time Password</label>
                            <input
                              type="number"
                              className="form-control"
                              id="otp"
                              placeholder="Enter OTP Code"
                              required
                              onChange={(e) => setOtp(e.target.value)}
                              value={otp}
                            />
                          </div>

                          <div className="mt-4 d-grid">
                            <button
                              className="btn btn-success w-md waves-effect waves-light"
                              type="submit"
                              onClick={verifyHandler}
                            >
                              Verify
                            </button>
                          </div>

                          <div class="content d-flex align-items-center mt-4">
                            <span>Didn't get the code?</span>
                            <button
                              onClick={sendOtpHandler}
                              className=" btn text-decoration-none"
                            >
                              Resend
                            </button>
                          </div>

                        </form>
                      </div>
                    </div> : null
                }

                {
                  showFrame3 ?
                    <div>
                      {funded ? (<MessageBox variant="success">
                        {funded.message}
                      </MessageBox>
                      ) : null}
                      <div className="col-md-12">
                        <div className="mb-1 mt-1">
                          <label className="form-label" forhtml="amount">
                            Amount
                          </label>
                          <input
                            id="amount"
                            name="amount"
                            type="number"
                            className="form-control"
                            required
                            onChange={(e) => setFundAmount(e.target.value)}
                            value={fundAmount}
                            placeholder="NGN 200.00 - 5,000,000.00"
                          />
                        </div>
                        <div className="col-md-12 mt-3 mb-5 d-grid">
                          <button className="btn btn-sm  btn-success" onClick={handleFundSubmit}>
                            Fund Account
                          </button>
                        </div>
                      </div>
                    </div>
                    : null
                }

                {/* <div>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3 mt-3">
                          <label className="form-label" forhtml="amount">
                            Top-up amount
                          </label>
                          <input
                            id="amount"
                            name="amount"
                            type="number"
                            className="form-control"
                            required
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="NGN 200.00 - 5,000,000.00"
                            value={amount}
                          />
                        </div>
                      </div>


                      <div className="col-md-12">
                        <div className="d-grid">
                          <button
                            // onClick={handleSubmit}
                            type="submit"
                            className="btn btn-success btn-md waves-effect"
                          >
                            Add Fund
                          </button>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="d-grid mt-3 mb-3">
                          <button
                            type="button"
                            className="btn btn-danger btn-md waves-effect"
                            data-bs-dismiss="modal"
                            onClick={() => setOpenDialog(false)}
                          >
                            Fund Later
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div> */}

              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
      {alert}
      <Footer />
    </div >
  )
}
