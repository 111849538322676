import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import register from '../../actions/authActions/register'
import { LoadingBox, MessageBox } from '../../components'
import { USER_REGISTER_RESET } from '../../constants/authConstants/RegisterContants'

function RegisterPage(props) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const userRegister = useSelector((state) => state.userRegister)
  const { loading, userInfo, error } = userRegister
  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(register(firstName, lastName, email, phone, password))
  }

  useEffect(() => {
    if (userInfo) {
      setTimeout(() => {
        props.history.push('/')
        dispatch({ type: USER_REGISTER_RESET })
      }, 2000)
    }

    return () => {
      //
    }
  }, [userInfo, props.history, dispatch])

  const date = new Date().getFullYear()
  return (
    <div>
      <div className="container-fluid p-0">
        {loading ? (
          <LoadingBox />
        ) : (
          <div className="row g-0">
            <div className="col-lg-4">
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  {error && <MessageBox variant="danger">{error}</MessageBox>}
                  {userInfo && (
                    <MessageBox variant="success">
                      Registration is successful.
                    </MessageBox>
                  )}
                  <div className="row justify-content-center">
                    <div className="col-lg-9">
                      <div>
                        <div className="text-center">
                          <div>
                            <a href="/" className="">
                              <img
                                src="assets/images/farmsured!.png"
                                alt=""
                                height="50"
                                className="auth-logo logo-dark mx-auto"
                              />
                              <img
                                src="assets/images/farmsured!.png"
                                alt=""
                                height="50"
                                className="auth-logo logo-light mx-auto"
                              />
                            </a>
                          </div>

                          <h4 className="font-size-18 mt-4">
                            Register account
                          </h4>
                          <p className="text-muted">
                            Get unboarded on the Farmsured account now.
                          </p>
                        </div>

                        <div className="p-2 mt-5">
                          <form className="" onSubmit={submitHandler}>
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-mail-line auti-custom-input-icon"></i>
                              <label htmlFor="useremail">Email</label>
                              <input
                                type="email"
                                className="form-control"
                                id="useremail"
                                placeholder="Enter email"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </div>

                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon"></i>
                              <label htmlFor="name">First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Enter first name"
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                              />
                            </div>

                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon"></i>
                              <label htmlFor="name">Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Enter last name"
                                onChange={(e) => setLastName(e.target.value)}
                                required
                              />
                            </div>

                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-phone-line auti-custom-input-icon"></i>
                              <label htmlFor="name">Phone</label>
                              <input
                                type="number"
                                className="form-control"
                                id="phone"
                                placeholder="Enter phone"
                                onChange={(e) => setPhone(e.target.value)}
                                required
                              />
                            </div>

                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <label htmlFor="userpassword">Password</label>
                              <input
                                type="password"
                                className="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                            </div>

                            <div className="text-center">
                              <button
                                className="btn btn-success w-md waves-effect waves-light"
                                type="submit"
                              >
                                Register
                              </button>
                            </div>

                            <div className="mt-3 text-center">
                              <p className="mb-0">
                                By registering you agree to the Farmsured{' '}
                                <a href="#" className="text-primary">
                                  Terms of Use
                                </a>
                              </p>
                            </div>
                          </form>
                        </div>

                        <div className="mt-4 text-center">
                          <p>
                            Already have an account?{' '}
                            <a href="/" className="fw-medium text-primary">
                              {' '}
                              Login
                            </a>{' '}
                          </p>
                          <p>
                            © {date} Farmsured
                            <sup>
                              <small>TM</small>
                            </sup>
                            {''} A product of AgDyna
                            <sup>
                              <i className="fal fa-registered font-size-12"></i>
                            </sup>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="authentication-bg position-relative">
                <div className="bg-overlay"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default RegisterPage
