import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import { MDBDataTableV5 } from 'mdbreact'
import { formatNumber } from '../../../components/functions'

export default function OrdersPage() {
    const [datatable, setDatatable] = useState({})

    useEffect(() => {
        // if (orders) {
        setDatatable({
            columns: [
                {
                    label: 'Order ID',
                    field: 'orderID',
                    width: 150,
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'orderID',
                    },
                },
                {
                    label: 'Date',
                    field: 'date',
                    width: 200,
                },

                {
                    label: 'Product',
                    field: 'product',
                    width: 100,
                },
                {
                    label: 'Quantity',
                    field: 'amount',
                    width: 100,
                },
                {
                    label: 'Amount',
                    field: 'price',
                    width: 200,
                },
                {
                    label: 'Total',
                    field: 'totalPrice',
                    width: 200,
                },
                {
                    label: 'Status',
                    field: 'status',
                    width: 100,
                    sort: 'disabled',
                },
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'disabled',
                },
            ],
            // rows: orders.map((item) => ({
            //     orderID: item.orderID,
            //     date: moment(item.created_at).format('Do MMMM YYYY,  h:mm a'),
            //     product: item.product && item.product.name,
            //     amount: item.quantity,
            //     status: (
            //         <div className={`badge badge-soft-${item.status} font-size-11`}>
            //             {item.status}
            //         </div>
            //     ),
            //     price: `NGN ${formatNumber(item.price)}`,
            //     totalPrice: `NGN ${formatNumber(item.total)}`,
            //     action: (
            //         <>
            //             <button
            //                 className="btn btn-sm btn-primary"
            //                 style={{ marginRight: '15px' }}
            //                 data-bs-container="#tooltip-container1"
            //                 data-bs-toggle="tooltip"
            //                 data-bs-placement="top"
            //                 title="Edit"
            //             // onClick={() => editProduct(item)}
            //             >
            //                 Detail
            //             </button>
            //         </>
            //     ),
            // })),
        })
        // }
    }, [])


    return (
        <div id="layout-wrapper">
            <Header />
            <LeftSidebar />
            <RightSidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">All Orders</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Farmsured</a>
                                            </li>
                                            <li className="breadcrumb-item active">Orders</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {
                            // loading
                            //     ?
                            //     <LoadingBox />
                            //     :
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            {/* {error ? <MessageBox variant='danger'>{error}</MessageBox> : null} */}
                                            <MDBDataTableV5
                                                hover
                                                entriesOptions={[5, 10, 20, 25, 50]}
                                                entries={10}
                                                pagesAmount={4}
                                                data={datatable}
                                                searchTop
                                                searchBottom={false}
                                                barReverse
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
