import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { getWalletBalance } from '../../actions/authActions/farmtroveAction/getWallet'
import MonoConnect from '@mono.co/connect.js'
import { addFund } from '../../actions/authActions/farmtroveAction/addFund'
// import { verifyTransaction } from '../../../actions/authActions/farmtroveAction/verifyTransaction'
import { TailSpin } from 'react-loading-icons'
import { LoadingBox, MessageBox } from '../../components'
import {
  INITIATE_ESCROW_TRANSFER_RESET,
  SEND_FUND_RESET,
  VERIFY_ACCOUNT_RESET,
  VERIFY_WALLET_USERID_RESET,
  WALLET_FUND_RESET,
  WITHDRAW_FUND_RESET,
} from '../../constants/authConstants/walletConstants'
import { sendFund } from '../../actions/authActions/farmtroveAction/sendFund'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { formatNumber, calculateMoneyFlow } from '../../components/functions'
import { getWalletTransaction } from '../../actions/authActions/farmtroveAction/getWalletTransactions'
import moment from 'moment'
import SweetAlert from 'react-bootstrap-sweetalert'
import { verifyUserID } from '../../actions/authActions/farmtroveAction/verifyUserID'
import { verifyAccountNumber } from '../../actions/authActions/farmtroveAction/verifyAccountNumber'
import Banks from '../../components/Banks'
import Select from 'react-select'
import { submitWithdrawRequest } from '../../actions/authActions/farmtroveAction/submitWithdrawRequest'
import { initialTransfer } from '../../actions/authActions/farmtroveAction/initialTransfer'
import { profileDetail } from '../../actions/authActions/profile'
import { allSecureTransfer } from '../../actions/authActions/farmtroveAction/allSecureTransfer'
import { allClaimTransfers } from '../../actions/authActions/farmtroveAction/allClaimTransfers'
import { MDBDataTableV5 } from 'mdbreact'

export default function WalletPage() {
  const [amount, setAmount] = useState()
  const [show, setShow] = useState(false)
  const [datatable, setDatatable] = useState({})
  const [datatable2, setDatatable2] = useState({})
  const [review, setReview] = useState(false)
  const [open, setOpen] = useState(false)
  const [openSendDialog, setOpenSendDialog] = useState(false)
  const [newSecureDialog, setNewSecureDialog] = useState(false)

  const [openWithdrawDialog, setOpenWithdrawDialog] = useState(false)
  const [sendAmount, setSendAmount] = useState('')
  const [recipient, setRecipient] = useState('')
  const [remark, setRemark] = useState('')
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false)
  const [alert, setAlert] = useState(null)
  const [dotsVisible, setDotsVisible] = useState('')
  const [bankCode, setBankCode] = useState('')
  const [account, setAccount] = useState('')
  const [accountName, setAccountName] = useState('')
  const [withdrawAmount, setWithdrawAmount] = useState('')
  const [withdrawRemark, setWithdrawRemark] = useState('')
  const [withdrawPIN, setWithdrawPIN] = useState('')
  const [walletPIN, setWalletPIN] = useState('')
  const [introDialog, setIntroDialog] = useState(false)
  const [secureTransferDialog, setSecureTransferDialog] = useState(false)
  const [claimFundsDialog, setClaimFundsDialog] = useState(false)

  const [detail, setDetail] = useState()
  const [showDialog, setShowDialog] = useState(false)


  const walletFund = useSelector((state) => state.walletFund)
  const { loading, codeId, error } = walletFund

  const walletBalance = useSelector((state) => state.walletBalance)
  const { loading: loadingWallet, wallet, error: walletErr } = walletBalance

  const verifyFund = useSelector((state) => state.verifyFund)
  const { loading: loadingVerify, success } = verifyFund

  const sendFunds = useSelector((state) => state.sendFunds)
  const { loading: loadingSend, sent, error: sendError } = sendFunds

  const verifyId = useSelector((state) => state.verifyId)
  const { loading: loadingVerifyID, verified, error: verifyIDError } = verifyId

  const verifyAccount = useSelector((state) => state.verifyAccount)
  const {
    loading: loadingVerifyAccount,
    accountDetail,
    error: verifyAccountError,
  } = verifyAccount

  const walletTransactions = useSelector((state) => state.walletTransactions)
  const {
    loading: loadingTransactions,
    transactions,
    error: transactionsError,
  } = walletTransactions

  const withdrawFund = useSelector((state) => state.withdrawFund)
  const {
    loading: loadingWithdraw,
    withdrawn,
    error: withdrawError,
  } = withdrawFund

  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  const userDetail = useSelector((state) => state.userDetail)
  const { loading: loadingDetails, user } = userDetail

  const newEscrowTransfer = useSelector((state) => state.newEscrowTransfer)
  const {
    loading: loadingTransfer,
    initiated,
    error: transferError,
  } = newEscrowTransfer

  const secureTransfers = useSelector((state) => state.secureTransfers)
  const {
    loading: loadingSecureTransactions,
    transfers,
    error: secureTransactionsError,
  } = secureTransfers

  const claimFunds = useSelector((state) => state.claimFunds)
  const {
    loading: loadingClaimFunds,
    claimTransfers,
    error: claimFundsError,
  } = claimFunds

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getWalletBalance())
    dispatch(getWalletTransaction())
    dispatch(profileDetail())
    dispatch(allSecureTransfer())
    dispatch(allClaimTransfers())
  }, [dispatch, success, sent, withdrawn, initiated])

  useEffect(() => {
    if (transfers) {
      setDatatable({
        columns: [
          {
            label: 'Recipient ID',
            field: 'recipientID',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'recipientID',
            },
          },
          {
            label: 'Full Name',
            field: 'name',
            width: 100,
          },
          {
            label: 'Amount',
            field: 'price',
            width: 200,
          },
          {
            label: 'Status',
            field: 'status',
            width: 100,
            sort: 'disabled',
          },
          {
            label: 'Date',
            field: 'date',
            width: 200,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: transfers.map((item) => ({
          date: moment(item.createdAt).format('Do MMMM YYYY,  h:mm a'),
          recipientID: item.receiver && item.receiver.phone,
          name: item.receiver
            ?
            <div style={{ textTransform: 'capitalize' }}>
              {`${item.receiver && item.receiver.firstName ? item.receiver.firstName : ''} ${item.receiver && item.receiver.lastName ? item.receiver.lastName : ''}`}
            </div>
            : '',
          status: (
            <div className={`badge badge-soft-${item.status} font-size-11`}>
              {item.status}
            </div>
          ),
          price: `NGN ${formatNumber(item.amount)}`,
          action: (
            <>
              <button
                className="btn btn-sm btn-primary"
                style={{ marginRight: '15px' }}
                data-bs-container="#tooltip-container1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Detail"
                onClick={() => openEditDialog(item)}
              >
                Detail
              </button>
            </>
          ),
        })),
      })
    }
  }, [transfers])

  useEffect(() => {
    if (claimTransfers) {
      setDatatable2({
        columns: [
          {
            label: 'Recipient ID',
            field: 'recipientID',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'recipientID',
            },
          },
          {
            label: 'Full Name',
            field: 'name',
            width: 100,
          },
          {
            label: 'Amount',
            field: 'price',
            width: 200,
          },
          {
            label: 'Status',
            field: 'status',
            width: 100,
            sort: 'disabled',
          },
          {
            label: 'Date',
            field: 'date',
            width: 200,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: claimTransfers.map((item) => ({
          date: moment(item.createdAt).format('Do MMMM YYYY,  h:mm a'),
          recipientID: item.receiver && item.receiver.phone,

          name: item.receiver
            ?
            <div style={{ textTransform: 'capitalize' }}>
              {`${item.receiver && item.receiver.firstName ? item.receiver.firstName : ''} ${item.receiver && item.receiver.lastName ? item.receiver.lastName : ''}`}
            </div>
            : '',
          status: (
            <div className={`badge badge-soft-${item.status} font-size-11`}>
              {item.status}
            </div>
          ),
          price: `NGN ${formatNumber(item.amount)}`,
          action: (
            <>
              <button
                className="btn btn-sm btn-primary"
                style={{ marginRight: '15px' }}
                data-bs-container="#tooltip-container1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Detail"
                onClick={() => openEditDialog(item)}
              >
                Detail
              </button>
            </>
          ),
        })),
      })
    }
  }, [claimTransfers])

  useEffect(() => {
    if (sent) {
      dispatch({ type: VERIFY_WALLET_USERID_RESET })
      setSendAmount('')
      setRecipient('')
      setRemark('')
      setReview(false)
      setTimeout(() => {
        dispatch({ type: SEND_FUND_RESET })
        setShow(false)
        setOpenSendDialog(false)
      }, 3000)
    }

    if (sendError) {
      setTimeout(() => {
        dispatch({ type: SEND_FUND_RESET })
      }, 4000)
    }
  }, [sent, sendError])

  useEffect(() => {
    if (withdrawn) {
      setTimeout(() => {
        setOpenWithdrawDialog(false)
        dispatch({ type: VERIFY_ACCOUNT_RESET })
        dispatch({ type: WITHDRAW_FUND_RESET })
      }, 2200)
      setBankCode('')
      setAccount('')
      setWithdrawAmount('')
      setWithdrawRemark('')
    }

    if (withdrawError) {
      dispatch({ type: VERIFY_ACCOUNT_RESET })
    }
  }, [withdrawn, withdrawError])

  useEffect(() => {
    if (initiated) {
      setTimeout(() => {
        setNewSecureDialog(false)
        dispatch({ type: INITIATE_ESCROW_TRANSFER_RESET })
      }, 5000)
      dispatch({ type: VERIFY_WALLET_USERID_RESET })
      setAmount()
      setRemark('')
      setRecipient('')
      setWalletPIN('')
    }

    if (transferError) {
      setWalletPIN('')
      setTimeout(() => {
        dispatch({ type: INITIATE_ESCROW_TRANSFER_RESET })
      }, 5000)
    }

  }, [initiated, transferError])


  useEffect(() => {
    if (codeId) {
      setOpen(false)
      setAmount('')
      setIsDeclarationChecked(false)
      setTimeout(() => {
        const connect = new MonoConnect({
          key: process.env.REACT_APP_MONO_PUBLIC_KEY,
          onSuccess: ({ code }) => {
            // console.log(code)
          },
          onEvent: (eventName, data) => {
            console.log(eventName)
            if (eventName === 'SUCCESS') {
              setAmount()
              setIsDeclarationChecked(!isDeclarationChecked)
              dispatch(getWalletBalance())
              dispatch(getWalletTransaction())
              setOpen(false)
              dispatch({ type: WALLET_FUND_RESET })
            }
          },
          scope: 'payments',
          data: {
            payment_id: codeId,
          },
        })

        connect.setup()
        connect.open()

        return connect
      }, 1000)
    }
  }, [codeId])

  const handleSubmit = (e) => {
    e.preventDefault()
    let amt = amount * 100
    dispatch(addFund(amt))
  }

  const handleSendFund = (e) => {
    e.preventDefault()

    if (sendAmount < 200) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Too little"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Amount must be at least 200.
        </SweetAlert>,
      )
    } else if (sendAmount > 5000000) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Too Much"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Amount cannot exceed 5,000,000.
        </SweetAlert>,
      )
    } else if (wallet.balance < sendAmount) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Insufficient balance"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Insufficient balance to send funds.
        </SweetAlert>,
      )
    } else if (
      recipient === `0${userInfo.phone}` ||
      recipient === userInfo.phone.toString()
    ) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Invalid Recipient"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          You can't send funds to yourself.
        </SweetAlert>,
      )
    } else {
      dispatch(sendFund(sendAmount, recipient, remark, walletPIN))
    }
  }

  const handleRefresh = (e) => {
    e.preventDefault()

    dispatch(getWalletBalance())
    dispatch(getWalletTransaction())
  }

  const handleCancel = () => {
    setOpenSendDialog(false)
    setSendAmount('')
    setRecipient('')
    setRemark('')
    setReview(false)
    setShow(false)
    setWalletPIN('')
    dispatch({ type: VERIFY_WALLET_USERID_RESET })
    dispatch({ type: SEND_FUND_RESET })
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setRecipient(value)
    if (/^\d{10}$/.test(value)) {
      dispatch(verifyUserID(value)) // Replace 'YOUR_ACTION_TYPE' with the actual action type.
    } else if (value.length > 10) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Invalid Recipient"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          User ID can't be more than 10 digits.
        </SweetAlert>,
      )
    } else {
      setRecipient(value)
    }
  }

  const handleAccountChange = (e) => {
    const value = e.target.value
    setAccount(value)
    if (/^\d{10}$/.test(value) && bankCode) {
      dispatch(verifyAccountNumber(value, bankCode)) // Replace 'YOUR_ACTION_TYPE' with the actual action type.
    } else if (value.length > 10) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Invalid Account"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Account Number can't be more than 10 digits.
        </SweetAlert>,
      )
    } else {
      setAccount(value)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      // Toggle the visibility of dots by adding/removing them
      setDotsVisible((prev) => (prev === '...' ? '' : '...'))
    }, 500) // Change the interval as needed

    return () => {
      clearInterval(interval) // Clear the interval on component unmount
    }
  }, [])

  const handleWithdrawCancel = (e) => {
    e.preventDefault()
    setOpenWithdrawDialog(false)
    setBankCode('')
    setAccount('')
    setWithdrawAmount('')
    setWithdrawRemark('')
    setWithdrawPIN('')
    dispatch({ type: VERIFY_ACCOUNT_RESET })
  }

  const handleWithdrawSubmit = (e) => {
    e.preventDefault()

    if (wallet.balance < withdrawAmount) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Insufficient balance"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Insufficient balance to withdraw funds.
        </SweetAlert>,
      )
    } else if (withdrawAmount < 3000) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Too little"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Amount must be at least 3,000.
        </SweetAlert>,
      )
    } else if (withdrawAmount > 500000) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Too Much"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Amount cannot exceed 500,000.
        </SweetAlert>,
      )
    } else if (accountName === '') {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Too Much"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Amount cannot exceed 500,000.
        </SweetAlert>,
      )
    } else {
      dispatch(
        submitWithdrawRequest(
          bankCode,
          account,
          accountName,
          withdrawAmount,
          withdrawRemark,
          withdrawPIN,
        ),
      )
    }
  }

  useEffect(() => {
    if (accountDetail) {
      setAccountName(accountDetail.account_name)
    }
  }, [accountDetail])


  const handleCancelEscrowTransfer = (e) => {
    e.preventDefault()
    setNewSecureDialog(false)
    setAmount()
    setRemark('')
    setRecipient('')
    setWalletPIN('')
    dispatch({ type: VERIFY_ACCOUNT_RESET })
  }

  const handleEscrowSubmit = (e) => {
    e.preventDefault()

    if (amount < 0) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Amount "
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Amount can't be less than 0
        </SweetAlert>,
      )
    } else if (wallet.balance < amount) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Insufficient balance"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Insufficient balance to initial transfer.
        </SweetAlert>,
      )
    } else {
      dispatch(initialTransfer(recipient, remark, amount, walletPIN))
    }
  }

  const openEditDialog = (item) => {
    setShowDialog(true)
    setDetail(item)
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Wallet</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmtrove</a>
                      </li>
                      <li className="breadcrumb-item active">Wallet</li>
                    </ol>
                  </div>
                </div>
              </div>
              {loadingVerify ? (
                <LoadingBox />
              ) : (
                <>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card" style={{ borderRadius: '1rem' }}>
                          <div className="card-body balance-body">
                            {walletErr ? (
                              <MessageBox variant="danger">
                                {walletErr}
                              </MessageBox>
                            ) : null}

                            {loadingWallet ? (
                              <div
                                className="tailspin"
                                style={{ height: 'calc(20vh - 40px)' }}
                              >
                                <TailSpin
                                  stroke="#98ff98"
                                  strokeWidth="3"
                                  color="#06bcee"
                                />
                              </div>
                            ) : (
                              <div className="">
                                <div className="d-flex">
                                  <div className="flex-1 overflow-hidden">
                                    <h5 className="card-title balance-title">
                                      My Wallet Balance
                                    </h5>

                                    <div>
                                      <h4 className="wallet-bal">
                                        NGN{' '}
                                        {wallet
                                          ? formatNumber((wallet.balance).toFixed(2))
                                          : '0.00'}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="ms-auto">
                                    {wallet && wallet.status ? (
                                      <span className="badge badge-soft-success font-size-13 me-3">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-danger font-size-13 me-3">
                                        Inactive
                                      </span>
                                    )}

                                    <button
                                      className="btn btn-sm btn-success"
                                      onClick={handleRefresh}
                                    >
                                      <i className="fa fa-sync"></i>
                                    </button>
                                  </div>
                                </div>
                                <div className="balance-container">
                                  {loadingTransactions ? (
                                    <div
                                      className="tailspin"
                                      style={{
                                        height: 'calc(2vh - 2px)',
                                      }}
                                    >
                                      <TailSpin
                                        stroke="#98ff98"
                                        strokeWidth="3"
                                        color="#06bcee"
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      <div className="balance-item">
                                        <div className="balance-profit">
                                          <ul
                                            className="list-unstyled balance-list"
                                            style={{ display: 'flex' }}
                                          >
                                            <li
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <i className="fas fa-arrow-down bg-soft-success"></i>
                                            </li>
                                            <li
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                              }}
                                            >
                                              <p className="m-0 p-0">
                                                Total Credit
                                              </p>
                                              {transactions ? (
                                                <h5 className="m-0 p-0">
                                                  {`+ NGN ${formatNumber(
                                                    calculateMoneyFlow(
                                                      transactions,
                                                    ).inflow.toFixed(2),
                                                  )}`}
                                                </h5>
                                              ) : (
                                                <h5 className="">+ NGN 0.00</h5>
                                              )}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>

                                      <div className="balance-item">
                                        <div className="balance-profit">
                                          <ul
                                            className="list-unstyled balance-list"
                                            style={{ display: 'flex' }}
                                          >
                                            <li
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <i className="fas fa-arrow-up bg-soft-danger"></i>
                                            </li>
                                            <li
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                              }}
                                            >
                                              <p className="m-0 p-0">
                                                Total Debit
                                              </p>
                                              {transactions ? (
                                                <h5 className="m-0 p-0">
                                                  {`- NGN ${formatNumber(
                                                    calculateMoneyFlow(
                                                      transactions,
                                                    ).outflow.toFixed(2),
                                                  )}`}
                                                </h5>
                                              ) : (
                                                <h5 className="">- NGN 0.00</h5>
                                              )}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="card" style={{ borderRadius: '1rem' }}>
                          <div className="card-body">
                            <h5 className="card-title">Information</h5>
                            {loadingDetails ? (
                              <div
                                className="tailspin"
                                style={{ height: 'calc(10vh - 10px)' }}
                              >
                                <TailSpin
                                  stroke="#98ff98"
                                  strokeWidth="3"
                                  color="#06bcee"
                                />
                              </div>
                            ) : (
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="text-center ">
                                    <img
                                      src={
                                        user && user.image
                                          ? user.image
                                          : 'assets/images/default-user.png'
                                      }
                                      alt=""
                                      className="farmer-user-image mt-0"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mt-3">
                                    <i className="fal fa-user me-2"> </i>
                                    <small className="font-size-15 me-2">
                                      Name
                                    </small>{' '}
                                    <h6 className="card-desc mt-1 mb-0 text-capitalize">
                                      {user && user.firstName}{' '}
                                      {user && user.lastName}{' '}
                                    </h6>
                                  </div>

                                  <div className="mt-3">
                                    <i className="fal fa-phone-alt me-2"> </i>
                                    <small className="font-size-15 me-2">
                                      Phone No
                                    </small>
                                    <h6 className="card-desc mt-1 mb-0">
                                      {user && `0${user.phone}`}
                                    </h6>
                                  </div>

                                  <div className="mt-3">
                                    <i className="fal fa-wallet me-2"> </i>
                                    <small className="font-size-15 me-2">
                                      Wallet ID
                                    </small>{' '}
                                    <h6 className="card-desc mt-1 mb-0">
                                      {' '}
                                      {user && user.phone}{' '}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="card" style={{ borderRadius: '1rem' }}>
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="flex-1 overflow-hidden">
                                <p className="text-truncate font-size-14 mb-2">
                                  Monthly Spend
                                </p>
                                {loadingTransactions ? (
                                  <div
                                    className="tailspin"
                                    style={{ height: 'calc(10vh - 10px)' }}
                                  >
                                    <TailSpin
                                      stroke="#98ff98"
                                      strokeWidth="3"
                                      color="#06bcee"
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {transactions ? (
                                      <h4 className="pb-0 mb-0">
                                        {`NGN ${formatNumber(
                                          calculateMoneyFlow(transactions)
                                            .outflowMonth.toFixed(2),
                                        )}`}
                                      </h4>
                                    ) : (
                                      <h4 className="pb-0 mb-0">NGN 0.00</h4>
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="text-primary ms-auto">
                                <i className="ri-upload-2-fill font-size-24"></i>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card" style={{ borderRadius: '1rem' }}>
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="flex-1 overflow-hidden">
                                <p className="text-truncate font-size-14 mb-2">
                                  Monthly Income
                                </p>
                                {loadingTransactions ? (
                                  <div
                                    className="tailspin"
                                    style={{ height: 'calc(10vh - 10px)' }}
                                  >
                                    <TailSpin
                                      stroke="#98ff98"
                                      strokeWidth="3"
                                      color="#06bcee"
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {transactions ? (
                                      <h4 className="pb-0 mb-0">
                                        {`NGN ${formatNumber(
                                          calculateMoneyFlow(transactions)
                                            .inflowMonth.toFixed(2),
                                        )}`}
                                      </h4>
                                    ) : (
                                      <h4 className="pb-0 mb-0">NGN 0.00</h4>
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="text-primary ms-auto">
                                <i className="ri-download-2-fill font-size-24"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <img
                          src="assets/images/Frame_1.png"
                          alt="banner"
                          className="farmtrove-banner"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div
                      className="card action-btn-container"
                      style={{
                        borderRadius: '1rem',
                        backgroundColor: '#E8F0F2',
                        display: 'flex',
                      }}
                    >
                      <div className="card-body action-btn"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                        <button
                          className="btn m-0 me-5 bg-soft-success"
                          type="button"
                          onClick={() => setOpen(true)}
                        >
                          <i className="ri-download-2-fill font-size-18"></i>
                          <span>Add Funds</span>
                        </button>

                        <button
                          className="btn m-0 me-5 bg-soft-danger"
                          type="button"
                          onClick={() => setOpenSendDialog(true)}
                        >
                          <i className="fal fa-paper-plane font-size-18"></i>
                          <span>Send</span>
                        </button>

                        <button
                          className="btn m-0 me-5 bg-soft-info"
                          type="button"
                          onClick={() => setOpenWithdrawDialog(true)}
                        >
                          <i className="fal fa-money-check-alt font-size-18"></i>
                          <span>Withdraw</span>
                        </button>
                      </div>

                      <div className="card-body action-btn justify-content-center align-items-center"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                        <button
                          className="btn m-0 me-5 bg-soft-primary"
                          type="button"
                          onClick={() => setNewSecureDialog(true)}
                        >
                          <i className="ri-download-2-fill font-size-18"></i>
                          <span>Initiate Secure Transfer</span>
                        </button>

                        <button
                          className="btn m-0 me-5 bg-soft-warning"
                          type="button"
                          onClick={() => setSecureTransferDialog(true)}
                        >
                          <i className="fal fa-paper-plane font-size-18"></i>
                          <span>Escrow Transfers</span>
                        </button>

                        <button
                          className="btn m-0 me-5 bg-soft-secondary"
                          type="button"
                          onClick={() => setClaimFundsDialog(true)}
                        >
                          <i className="fal fa-money-check-alt font-size-18"></i>
                          <span>Claim Funds</span>
                        </button>
                      </div>
                    </div>
                    <div className="card" style={{ borderRadius: '1rem' }}>
                      <div className="card-body">
                        <h5 className="card-title">Recent Transaction</h5>

                        {transactionsError ? (
                          <MessageBox variant="danger">
                            {transactionsError}
                          </MessageBox>
                        ) : null}

                        <div
                          className=""
                          style={{ padding: '0px', marginTop: '30px' }}
                        >
                          <ul className="list-unstyled">
                            {loadingTransactions ? (
                              <div
                                className="tailspin"
                                style={{ height: 'calc(20vh - 40px)' }}
                              >
                                <TailSpin
                                  stroke="#98ff98"
                                  strokeWidth="3"
                                  color="#06bcee"
                                />
                              </div>
                            ) : (
                              <>
                                {transactions && transactions.length > 0 ? (
                                  transactions.slice(0, 7).map((item) => (
                                    <li
                                      className="transaction-list"
                                      key={item._id}
                                    >
                                      <div className="activity-icon avatar-xs transaction-items">
                                        <span
                                          className={`avatar-title ${item.type === 'credit'
                                            ? 'bg-soft-success'
                                            : 'bg-soft-danger'
                                            } text-primary`}
                                          style={{ borderRadius: '10px' }}
                                        >
                                          <i
                                            className={`${item.type === 'credit'
                                              ? 'ri-download-2-fill'
                                              : 'ri-upload-2-fill'
                                              } p-4`}
                                          ></i>
                                        </span>
                                      </div>
                                      <div className="transaction-items">
                                        <div>
                                          <h5 className="font-size-13">
                                            {item.description}
                                          </h5>
                                        </div>

                                        <div>
                                          <p className="text-muted mt-0 mb-0">
                                            {moment(item.timestamp).format(
                                              'DD MMM YYYY',
                                            )}{' '}
                                            <small className="text-muted">
                                              {moment(item.timestamp).format(
                                                'h:mm a',
                                              )}
                                            </small>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="transaction-items text-center">
                                        <p className="m-0 mb-0">
                                          <b
                                            className={`${item.type === 'credit'
                                              ? 'text-success'
                                              : 'text-danger'
                                              } font-size-17`}
                                          >
                                            {item.type === 'credit'
                                              ? `+ ₦${formatNumber(
                                                item.amount,
                                              )}`
                                              : `- ₦${formatNumber(
                                                item.amount,
                                              )}`}
                                          </b>
                                        </p>
                                        <small
                                          className={`text-${item.status === 'successful'
                                            ? 'success'
                                            : item.status === 'processing'
                                              ? 'warning'
                                              : item.status === 'pending'
                                                ? 'info'
                                                : 'danger'
                                            } font-size-12`}
                                        >
                                          {item.status}
                                        </small>
                                      </div>
                                    </li>
                                  ))
                                ) : (
                                  <div className="card-empty-body">
                                    <div className="card-empty">
                                      <h5 className="card-empty-title">
                                        Nothing to See Here
                                      </h5>
                                      <p className="card-empty-text">
                                        Sorry, you are yet to perform a
                                        transaction at the moment.
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* =============================================
      =================================================
      ============Add Fund Dialog Start================
      =================================================
      ================================================*/}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'sm'}
      >
        <div className="modal-header">
          <h5 className="modal-title">Fund wallet</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setOpen(false)}
          ></button>
        </div>
        <DialogContent>
          {error ? <MessageBox variant="danger">{error}</MessageBox> : null}

          {loading ? (
            <div className="tailspin" style={{ height: 'calc(50vh - 100px)' }}>
              <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
            </div>
          ) : (
            <div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3 mt-3">
                      <label className="form-label" forhtml="amount">
                        Top-up amount
                      </label>
                      <input
                        id="amount"
                        name="amount"
                        type="number"
                        className="form-control"
                        required
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="NGN 200.00 - 5,000,000.00"
                        value={amount}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    {/* Declaration */}
                    <div className="mt-3 mb-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault6"
                          checked={isDeclarationChecked}
                          onChange={() =>
                            setIsDeclarationChecked(!isDeclarationChecked)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault6"
                        >
                          I agree to fund my Farmtrove wallet with this
                          non-refundable, no transferable deposit.
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="d-grid">
                      <button
                        onClick={handleSubmit}
                        type="submit"
                        disabled={!isDeclarationChecked}
                        className="btn btn-success btn-md waves-effect"
                      >
                        Add Fund
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="d-grid mt-3 mb-3">
                      <button
                        type="button"
                        className="btn btn-danger btn-md waves-effect"
                        data-bs-dismiss="modal"
                        onClick={() => setOpen(false)}
                      >
                        Fund Later
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </DialogContent>
      </Dialog>

      {/* =============================================
      =================================================
      ============Send Fund Dialog Start================
      =================================================
      ================================================*/}
      <Dialog
        open={openSendDialog}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'md'}
      >
        <div className="modal-header">
          <h5 className="modal-title">Send fund</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleCancel}
          ></button>
        </div>
        <DialogContent>
          {loadingSend ? (
            <div className="tailspin" style={{ height: 'calc(50vh - 100px)' }}>
              <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
            </div>
          ) : (
            <div>
              {sendError ? (
                <MessageBox variant="danger">{sendError}</MessageBox>
              ) : null}
              {sent ? (
                <MessageBox variant="success">{sent.message}</MessageBox>
              ) : null}
              {show ? (
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-1 mt-1">
                        <label className="form-label" forhtml="amount">
                          Amount
                        </label>
                        <input
                          id="amount"
                          name="amount"
                          type="number"
                          className="form-control"
                          required
                          onChange={(e) => setSendAmount(e.target.value)}
                          value={sendAmount}
                          placeholder="NGN 200.00 - 5,000,000.00"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-1 mt-4">
                        <label className="form-label" forhtml="account">
                          Recipient Account
                        </label>
                        <input
                          id="account"
                          name="account"
                          type="number"
                          className="form-control"
                          required
                          onChange={handleInputChange}
                          value={recipient}
                          placeholder="Phone No./Farmsured User ID"
                        />
                      </div>
                    </div>

                    {loadingVerifyID ? (
                      <div className="col-md-12">
                        <div className="card bg-success text-white mb-0">
                          <div className="card-body p-2">
                            <span className="p-1 m-0">{dotsVisible}</span>
                          </div>
                        </div>
                      </div>
                    ) : verifyIDError ? (
                      <div className="col-md-12">
                        <div className="card bg-danger text-white mb-0">
                          <div className="card-body p-2">
                            <span className="p-1 m-0">{verifyIDError}</span>
                          </div>
                        </div>
                      </div>
                    ) : verified ? (
                      <div className="col-md-12">
                        <div className="card bg-success text-white mb-0">
                          <div className="card-body p-2">
                            <span className="p-1 m-0">
                              {verified && verified.phone && verified.phone}{' '}
                              {verified &&
                                verified.firstName &&
                                verified.lastName
                                ? `(${verified.firstName} ${verified.lastName})`
                                : ''}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-md-12">
                      <div className="mb-2 mt-4">
                        <label className="form-label" forhtml="remark">
                          Remark
                        </label>
                        <input
                          id="remark"
                          name="remark"
                          type="text"
                          className="form-control"
                          onChange={(e) => setRemark(e.target.value)}
                          value={remark}
                          placeholder="What's this for? (Optional)"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="d-grid">
                        <button
                          className="btn btn-md btn-success mt-2"
                          type="button"
                          onClick={() => (setShow(false), setReview(true))}
                          disabled={
                            sendAmount === '' || recipient === '' ? true : false
                          }
                        >
                          <i className="fal fa-arrow-right me-3"></i> Proceed
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : review ? (
                <form onSubmit={handleSendFund} className="">
                  <div
                    className="btn btn-sm btn-success mb-3"
                    onClick={() => (setReview(false), setShow(true))}
                  >
                    Back
                  </div>
                  <div className="row">
                    <div className="col-md-7">
                      <div className="card">
                        <div className="card-body">
                          <p className="mb-0 card-title">
                            <i className="fal fa-money-bill-alt me-2"> </i>
                            TOTAL DEBIT
                          </p>
                          <p className="send-amount">
                            <b>
                              ₦
                              {sendAmount
                                ? `${formatNumber(sendAmount)}.00`
                                : '0.00'}
                            </b>
                          </p>
                          <hr className="my-1 mb-1" />

                          <div
                            className=""
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyItems: 'center',
                              margin: '0 auto',
                              marginTop: '30px',
                            }}
                          >
                            <div className="">
                              <p className="m-0">Transaction Date</p>
                              <p className="mt-2">
                                {moment().format('DD MMM YYYY')}
                              </p>
                            </div>
                            <hr className="vertical-line my-1 mb-1" />
                            <div className="">
                              <p className="m-0">Transaction Type</p>
                              <p className="mt-2">
                                Transfer from Farmtrove Account{' '}
                              </p>
                            </div>
                          </div>

                          <div className="mt-4">
                            <p className="mb-0">
                              <b>Transaction Details</b>
                            </p>
                            <hr className="my-1 mb-1" />

                            <div className="transaction-detail-container">
                              <div className="transaction-item card-desc">
                                Description
                              </div>
                              <div className="transaction-item">
                                {remark ? remark : ''}
                              </div>
                            </div>

                            <div className="transaction-detail-container">
                              <div className="transaction-item card-desc">
                                Amount
                              </div>
                              <div className="transaction-item">
                                ₦
                                {sendAmount ? formatNumber(sendAmount) : '0.00'}
                              </div>
                            </div>

                            <div className="transaction-detail-container">
                              <div className="transaction-item card-desc">
                                Commission
                              </div>
                              <div className="transaction-item">₦0.00</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="card text-white transfer-info-card">
                        <div className="card-body">
                          <div className="mb-2">
                            <h5 className="card-title text-white">
                              <i className="fal fa-download font-size-14 me-3"></i>
                              <b>From</b>
                            </h5>
                            <hr className="my-1 mb-2" />

                            <div className="send-detail-container">
                              <div className="send-item card-desc">Name</div>
                              <div className="send-item">
                                {user && user.firstName} {user && user.lastName}
                              </div>
                            </div>

                            <div className="send-detail-container">
                              <div className="send-item card-desc">Phone</div>
                              <div className="send-item">
                                {user && `0${user.phone}`}
                              </div>
                            </div>

                            <div className="send-detail-container">
                              <div className="send-item card-desc">
                                Wallet ID
                              </div>
                              <div className="send-item">
                                {user && user.phone}
                              </div>
                            </div>

                            <div className="send-detail-container">
                              <div className="send-item card-desc">Email</div>
                              <div className="send-item">
                                {user && user.email}
                              </div>
                            </div>
                          </div>

                          <div className="mt-4">
                            <h5 className="card-title text-white">
                              <i className="fal fa-upload font-size-14 me-3"></i>
                              <b>To</b>
                            </h5>
                            <hr className="my-1 mb-2" />

                            <div className="send-detail-container">
                              <div className="send-item card-desc">Name</div>
                              <div className="send-item">
                                {verified &&
                                  verified.firstName &&
                                  verified.lastName
                                  ? `${verified.firstName} ${verified.lastName}`
                                  : ''}
                              </div>
                            </div>

                            <div className="send-detail-container">
                              <div className="send-item card-desc">Phone</div>
                              <div className="send-item">
                                {verified &&
                                  verified.phone &&
                                  `0${verified.phone}`}
                              </div>
                            </div>

                            <div className="send-detail-container">
                              <div className="send-item card-desc">
                                Wallet ID
                              </div>
                              <div className="send-item">
                                {' '}
                                {verified && verified.phone && verified.phone}
                              </div>
                            </div>

                            <div className="send-detail-container">
                              <div className="send-item card-desc">Email</div>
                              <div className="send-item">
                                {verified && verified.email && verified.email}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-2 mt-2">
                        <label className="form-label" forhtml="walletpin">
                          Wallet PIN
                        </label>
                        <input
                          id="walletpin"
                          name="walletpin"
                          type="password"
                          className="form-control"
                          onChange={(e) => setWalletPIN(e.target.value)}
                          value={walletPIN}
                          placeholder="Transaction PIN"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="d-grid">
                        <button
                          className="btn btn-md btn-success mt-2"
                          type="submit"
                          disabled={!walletPIN ? true : false}
                        >
                          <i className="fal fa-paper-plane me-3"></i> Send
                        </button>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-grid">
                        <button
                          className="btn btn-md btn-danger mt-2"
                          type="button"
                          onClick={handleCancel}
                        >
                          <i className="fal fa-times me-3"></i> Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <div className="send__dialog__card p-5">
                  <div className="text-center send__dialog__body">
                    <div className="send-dialog-container">
                      <div className="send-dialog-item">
                        <img
                          src="https://cdn.pixabay.com/photo/2021/07/01/09/18/money-6378855_1280.png"
                          alt=""
                          style={{ maxWidth: '100%' }}
                        />
                      </div>

                      <div className="send-dialog-item d-flex flex-column justify-content-center align-items-center">
                        <div className="">
                          <button
                            className="btn btn-success p-4 mb-3"
                            onClick={() => setShow(true)}
                          >
                            <i className="fal fa-paper-plane me-3"></i>
                            <span>Send to Farmtrove User</span>
                          </button>
                        </div>
                        <div className="">
                          <button className="btn btn-primary p-4 mb-3">
                            <i className="fal fa-paper-plane me-3"></i>
                            <span>Send to other bank account</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>

      {/* =============================================
        =================================================
        =========Withdraw Dialog End Here================
        =================================================
        ================================================*/}
      <Dialog
        open={openWithdrawDialog}
        onClose={() => setOpenWithdrawDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'md'}
      >
        <div className="modal-header">
          <h5 className="modal-title">Send Withdraw Request</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setOpenWithdrawDialog(false)}
          ></button>
        </div>
        <DialogContent>
          {loadingWithdraw ? (
            <div className="tailspin" style={{ height: 'calc(20vh - 40px)' }}>
              <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
            </div>
          ) : (
            <div className="">
              {withdrawError ? (
                <MessageBox variant="danger">{withdrawError}</MessageBox>
              ) : null}
              {withdrawn ? (
                <MessageBox variant="success">{withdrawn.message}</MessageBox>
              ) : null}
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="card bg-soft-success"
                    style={{ borderRadius: '1rem' }}
                  >
                    <div className="card-body">
                      {loadingWallet ? (
                        <div
                          className="tailspin"
                          style={{ height: 'calc(20vh - 40px)' }}
                        >
                          <TailSpin
                            stroke="#98ff98"
                            strokeWidth="3"
                            color="#06bcee"
                          />
                        </div>
                      ) : (
                        <div className="d-flex">
                          <div className="flex-1 overflow-hidden">
                            <h5 className="card-title balance-title">
                              Current Balance
                            </h5>

                            <div>
                              <h4 className="wallet-bal">
                                ₦
                                {wallet ? formatNumber((wallet.balance).toFixed(2)) : '0.00'}
                              </h4>
                            </div>
                          </div>

                          <div className="ms-auto">
                            <i className="fal fa-money-bill-alt font-size-24"></i>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <form onSubmit={handleWithdrawSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-2 mt-2">
                      <label className="form-label" forhtml="withdrawAmount">
                        Amount to withdraw (minimum ₦3,000)
                      </label>
                      <input
                        id="withdrawAmount"
                        name="withdrawAmountwithdrawAmountwithdrawAmountwithdrawAmount"
                        type="number"
                        className="form-control"
                        required
                        onChange={(e) => setWithdrawAmount(e.target.value)}
                        placeholder="NGN 3,000.00 - 500,000.00"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="mb-2 mt-2">
                      <label className="form-label" forhtml="ProductName">
                        Bank Name
                      </label>
                      <Select
                        onChange={(opt) => setBankCode(opt.value)}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        required
                        options={Banks.map((opt) => ({
                          label: opt.bank_name,
                          value: opt.cbn_code,
                        }))}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="mb-2 mt-2">
                      <label className="form-label" forhtml="ProductName">
                        Account Number
                      </label>
                      <input
                        id="amount"
                        name="amount"
                        type="number"
                        className="form-control"
                        required
                        onChange={handleAccountChange}
                        placeholder="Account Number"
                      />
                    </div>
                  </div>

                  {loadingVerifyAccount ? (
                    <div className="col-md-12">
                      <div className="card bg-success text-white mb-0">
                        <div className="card-body p-2">
                          <span className="p-1 m-0">{dotsVisible}</span>
                        </div>
                      </div>
                    </div>
                  ) : verifyAccountError ? (
                    <div className="col-md-12">
                      <div className="card bg-danger text-white mb-0">
                        <div className="card-body p-2">
                          <span className="p-1 m-0">{verifyAccountError}</span>
                        </div>
                      </div>
                    </div>
                  ) : accountDetail ? (
                    <div className="col-md-12">
                      <div className="card bg-success text-white mb-0">
                        <div className="card-body p-2">
                          <span className="p-1 m-0">
                            {accountDetail && accountDetail.account_name
                              ? accountDetail.account_name
                              : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="col-md-12">
                    <div className="mb-2 mt-2">
                      <label className="form-label" forhtml="remark">
                        Remark
                      </label>
                      <input
                        id="remark"
                        name="remark"
                        type="text"
                        className="form-control"
                        onChange={(e) => setRemark(e.target.value)}
                        value={remark}
                        placeholder="What's this for? (Optional)"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="mb-2 mt-2">
                      <label className="form-label" forhtml="walletpin">
                        Wallet PIN
                      </label>
                      <input
                        id="walletpin"
                        name="walletpin"
                        type="password"
                        className="form-control"
                        onChange={(e) => setWithdrawPIN(e.target.value)}
                        value={withdrawPIN}
                        placeholder="Transaction PIN"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="d-grid">
                      <button
                        className="btn btn-success btn-md mt-3 mb-3"
                        type="submit"
                        disabled={
                          !withdrawPIN ||
                            !withdrawAmount ||
                            !bankCode ||
                            !account
                            ? true
                            : false
                        }
                      >
                        Withdraw
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-grid">
                      <button
                        className="btn btn-danger btn-md mt-3 mb-3"
                        onClick={handleWithdrawCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </DialogContent>
      </Dialog>

      {/* =============================================
      =================================================
      ============First Time Dialog Start==============
      =================================================
      ================================================*/}
      <Dialog
        open={introDialog}
        onClose={() => setIntroDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'lg'}
      >
        <DialogContent>
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-4">
              <img src="/assets/images/product/Chicken.png" alt="" />
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5
                    className="card-title text-dark bg-soft-secondary"
                    style={{
                      padding: '10px',
                      borderRadius: '5px',
                      marginBottom: '20px',
                    }}
                  >
                    Finance made easy
                  </h5>

                  <div className="d-flex">
                    <div className="avatar-sm me-3">
                      <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                        <i className="ri-checkbox-circle-line"></i>
                      </span>
                    </div>
                    <div className="flex-1 align-self-center overflow-hidden">
                      <h5 className="mb-1">Free Shipping</h5>
                      <p className="text-muted mb-0">
                        Sed ut perspiciatis unde
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </DialogContent>
      </Dialog>


      {/* =============================================
      =================================================
      ====Initiate Secure Transfer Dialog Start========
      =================================================
      ================================================*/}

      <Dialog
        open={newSecureDialog}
        onClose={() => setNewSecureDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'md'}
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setNewSecureDialog(false)}
          ></button>
        </div>

        <div className="bg-success" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
          <h5 className="modal-title white" style={{ color: 'white', paddingLeft: '20px' }}>Escrow Secure Transfer</h5>
          <p className="modal-title white" style={{ color: 'white', paddingLeft: '20px' }}>Initiate our secure escrow system to make your payments</p>
        </div>
        <DialogContent>
          {transferError ? <MessageBox variant="danger">{transferError}</MessageBox> : null}
          {initiated ? (
            <MessageBox variant="success">Escrow Secure Transfer Initiated</MessageBox>
          ) : null}
          {loadingTransfer ? (
            <div className="tailspin" style={{ height: 'calc(20vh - 40px)' }}>
              <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
            </div>
          ) : (
            <form onSubmit={handleEscrowSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-2 mt-2">
                    <label className="form-label" forhtml="ProductName">
                      Recipient
                    </label>
                    <input
                      id="amount"
                      name="amount"
                      type="number"
                      className="form-control"
                      required
                      onChange={handleInputChange}
                      value={recipient}
                      placeholder="Input receiptent details"
                    />
                  </div>
                </div>

                {loadingVerifyID ? (
                  <div className="col-md-12">
                    <div className="card bg-success text-white mb-0">
                      <div className="card-body p-2">
                        <span className="p-1 m-0">{dotsVisible}</span>
                      </div>
                    </div>
                  </div>
                ) : verifyIDError ? (
                  <div className="col-md-12">
                    <div className="card bg-danger text-white mb-0">
                      <div className="card-body p-2">
                        <span className="p-1 m-0">{verifyIDError}</span>
                      </div>
                    </div>
                  </div>
                ) : verified ? (
                  <div className="col-md-12">
                    <div className="card bg-success text-white mb-0">
                      <div className="card-body p-2">
                        <span className="p-1 m-0">
                          {verified && verified.phone && verified.phone}{' '}
                          {verified &&
                            verified.firstName &&
                            verified.lastName
                            ? `(${verified.firstName} ${verified.lastName})`
                            : ''}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="col-md-12">
                  <div className="mb-2 mt-2">
                    <label className="form-label" forhtml="amount">
                      Amount
                    </label>
                    <input
                      id="amount"
                      name="amount"
                      type="number"
                      className="form-control"
                      required
                      onChange={(e) => setAmount(e.target.value)}
                      value={amount}
                      placeholder="Amount"
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-2 mt-2">
                    <label className="form-label" forhtml="remark">
                      Remark
                    </label>
                    <input
                      id="remark"
                      name="remark"
                      type="text"
                      className="form-control"
                      onChange={(e) => setRemark(e.target.value)}
                      value={remark}
                      placeholder="What's this for? (Optional)"
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-2 mt-2">
                    <label className="form-label" forhtml="walletpin">
                      Wallet PIN
                    </label>
                    <input
                      id="walletpin"
                      name="walletpin"
                      type="password"
                      className="form-control"
                      onChange={(e) => setWalletPIN(e.target.value)}
                      value={walletPIN}
                      placeholder="Transaction PIN"
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="d-grid">
                    <button
                      className="btn btn-success btn-md mt-3 mb-3"
                      type="submit"
                      disabled={
                        !walletPIN ||
                          !amount ||
                          !recipient
                          ? true
                          : false
                      }
                    >
                      Initiate Transfer
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-grid">
                    <button
                      className="btn btn-danger btn-md mt-3 mb-3"
                      onClick={handleCancelEscrowTransfer}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </DialogContent>
      </Dialog>



      {/* =============================================
      =================================================
      ========= Secure Transfer Dialog Start==========
      =================================================
      ================================================*/}

      <Dialog
        open={secureTransferDialog}
        onClose={() => (setShowDialog(false), setSecureTransferDialog(false))}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'lg'}
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => (setShowDialog(false), setSecureTransferDialog(false))}
          ></button>
        </div>

        <div className="bg-success" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
          <h5 className="modal-title white" style={{ color: 'white', paddingLeft: '20px' }}>Escrow Transfers</h5>
          <p className="modal-title white" style={{ color: 'white', paddingLeft: '20px' }}>Below are your list of active transfers pending to be completed</p>
        </div>
        <DialogContent>
          {
            showDialog
              ?
              <div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="card bg-soft-success">
                      <div className="card-body">
                        <h6>Amount</h6>
                        <h5 className='card-title font-size-17'>
                          <b>NGN {detail && detail.amount ? formatNumber(detail.amount) : 0}.00</b>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <h6>Transfer Initiated</h6>
                        <h5 className='card-title font-size-17'>
                          {detail && detail.createdAt && moment(detail.createdAt).format('Do MMMM YYYY')} | {detail && detail.createdAt && moment(detail.createdAt).format('h:mm a')}
                        </h5>

                        <h6 className='mt-4'>Funds placed in escrow account</h6>
                        <h5 className='card-title font-size-17'>
                          {detail && detail.createdAt && moment(detail.createdAt).format('Do MMMM YYYY')} | {detail && detail.createdAt && moment(detail.createdAt).format('h:mm a')}
                        </h5>
                      </div>
                    </div>
                  </div>
                  {console.log(detail)}
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <h6>Wallet ID</h6>
                        <h5 className='card-title font-size-17'>
                          {detail && detail.receiver && detail.receiver.phone}
                        </h5>

                        <h6 className='mt-4'>Credited for?</h6>
                        <h5 className='card-title font-size-17'>
                          {detail && detail.remark}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              loadingSecureTransactions
                ?
                <div
                  className="tailspin"
                  style={{ height: 'calc(20vh - 40px)' }}
                >
                  <TailSpin
                    stroke="#98ff98"
                    strokeWidth="3"
                    color="#06bcee"
                  />
                </div>
                :
                <MDBDataTableV5
                  hover
                  entriesOptions={[5, 10, 20, 25, 50]}
                  entries={10}
                  pagesAmount={4}
                  data={datatable}
                  searchTop
                  searchBottom={false}
                  barReverse
                />
          }

        </DialogContent>
      </Dialog>


      {/* =============================================
      =================================================
      =========== Claim Funds Dialog Start ============
      =================================================
      ================================================*/}

      <Dialog
        open={claimFundsDialog}
        onClose={() => (setShowDialog(false), setClaimFundsDialog(false))}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'lg'}
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => (setShowDialog(false), setClaimFundsDialog(false))}
          ></button>
        </div>

        <div className="bg-success" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
          <h5 className="modal-title white" style={{ color: 'white', paddingLeft: '20px' }}>Claim Funds</h5>
          <p className="modal-title white" style={{ color: 'white', paddingLeft: '20px' }}>Initiate our secure escrow system to make your payments</p>
        </div>
        <DialogContent>
          {
            showDialog
              ?
              <div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body"></div>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
              :
              loadingClaimFunds
                ?
                <div
                  className="tailspin"
                  style={{ height: 'calc(20vh - 40px)' }}
                >
                  <TailSpin
                    stroke="#98ff98"
                    strokeWidth="3"
                    color="#06bcee"
                  />
                </div>
                :
                <MDBDataTableV5
                  hover
                  entriesOptions={[5, 10, 20, 25, 50]}
                  entries={10}
                  pagesAmount={4}
                  data={datatable2}
                  searchTop
                  searchBottom={false}
                  barReverse
                />
          }

        </DialogContent>
      </Dialog>

      {alert}
      <Footer />
    </div >
  )
}
