import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Bar, Pie, Line } from 'react-chartjs-2'
import { useSelector, useDispatch } from 'react-redux'
import { LoadingBox, MessageBox } from '../../index.js'
import 'chart.js/auto'
import { analysis } from '../../../actions/adminActions/dashboardActions/analysis.js'
import {
  monthlygreen,
  weeklyRed,
  todaygreenanalysis,
  todayredanalysis,
  weeklyGreen,
  monthlyred,
  claim,
  highestProducts,
  recentOrder,
  thisMonth,
  thisYear,
  previousYear,
  comMonth,
  formatNumber,
  comYear,
  activityFeed,
  geolocations,
} from '../../functions.js'
import { deleteTagOrder } from '../../../actions/adminActions/tagActions/deleteOrder'
import moment from 'moment'
import { tagAnalysis } from '../../../actions/adminActions/dashboardActions/tagAnalysis.js'
// import GoogleMapReact from 'google-map-react'
import { exportComponentAsPNG } from 'react-component-export-image'
import SweetAlert from 'react-bootstrap-sweetalert'
// import {
//   GoogleMap,
//   withGoogleMap,
//   withScriptjs,
//   OverlayView,
//   InfoWindow,
// } from 'react-google-maps'

import {
  APIProvider, Map, AdvancedMarker, Pin, InfoWindow
} from '@vis.gl/react-google-maps';

const Admin = () => {
  const [alert, setAlert] = useState(null)
  const [monthly, setMonth] = useState(false)
  const [today, setToday] = useState(false)
  const [weekly, setWeek] = useState(false)
  const [todayRed, setTodayRed] = useState([])
  const [todayGreen, setTodayGreen] = useState([])
  const [redWeekly, setWeeklyRed] = useState([])
  const [greenWeekly, setWeeklyGreen] = useState([])
  const [redMonthly, setMonthRed] = useState([])
  const [greenMonthly, setMonthGreen] = useState([])
  const [locations, setLocations] = useState([])

  const [openRedDialog, setOpenRedDialog] = useState(false)
  const [openGreenDialog, setOpenGreenDialog] = useState(false)

  const [selectedRedMark, setSelectedRedMark] = useState(null)
  const [selectedGreenMark, setSelectedGreenMark] = useState(null)

  const componentRef = useRef()

  const fileName = `Geolocation Tag Analyis - ${Date.now()}`

  const dashboardAnalysis = useSelector((state) => state.dashboardAnalysis)
  const { loading, error, result } = dashboardAnalysis

  const dashboardTagAnalysis = useSelector(
    (state) => state.dashboardTagAnalysis,
  )
  const {
    loading: loadingAnalysis,
    error: analysisError,
    tags,
  } = dashboardTagAnalysis

  const deleteOrder = useSelector((state) => state.deleteOrder)
  const { loading: deleteLoading, error: deleteError, success } = deleteOrder

  const dispatch = useDispatch()

  const deleteHandler = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => dispatch(deleteTagOrder(item._id))}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You can not undo this!!!
      </SweetAlert>,
    )
  }

  useEffect(() => {
    dispatch(analysis())
    dispatch(tagAnalysis())
  }, [dispatch, success])

  useMemo(() => {
    if (tags && tags.tag) {
      setTodayRed(
        Object.entries(todayredanalysis(tags.tag)).map((item) => {
          return item[1].length
        }),
      )

      setTodayGreen(
        Object.entries(todaygreenanalysis(tags.tag)).map(
          (item) => item[1].length,
        ),
      )

      setWeeklyRed(
        Object.entries(weeklyRed(tags.tag)).map((item) => item[1].length),
      )

      setWeeklyGreen(
        Object.entries(weeklyGreen(tags.tag)).map((item) => item[1].length),
      )

      setMonthRed(
        Object.entries(monthlyred(tags.tag)).map((item) => item[1].length),
      )

      setMonthGreen(
        Object.entries(monthlygreen(tags.tag)).map((item) => item[1].length),
      )

      setLocations(geolocations(tags.tag))
    }
  }, [tags])

  // const RedMarker = ({ item }) => (
  //   <div className={item.red ? 'red_blink' : null}></div>
  // )

  // const GreenMarker = ({ item }) => (
  //   <div className={item.green ? 'green_blink' : null}></div>
  // )

  const defaultProps = {
    center: {
      lat: 9.0845755,
      lng: 8.674252499999994,
    },
    zoom: 7,
  }

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  })

  // function Map() {
  //   const [selectedRedMark, setSelectedRedMark] = useState(null)
  //   const [selectedGreenMark, setSelectedGreenMark] = useState(null)
  //   return (
  //     <GoogleMap
  //       defaultCenter={{ lat: 9.0845755, lng: 8.674252499999994 }}
  //       defaultZoom={7}
  //     >
  //       {locations &&
  //         locations.length > 0 &&
  //         locations.map((item) => (
  //           <OverlayView
  //             key={item._id}
  //             position={{
  //               lat: item.redlat,
  //               lng: item.redlong,
  //             }}
  //             mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  //             getPixelPositionOffset={getPixelPositionOffset}
  //             onClick={() => {
  //               setSelectedRedMark(item)
  //             }}
  //           >
  //             <button
  //               className={item.red ? 'red_blink' : null}
  //               style={{
  //                 border: 'none',
  //               }}
  //               onClick={() => {
  //                 setSelectedRedMark(item)
  //               }}
  //             ></button>
  //           </OverlayView>
  //         ))}

  //       {locations &&
  //         locations.length > 0 &&
  //         locations.map((item) => (
  //           <OverlayView
  //             key={item._id}
  //             position={{
  //               lat: item.greenlat,
  //               lng: item.greenlong,
  //             }}
  //             mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  //             getPixelPositionOffset={getPixelPositionOffset}
  //             onClick={() => {
  //               setSelectedGreenMark(item)
  //             }}
  //           >
  //             <button
  //               className={item.green ? 'green_blink' : null}
  //               style={{
  //                 border: 'none',
  //               }}
  //               onClick={() => {
  //                 setSelectedGreenMark(item)
  //               }}
  //             ></button>
  //           </OverlayView>
  //         ))}

  //       {selectedRedMark && (
  //         <InfoWindow
  //           position={{
  //             lat: selectedRedMark.redlat,
  //             lng: selectedRedMark.redlong,
  //           }}
  //           onCloseClick={() => {
  //             setSelectedRedMark(null)
  //           }}
  //         >
  //           <div>
  //             <img
  //               src="assets/images/red_tag.png"
  //               alt="red tag"
  //               style={{ width: '70px' }}
  //             />
  //             <h5 className="card-title mt-1">
  //               <b>{selectedRedMark.product.name}</b>
  //             </h5>
  //             <a
  //               href={`/farmer/detail/${selectedRedMark.farmer._id}`}
  //               style={{
  //                 marginBottom: '2px',
  //                 marginTop: '2px',
  //               }}
  //             >
  //               {selectedRedMark.farmer.phone}
  //             </a>
  //             <br />
  //             <div
  //               className=""
  //               style={{
  //                 marginBottom: '3px',
  //                 marginTop: '3px',
  //               }}
  //             >
  //               <spam
  //                 className="card-desc"
  //                 style={{
  //                   textTransform: 'capitalize',
  //                 }}
  //               >
  //                 {selectedRedMark.farmer && selectedRedMark.farmer.firstName}
  //               </spam>{' '}
  //               <spam
  //                 className="card-desc"
  //                 style={{
  //                   textTransform: 'capitalize',
  //                 }}
  //               >
  //                 {selectedRedMark.farmer && selectedRedMark.farmer.lastName}
  //               </spam>
  //             </div>

  //             <p
  //               className="card-desc"
  //               style={{ marginBottom: '2px', marginTop: '3px' }}
  //             >
  //               {moment(selectedRedMark.redActivatedAt).format(
  //                 'Do MMMM YYYY,  h:mm a',
  //               )}
  //             </p>
  //           </div>
  //         </InfoWindow>
  //       )}

  //       {selectedGreenMark && (
  //         <InfoWindow
  //           position={{
  //             lat: selectedGreenMark.greenlat,
  //             lng: selectedGreenMark.greenlong,
  //           }}
  //           onCloseClick={() => {
  //             setSelectedGreenMark(null)
  //           }}
  //         >
  //           <div>
  //             <img
  //               src="assets/images/green_tag.png"
  //               alt="red tag"
  //               style={{ width: '70px' }}
  //             />
  //             <h5 className="card-title mt-1">
  //               <b>{selectedGreenMark.product.name}</b>
  //             </h5>
  //             <a
  //               href={`/farmer/detail/${selectedGreenMark.farmer._id}`}
  //               style={{
  //                 marginBottom: '2px',
  //                 marginTop: '2px',
  //               }}
  //             >
  //               {selectedGreenMark.farmer.phone}
  //             </a>
  //             <br />
  //             <div
  //               className=""
  //               style={{
  //                 marginBottom: '3px',
  //                 marginTop: '3px',
  //               }}
  //             >
  //               <spam
  //                 className="card-desc"
  //                 style={{
  //                   textTransform: 'capitalize',
  //                 }}
  //               >
  //                 {selectedGreenMark.farmer &&
  //                   selectedGreenMark.farmer.firstName}
  //               </spam>{' '}
  //               <spam
  //                 className="card-desc"
  //                 style={{
  //                   textTransform: 'capitalize',
  //                 }}
  //               >
  //                 {selectedGreenMark.farmer &&
  //                   selectedGreenMark.farmer.lastName}
  //               </spam>
  //             </div>

  //             <p
  //               className="card-desc"
  //               style={{ marginBottom: '2px', marginTop: '3px' }}
  //             >
  //               {moment(selectedGreenMark.redActivatedAt).format(
  //                 'Do MMMM YYYY,  h:mm a',
  //               )}
  //             </p>
  //           </div>
  //         </InfoWindow>
  //       )}
  //     </GoogleMap>
  //   )
  // }
  // const WrappedMap = withScriptjs(withGoogleMap(Map))

  const openRed = (item) => {
    setSelectedRedMark(item)
    setOpenRedDialog(true)
  }

  const openGreen = (item) => {
    setSelectedGreenMark(item)
    setOpenGreenDialog(true)
  }


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">Dashboard</h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="javascript: void(0);">Farmsured</a>
                </li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div>
          <div className="row">
            <div className="col-xl-8">
              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Organizations
                          </p>
                          <h4 className="mb-0">
                            {result && result.manufacturer
                              ? result.manufacturer.length
                              : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-store font-size-24"></i>
                        </div>
                      </div>
                    </div>

                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/manufacturers">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Farmers
                          </p>
                          <h4 className="mb-0">
                            {result && result.farmer ? result.farmer.length : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-id-badge font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/farmers">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Products
                          </p>
                          <h4 className="mb-0">
                            {result && result.product
                              ? result.product.length
                              : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-box-check font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/products">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="float-end d-none d-md-inline-block">
                    <div className="btn-group mb-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-light"
                        onClick={() => {
                          setToday(true)
                          setMonth(false)
                          setWeek(false)
                        }}
                      >
                        Today
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-light active"
                        onClick={() => {
                          setWeek(true)
                          setToday(false)
                          setMonth(false)
                        }}
                      >
                        Weekly
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-light"
                        onClick={() => {
                          setMonth(true)
                          setWeek(false)
                          setToday(false)
                        }}
                      >
                        Monthly
                      </button>
                    </div>
                  </div>
                  <h4 className="card-title mb-4">
                    Farmsured Tags Usage Trend
                  </h4>

                  {loadingAnalysis ? (
                    <div id="pluswrap">
                      <div className="plus">
                        <div className="spinner">
                          <i className="ri-loader-line spin-icon"></i>
                        </div>
                      </div>
                    </div>
                  ) : analysisError ? (
                    <MessageBox>{analysisError}</MessageBox>
                  ) : (
                    <div className="mb-2">
                      {monthly ? (
                        <div
                          id="line-column-chart"
                          className="bar-charts"
                          dir="ltr"
                        >
                          <Line
                            data={{
                              labels: [
                                'Jan',
                                'Feb',
                                'Mar',
                                'Apr',
                                'May',
                                'Jun',
                                'Jul',
                                'Aug',
                                'Sept',
                                'Oct',
                                'Nov',
                                'Dec',
                              ],
                              datasets: [
                                {
                                  label: 'Verified Tags',
                                  data: redMonthly,
                                  backgroundColor: '#FF4E60',
                                  borderColor: '#FF4E60',
                                },
                                {
                                  label: 'Activated Tags',
                                  data: greenMonthly,
                                  backgroundColor: '#1CBB8C',
                                  borderColor: '#1CBB8C',
                                },
                              ],
                            }}
                          />
                        </div>
                      ) : today ? (
                        <div
                          id="line-column-chart"
                          className="bar-charts "
                          dir="ltr"
                        >
                          <Line
                            data={{
                              labels: [
                                '12:00 am',
                                '2:00 am',
                                '4:00 am',
                                '6:00 am',
                                '8:00 am',
                                '10:00 am',
                                '12:00 pm',
                                '2:00 pm',
                                '4:00 pm',
                                '6:00 pm',
                                '8:00 pm',
                                '10:00 pm',
                              ],
                              datasets: [
                                {
                                  label: 'Verified Tags',
                                  data: todayRed,
                                  backgroundColor: '#FF4E60',
                                  borderColor: '#FF4E60',
                                },
                                {
                                  label: 'Activated Tags',
                                  data: todayGreen,
                                  backgroundColor: '#1CBB8C',
                                  borderColor: '#1CBB8C',
                                },
                              ],
                            }}
                          />
                        </div>
                      ) : weekly ? (
                        <div
                          id="line-column-chart"
                          className="bar-charts "
                          dir="ltr"
                        >
                          <Line
                            data={{
                              labels: [
                                'Sunday',
                                'Monday',
                                'Tuesday',
                                'Wednesday',
                                'Thursday',
                                'Friday',
                                'Saturday',
                              ],
                              datasets: [
                                {
                                  label: 'Verified Tags',
                                  data: redWeekly,
                                  borderColor: '#FF4E60',
                                  backgroundColor: '#FF4E60',
                                },
                                {
                                  label: 'Activated Tags',
                                  data: greenWeekly,
                                  borderColor: '#1CBB8C',
                                  backgroundColor: '#1CBB8C',
                                },
                              ],
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          id="line-column-chart"
                          className="bar-charts "
                          dir="ltr"
                        >
                          <Line
                            data={{
                              labels: [
                                'Sunday',
                                'Monday',
                                'Tuesday',
                                'Wednesday',
                                'Thursday',
                                'Friday',
                                'Saturday',
                              ],
                              datasets: [
                                {
                                  label: 'Verified Tags',
                                  data: redWeekly,
                                  borderColor: '#FF4E60',
                                  backgroundColor: '#FF4E60',
                                },
                                {
                                  label: 'Activated Tags',
                                  data: greenWeekly,
                                  borderColor: '#1CBB8C',
                                  backgroundColor: '#1CBB8C',
                                },
                              ],
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="card-body border-top text-center mt-2">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="d-inline-flex">
                        <h5 className="me-2">
                          {tags && tags.tag
                            ? formatNumber(thisMonth(tags.tag))
                            : 0}
                        </h5>
                        {tags && tags.tag ? (
                          comMonth(tags.tag) < 0 ? (
                            <div className="text-danger">
                              <i className="mdi mdi-menu-down font-size-14"></i>
                              {Math.round(comMonth(tags.tag))} %
                            </div>
                          ) : Number.isNaN(Math.round(comMonth(tags.tag))) ===
                            true ? (
                            <div className="text-danger">
                              <i className="mdi mdi-menu-down font-size-14"></i>
                              0 %
                            </div>
                          ) : Number.isFinite(comMonth(tags.tag)) !== true ? (
                            <div className="text-danger">
                              <i className="mdi mdi-menu-down font-size-14"></i>
                              0 %
                            </div>
                          ) : (
                            <div className="text-success">
                              <i className="mdi mdi-menu-up font-size-14"></i>
                              {Math.round(comMonth(tags.tag))} %
                            </div>
                          )
                        ) : (
                          <div className="text-danger">
                            <i className="mdi mdi-menu-down font-size-14"></i>0
                            %
                          </div>
                        )}
                      </div>
                      <p className="text-muted text-truncate mb-0">
                        This month
                      </p>
                    </div>

                    <div className="col-sm-4">
                      <div className="mt-5 mt-sm-0">
                        <p className="mb-2 text-muted text-truncate">
                          <i className="mdi mdi-circle text-primary font-size-10 me-1"></i>{' '}
                          This Year :
                        </p>
                        <div className="d-inline-flex">
                          <h5 className="mb-0 me-2">
                            {tags && tags.tag
                              ? formatNumber(thisYear(tags.tag))
                              : 0}
                          </h5>
                          {tags && tags.tag ? (
                            Number.isFinite(comYear(tags.tag)) !== true ? (
                              <div className="text-danger">
                                <i className="mdi mdi-menu-down font-size-14"></i>
                                0 %
                              </div>
                            ) : comYear(tags.tag) < 0 ? (
                              <div className="text-danger">
                                <i className="mdi mdi-menu-down font-size-14"></i>
                                {formatNumber(Math.round(comYear(tags.tag)))} %
                              </div>
                            ) : (
                              <div className="text-success">
                                <i className="mdi mdi-menu-up font-size-14"></i>
                                {formatNumber(Math.round(comYear(tags.tag)))} %
                              </div>
                            )
                          ) : (
                            <div className="text-danger">
                              <i className="mdi mdi-menu-down font-size-14"></i>
                              0 %
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="mt-4 mt-sm-0">
                        <p className="mb-2 text-muted text-truncate">
                          <i className="mdi mdi-circle text-success font-size-10 me-1"></i>{' '}
                          Previous Year :
                        </p>
                        <div className="d-inline-flex">
                          <h5 className="mb-0">
                            {tags && tags.tag
                              ? formatNumber(previousYear(tags.tag))
                              : 0}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-3">Benefit Claim Analysis</h4>

                  {result && result.claim.length > 0 ? (
                    <div className="">
                      <Pie
                        width={270}
                        height={270}
                        options={{ maintainAspectRatio: false }}
                        data={{
                          labels: ['Open', 'Pending', 'Completed'],
                          datasets: [
                            {
                              label: '# of claims',
                              data: [
                                claim(result, 'Open'),
                                claim(result, 'Pending'),
                                claim(result, 'Completed'),
                              ],
                              backgroundColor: [
                                '#FF4E60',
                                '#fcb92c',
                                '#1CBB8C',
                              ],
                            },
                          ],
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ minHeight: '285px' }}>
                      <p className="claim-card">There are no claims</p>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-4">
                      <div className="text-center mt-1">
                        <p className="mb-2 text-truncate">
                          <i className="mdi mdi-circle text-danger font-size-10 me-1"></i>{' '}
                          Open
                        </p>
                        <h5>
                          {result && result.claim.length > 0
                            ? claim(result, 'Open')
                            : 0}
                        </h5>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="text-center mt-1">
                        <p className="mb-2 text-truncate">
                          <i className="mdi mdi-circle text-warning font-size-10 me-1"></i>{' '}
                          Pending
                        </p>
                        <h5>
                          {result && result.claim.length > 0
                            ? claim(result, 'Pending')
                            : 0}
                        </h5>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="text-center mt-1">
                        <p className="mb-2 text-truncate">
                          <i className="mdi mdi-circle text-success font-size-10 me-1"></i>{' '}
                          Completed
                        </p>
                        <h5>
                          {result && result.claim.length > 0
                            ? claim(result, 'Completed')
                            : 0}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-2">Tags Stock Report</h4>
                  <div className="text-center">
                    <div className="row">
                      <div className="col-sm-12">
                        <div>
                          <Pie
                            width={270}
                            height={270}
                            options={{ maintainAspectRatio: false }}
                            data={{
                              labels: ['In Stock', 'Orders'],
                              datasets: [
                                {
                                  label: 'Tags Stock',
                                  data: [
                                    result && result.batch
                                      ? result.batch.filter(
                                        (item) => item.assigned === false,
                                      ).length
                                      : 0,
                                    result && result.order
                                      ? result.order.filter(
                                        (item) =>
                                          item.status === 'Open' ||
                                          item.status === 'Pending' ||
                                          item.status === 'Order_Received' ||
                                          item.status === 'Request_Sent',
                                      ).length
                                      : 0,
                                  ],
                                  backgroundColor: ['#FF4E60', '#1CBB8C'],
                                  hoverOffset: 4,
                                },
                              ],
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className=" mt-sm-0">
                          <p className="text-muted text-truncate mt-2 mb-1">
                            In Stock
                          </p>
                          <h5 className="mb-0">
                            {result && result.batch
                              ? result.batch.filter(
                                (item) => item.assigned === false,
                              ).length
                              : 0}
                          </h5>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="mt-sm-0">
                          <p className="text-muted text-truncate mt-2   mb-1">
                            Orders
                          </p>
                          <h5 className="mb-0">
                            {result && result.order
                              ? result.order.filter(
                                (item) =>
                                  item.status === 'Open' ||
                                  item.status === 'Pending' ||
                                  item.status === 'Order_Received' ||
                                  item.status === 'Request_Sent',
                              ).length
                              : 0}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3">
              <div className="card">
                <div
                  className="card-body"
                  style={{
                    minHeight: '410px',
                  }}
                >
                  <h4 className="card-title mb-3"> Product Trends</h4>

                  <div>
                    <div className="text-center">
                      <p className="mb-2">Total products</p>
                      <h4>
                        {tags && tags.tag
                          ? Object.values(highestProducts(tags.tag)).flat()
                            .length
                          : 0}
                      </h4>
                    </div>

                    <div className="table-responsive mt-4">
                      <table className="table table-hover mb-0 table-centered">
                        <tbody>
                          {tags && tags.tag ? (
                            Object.entries(highestProducts(tags.tag)).length ===
                              0 ? (
                              <div style={{ minHeight: '155px' }}>
                                <p className="product-card">
                                  There are no scans yet
                                </p>
                              </div>
                            ) : (
                              Object.entries(highestProducts(tags.tag)).map(
                                (item) => (
                                  <tr>
                                    <td>
                                      <h5 className="font-size-14 mb-0">
                                        {item[0]}
                                      </h5>
                                    </td>
                                    <td>
                                      <div id="spak-chart1"></div>
                                    </td>
                                    <td>
                                      <p className="text-muted mb-0">
                                        {item[1].length}
                                      </p>
                                    </td>
                                  </tr>
                                ),
                              )
                            )
                          ) : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Recent Activity Feed</h4>

                  <div
                    data-simplebar
                    style={{ minHeight: '330px', maxHeight: '330px' }}
                  >
                    <ul className="list-unstyled activity-wid">
                      {result && result.log.length < 0 ? (
                        <div style={{ minHeight: '300px' }}>
                          <p className="feed-card">
                            There are no recent activities
                          </p>
                        </div>
                      ) : (
                        result &&
                        result.log.length > 0 &&
                        activityFeed(result.log).map((item) => (
                          <li className="activity-list">
                            <div className="activity-icon avatar-xs">
                              <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                                {item.user &&
                                  item.user.account &&
                                  item.user.account === 'admin' ? (
                                  <i className="fal fa-user-lock"></i>
                                ) : item.user &&
                                  item.user.account &&
                                  item.user.account === 'farmer' ? (
                                  <i className="ri-user-line"></i>
                                ) : item.user &&
                                  item.user.account &&
                                  item.user.account === 'manufacturer' ? (
                                  <i className="fal fa-store"></i>
                                ) : null}
                              </span>
                            </div>
                            <div>
                              <div>
                                <h5 className="font-size-13">
                                  {moment(item.createdAt).format(
                                    'DD MMM, YYYY',
                                  )}{' '}
                                  <small className="text-muted">
                                    {moment(item.createdAt).format('hh:mm a')}
                                  </small>
                                </h5>
                              </div>

                              <div>
                                <p className="text-muted mb-0">
                                  {`${item.user && item.user.account
                                    ? item.user.account
                                    : null
                                    } ${item.activity}`}
                                </p>
                              </div>
                            </div>
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card">
                <div
                  className="card-body"
                  style={{
                    minHeight: '410px',
                  }}
                >
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <div>
                      <h6 className="card-title font">
                        Latest Farmsured Tags Requests
                      </h6>
                    </div>
                  </div>
                  {deleteLoading ? <LoadingBox /> : null}
                  {deleteError && (
                    <MessageBox variant="danger">{deleteError}</MessageBox>
                  )}
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="table-responsive">
                        <table
                          className="table table-centered` dt-responsive nowrap"
                          style={{
                            borderCollapse: 'collapse',
                            borderSpacing: 0,
                            width: '100',
                          }}
                        >
                          <thead className="thead-light">
                            <tr>
                              <th>Manufacturer Name</th>
                              <th>Product</th>
                              <th>Amount</th>
                              <th>Status</th>
                              <th style={{ width: '100px' }}> Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {result && result.order.length === 0 ? (
                              <div className="d-sm-flex align-items-center justify-content-between">
                                <p className="card-title-desc">
                                  There is no data
                                </p>
                              </div>
                            ) : (
                              result &&
                              recentOrder(result.order).map((item) => (
                                <tr key={item._id}>
                                  <td>
                                    {item.manufacturer &&
                                      item.manufacturer.name}
                                  </td>
                                  <td>{item.product && item.product.name}</td>
                                  <td>
                                    {item.amount
                                      ? formatNumber(item.amount)
                                      : null}
                                  </td>
                                  <td>
                                    <div
                                      className={`badge badge-soft-${item.status} font-size-11`}
                                    >
                                      {item.status}
                                    </div>
                                  </td>
                                  <td id="tooltip-container1">
                                    <a
                                      href={`/request/${item._id}`}
                                      className="btn p-0 text-primary"
                                      style={{ marginRight: '20px' }}
                                      data-bs-container="#tooltip-container1"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      title="Edit"
                                    >
                                      Edit
                                    </a>
                                    <button
                                      className="btn p-0 text-danger"
                                      data-bs-container="#tooltip-container1"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      title="Delete"
                                      onClick={() => deleteHandler(item)}
                                    >
                                      <i className="mdi mdi-trash-can font-size-18"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                        {result && result.order.length > 0 ? (
                          <div className="text-center mt-4">
                            <a
                              href="/tag-request"
                              className="btn btn-success btn-sm"
                            >
                              View more
                            </a>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {/* First Chart */}
            <div className="col-lg-3">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-3">Channel Trends</h4>
                  {result &&
                    result.log &&
                    result.log.filter((item) => item.channel).length > 0 ? (
                    <div
                      style={{
                        width: '100%',
                        margin: 'auto',
                      }}
                    >
                      <Pie
                        data={{
                          labels: ['Web', 'Whatsapp', 'USSD'],
                          datasets: [
                            {
                              label: 'Tags Stock',
                              data: [
                                result && result.log
                                  ? result.log.filter(
                                    (item) => item.channel === 'Web',
                                  ).length
                                  : 0,
                                result && result.log
                                  ? result.log.filter(
                                    (item) => item.channel === 'Whatsapp',
                                  ).length
                                  : 0,
                                result && result.log
                                  ? result.log.filter(
                                    (item) => item.channel === 'USSD',
                                  ).length
                                  : 0,
                              ],
                              backgroundColor: [
                                '#ffce56',
                                '#FF4E60',
                                '#1CBB8C',
                              ],
                              hoverOffset: 4,
                            },
                          ],
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <p className="channel-card">There are no activities</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Second Chart */}
            <div className="col-lg-3">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-3"> Web</h4>

                  {result &&
                    result.log &&
                    result.log.filter((item) => item.channel === 'Web').length >
                    0 ? (
                    <div
                      style={{
                        width: '100%',
                        margin: 'auto',
                      }}
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                    >
                      <Pie
                        data={{
                          labels: ['Web'],
                          datasets: [
                            {
                              label: 'Tags Stock',
                              data: [
                                result && result.log
                                  ? result.log.filter(
                                    (item) => item.channel === 'Web',
                                  ).length
                                  : 0,
                              ],
                              backgroundColor: ['#ffce56'],
                              hoverOffset: 4,
                            },
                          ],
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <p className="channel-card">There are no activities</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Third Chart */}
            <div className="col-lg-3">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-3"> Whatsapp </h4>
                  {result &&
                    result.log &&
                    result.log.filter((item) => item.channel === 'Whatsapp')
                      .length > 0 ? (
                    <div
                      style={{
                        width: '100%',
                        margin: 'auto',
                      }}
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                    >
                      <Pie
                        data={{
                          labels: ['Whatsapp'],
                          datasets: [
                            {
                              label: 'Tags Stock',
                              data: [
                                result && result.log
                                  ? result.log.filter(
                                    (item) => item.channel === 'Whatsapp',
                                  ).length
                                  : 0,
                              ],
                              backgroundColor: ['#FF4E60'],
                              hoverOffset: 4,
                            },
                          ],
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <p className="channel-card">There are no activities</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Fourth Chart */}
            <div className="col-lg-3">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-3"> USSD</h4>
                  {result &&
                    result.log &&
                    result.log.filter((item) => item.channel === 'USSD').length >
                    0 ? (
                    <div
                      style={{
                        width: '100%',
                        margin: 'auto',
                      }}
                    >
                      <Pie
                        data={{
                          labels: ['USSD'],
                          datasets: [
                            {
                              label: 'Tags Stock',
                              data: [
                                result && result.log
                                  ? result.log.filter(
                                    (item) => item.channel === 'USSD',
                                  ).length
                                  : 0,
                              ],
                              backgroundColor: ['#1CBB8C'],
                              hoverOffset: 4,
                            },
                          ],
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <p className="channel-card">There are no activities</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                  <h4 className="card-title mb-4">
                    Scanning Activities by Locations
                  </h4>
                </div>
                <div className="text-primary ms-auto">
                  <button
                    className="btn"
                    type="button"
                    onClick={() =>
                      exportComponentAsPNG(componentRef, {
                        fileName,
                      })
                    }
                  >
                    <i className="fa fa-download me-2" aria-hidden="true"></i>{' '}
                    Download
                  </button>
                </div>
              </div>

              <div
                className="print"
                ref={componentRef}
                style={{ height: '80vh', width: '100%' }}
              >

                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_KEY}>
                  <Map
                    style={{ width: '100%', height: '100vh' }}
                    defaultCenter={{ lat: 9.0845755, lng: 8.674252499999994 }}
                    defaultZoom={6}
                    gestureHandling={'greedy'}
                    disableDefaultUI={false}
                    mapId={process.env.REACT_APP_MAP_ID}
                  >
                    {locations && locations.length > 0
                      ? locations.map((item) => {
                        return (
                          <div>
                            {
                              item.redlat && item.redlong ?
                                <AdvancedMarker position={{ lat: item.redlat, lng: item.redlong }} onClick={() => {
                                  openRed(item)
                                }}>
                                  <Pin background={'red'} glyphColor={'white'} borderColor={'white'} />
                                </AdvancedMarker>
                                : null
                            }

                            {
                              item.greenlat && item.greenlong ?
                                <AdvancedMarker position={{ lat: item.greenlat, lng: item.greenlong }} onClick={() => {
                                  openGreen(item)
                                }}>
                                  <Pin background={'green'} glyphColor={'white'} borderColor={'white'} />
                                </AdvancedMarker>
                                : null
                            }
                          </div>
                        )
                      })
                      : null}

                    {openRedDialog && (
                      <InfoWindow
                        headerContent={
                          <h5 className="card-title">
                            <b>{selectedRedMark.product.name}</b>
                          </h5>}
                        position={{
                          lat: selectedRedMark.redlat,
                          lng: selectedRedMark.redlong,
                        }}
                        onClose={() => {
                          setOpenRedDialog(false)
                        }}
                        onCloseClick={() => {
                          setOpenRedDialog(false)
                        }}
                      >
                        <div>
                          <img
                            src="assets/images/red_tag.png"
                            alt="red tag"
                            style={{ width: '50px', marginBottom: '6px', }}
                          />
                          <br />
                          <a
                            href={`/farmer/detail/${selectedRedMark.farmer._id}`}
                            style={{
                              marginBottom: '2px',
                            }}
                          >
                            {selectedRedMark.farmer.phone}
                          </a>
                          <br />
                          <div
                            className=""
                            style={{
                              marginBottom: '3px',
                              marginTop: '3px',
                            }}
                          >
                            <span
                              className="card-desc"
                              style={{
                                textTransform: 'capitalize',
                              }}
                            >
                              {selectedRedMark.farmer && selectedRedMark.farmer.firstName}
                            </span>{' '}
                            <span
                              className="card-desc"
                              style={{
                                textTransform: 'capitalize',
                              }}
                            >
                              {selectedRedMark.farmer && selectedRedMark.farmer.lastName}
                            </span>
                          </div>

                          <p
                            className="card-desc"
                            style={{ marginBottom: '2px', marginTop: '3px' }}
                          >
                            {moment(selectedRedMark.redActivatedAt).format(
                              'Do MMMM YYYY,  h:mm a',
                            )}
                          </p>
                        </div>
                      </InfoWindow>
                    )}

                    {openGreenDialog && (
                      <InfoWindow
                        headerContent={
                          <h5 className="card-title">
                            <b>{selectedGreenMark.product.name}</b>
                          </h5>}
                        position={{
                          lat: selectedGreenMark.redlat,
                          lng: selectedGreenMark.redlong,
                        }}
                        onClose={() => {
                          setOpenGreenDialog(false)
                        }}
                        onCloseClick={() => {
                          setOpenGreenDialog(false)
                        }}
                      >
                        <div>
                          <img
                            src="assets/images/green_tag.png"
                            alt="red tag"
                            style={{ width: '50px', marginBottom: '6px', }}
                          />
                          <br />
                          <a
                            href={`/farmer/detail/${selectedGreenMark.farmer._id}`}
                            style={{
                              marginBottom: '2px',
                            }}
                          >
                            {selectedGreenMark.farmer.phone}
                          </a>
                          <br />
                          <div
                            className=""
                            style={{
                              marginBottom: '3px',
                              marginTop: '3px',
                            }}
                          >
                            <span
                              className="card-desc"
                              style={{
                                textTransform: 'capitalize',
                              }}
                            >
                              {selectedGreenMark.farmer && selectedGreenMark.farmer.firstName}
                            </span>{' '}
                            <span
                              className="card-desc"
                              style={{
                                textTransform: 'capitalize',
                              }}
                            >
                              {selectedGreenMark.farmer && selectedGreenMark.farmer.lastName}
                            </span>
                          </div>

                          <p
                            className="card-desc"
                            style={{ marginBottom: '2px', marginTop: '3px' }}
                          >
                            {moment(selectedGreenMark.redActivatedAt).format(
                              'Do MMMM YYYY,  h:mm a',
                            )}
                          </p>
                        </div>
                      </InfoWindow>
                    )}
                  </Map>
                </APIProvider>
                {/* <WrappedMap
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
                  loadingElement={<div style={{ height: '100%' }} />}
                  containerElement={<div style={{ height: '100%' }} />}
                  mapElement={<div style={{ height: '100%' }} />}
                /> */}
                {/* <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_KEY,
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  layerTypes={['TrafficLayer', 'TransitLayer']}
                >
                  {locations && locations.length > 0
                    ? locations.map((item) => {
                        return (
                          <RedMarker
                            key={item.tag}
                            lat={item.redlat}
                            lng={item.redlong}
                            item={item}
                          />
                        )
                      })
                    : null}
                  {locations && locations.length > 0
                    ? locations.map((item) => {
                        return (
                          <GreenMarker
                            key={item.tag}
                            lat={item.greenlat}
                            lng={item.greenlong}
                            item={item}
                          />
                        )
                      })
                    : null}
                </GoogleMapReact> */}
              </div>
            </div>
          </div>
          {alert}
        </div>
      )}
    </div>
  )
}

export default Admin
