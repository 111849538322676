import axios from 'axios'
import {
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from '../../../constants/adminConstants/usersContants'

export const updateUser = (
  itemId,
  firstName,
  lastName,
  email,
  address,
  phone,
  state,
  lga,
  isSuper,
  status,
  role,
) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_USER_REQUEST,
    payload: {
      itemId,
      firstName,
      lastName,
      email,
      address,
      phone,
      state,
      lga,
      isSuper,
      status,
      role,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/update-user',
      {
        itemId,
        firstName,
        lastName,
        email,
        address,
        phone,
        state,
        lga,
        isSuper,
        status,
        role,
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: UPDATE_USER_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: UPDATE_USER_FAIL, payload: message })
  }
}
