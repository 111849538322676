export const WALLET_BALANCE_REQUEST = 'WALLET_BALANCE_REQUEST'
export const WALLET_BALANCE_SUCCESS = 'WALLET_BALANCE_SUCCESS'
export const WALLET_BALANCE_FAIL = 'WALLET_BALANCE_FAIL'

export const WALLET_FUND_REQUEST = 'WALLET_FUND_REQUEST'
export const WALLET_FUND_SUCCESS = 'WALLET_FUND_SUCCESS'
export const WALLET_FUND_FAIL = 'WALLET_FUND_FAIL'
export const WALLET_FUND_RESET = 'WALLET_FUND_RESET'

export const VERIFY_WALLET_FUND_REQUEST = 'VERIFY_WALLET_FUND_REQUEST'
export const VERIFY_WALLET_FUND_SUCCESS = 'VERIFY_WALLET_FUND_SUCCESS'
export const VERIFY_WALLET_FUND_FAIL = 'VERIFY_WALLET_FUND_FAIL'

export const SEND_FUND_REQUEST = 'SEND_FUND_REQUEST'
export const SEND_FUND_SUCCESS = 'SEND_FUND_SUCCESS'
export const SEND_FUND_FAIL = 'SEND_FUND_FAIL'
export const SEND_FUND_RESET = 'SEND_FUND_RESET'

export const WALLET_TRANSACTIONS_REQUEST = 'WALLET_TRANSACTIONS_REQUEST'
export const WALLET_TRANSACTIONS_SUCCESS = 'WALLET_TRANSACTIONS_SUCCESS'
export const WALLET_TRANSACTIONS_FAIL = 'WALLET_TRANSACTIONS_FAIL'

export const VERIFY_WALLET_USERID_REQUEST = 'VERIFY_WALLET_USERID_REQUEST'
export const VERIFY_WALLET_USERID_SUCCESS = 'VERIFY_WALLET_USERID_SUCCESS'
export const VERIFY_WALLET_USERID_FAIL = 'VERIFY_WALLET_USERID_FAIL'
export const VERIFY_WALLET_USERID_RESET = 'VERIFY_WALLET_USERID_RESET'

export const VERIFY_ACCOUNT_REQUEST = 'VERIFY_ACCOUNT_REQUEST'
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS'
export const VERIFY_ACCOUNT_FAIL = 'VERIFY_ACCOUNT_FAIL'
export const VERIFY_ACCOUNT_RESET = 'VERIFY_ACCOUNT_RESET'

export const WITHDRAW_FUND_REQUEST = 'WITHDRAW_FUND_REQUEST'
export const WITHDRAW_FUND_SUCCESS = 'WITHDRAW_FUND_SUCCESS'
export const WITHDRAW_FUND_FAIL = 'WITHDRAW_FUND_FAIL'
export const WITHDRAW_FUND_RESET = 'WITHDRAW_FUND_RESET'

export const INITIATE_ESCROW_TRANSFER_REQUEST = 'INITIATE_ESCROW_TRANSFER_REQUEST'
export const INITIATE_ESCROW_TRANSFER_SUCCESS = 'INITIATE_ESCROW_TRANSFER_SUCCESS'
export const INITIATE_ESCROW_TRANSFER_FAIL = 'INITIATE_ESCROW_TRANSFER_FAIL'
export const INITIATE_ESCROW_TRANSFER_RESET = 'INITIATE_ESCROW_TRANSFER_RESET'

export const SECURE_TRANSFER_REQUEST = 'SECURE_TRANSFER_REQUEST'
export const SECURE_TRANSFER_SUCCESS = 'SECURE_TRANSFER_SUCCESS'
export const SECURE_TRANSFER_FAIL = 'SECURE_TRANSFER_FAIL'


export const ALL_SECURE_FUNDS_REQUEST = 'ALL_SECURE_FUNDS_REQUEST'
export const ALL_SECURE_FUNDS_SUCCESS = 'ALL_SECURE_FUNDS_SUCCESS'
export const ALL_SECURE_FUNDS_FAIL = 'ALL_SECURE_FUNDS_FAIL'