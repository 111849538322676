import React from 'react'
import { useSelector } from 'react-redux'

function LeftSidebar() {
  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  return (
    <div>
      {/* <!-- ========== Left Sidebar Start ========== --> */}

      {/* ===============================================
      ===================================================
      =================Admin Side Bar====================
      ===================================================
      =============================================== */}
      {userInfo && userInfo.account === 'admin' ? (
        <div className="vertical-menu">
          <div data-simplebar className="h-100">
            {/* <!--- Sidemenu --> */}
            <div id="sidebar-menu">
              {/* <!-- Left Menu Start --> */}
              <ul className="metismenu list-unstyled" id="side-menu">
                <li>
                  <a href="/dashboard" className="waves-effect">
                    <i className="fal fa-analytics"></i>
                    <span>Overview</span>
                  </a>
                </li>
                <li className="menu-title">
                  Farmsured
                  <sup>
                    <small>TM</small>
                  </sup>
                </li>
                {/* Tags Dropdown */}
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-tag font-size-15"></i>
                    <span>Tags & Points</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/tag-generation">Create New</a>
                    </li>
                    <li>
                      <a href="/tag-analysis">Analysis</a>
                    </li>
                    <li>
                      <a href="/generate-label">Generate Label</a>
                    </li>
                    <li>
                      <a href="/tag-request">Requests</a>
                    </li>
                  </ul>
                </li>
                {/* Manufacturer Dropdown */}
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-store font-size-15"></i>
                    <span>Organization</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/manufacturers">All Organization</a>
                    </li>
                    <li>
                      <a href="/create-manufacturer">Add New</a>
                    </li>
                  </ul>
                </li>
                {/* Products Dropdown */}
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-box-check font-size-15"></i>
                    <span>Products & Services </span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/products">All Products & Services</a>
                    </li>
                    <li>
                      <a href="/create-product">Add New</a>
                    </li>
                    <li>
                      <a href="/product-category">Category</a>
                    </li>
                  </ul>
                </li>

                {/* <li className="">
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                    aria-expanded="false"
                  >
                    <i className="ri-share-line"></i>
                    <span>Enterprise Protocol</span>
                  </a>
                  <ul
                    className="sub-menu mm-collapse"
                    aria-expanded="true"
                    style={{ height: '0px' }}
                  >
                    <li className="">
                      <a
                        href="javascript: void(0);"
                        className="has-arrow"
                        aria-expanded="false"
                      >
                        Enterprise Package
                      </a>
                      <ul className="sub-menu mm-collapse" aria-expanded="true">
                        <li>
                          <a href="/all-enterprice">All Enterprise</a>
                        </li>
                        <li>
                          <a href="/add-enterprice">Add New</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul
                    className="sub-menu mm-collapse"
                    aria-expanded="true"
                    style={{ height: '0px' }}
                  >
                    <li className="">
                      <a
                        href="javascript: void(0);"
                        className="has-arrow"
                        aria-expanded="false"
                      >
                        Protocol System
                      </a>
                      <ul className="sub-menu mm-collapse" aria-expanded="true">
                        <li>
                          <a href="/protocols">All Protocol</a>
                        </li>
                        <li>
                          <a href="/create-protocol">Add New</a>
                        </li>
                        <li>
                          <a href="/protocol-category">Protocol Category</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li> */}

                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="ri-share-line "></i>
                    <span>Enterprise Package</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/all-enterprice">All Enterprise</a>
                    </li>
                    <li>
                      <a href="/add-enterprice">Add New</a>
                    </li>
                  </ul>
                </li>

                {/* Protocol Package Dropdown */}
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-archive font-size-15"></i>
                    <span>Protocol System</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/protocols">All Protocol</a>
                    </li>
                    <li>
                      <a href="/create-protocol">Add New</a>
                    </li>
                    <li>
                      <a href="/protocol-category">Protocol Category</a>
                    </li>
                  </ul>
                </li>

                {/* Farmclusters */}
                <li className="menu-title">
                  Farmclusters
                  <sup>
                    <small>TM</small>
                  </sup>
                </li>
                <li>
                  <a href="/farmers" className="waves-effect">
                    <i className="fal fa-id-badge green font-size-15"></i>
                    <span>Farmers</span>
                  </a>
                </li>
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal  fa-tractor green font-size-14"></i>
                    <span>Farm Cycle</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/all-farm-cycle">All Farm Cycle</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal  fa-users green font-size-14"></i>
                    <span>Clusters</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/cluster/yeap-safer">YEAP_SAfER</a>
                    </li>
                  </ul>
                </li>

                {/* Farmtrove */}
                <li className="menu-title">
                  Farmtrove
                  <sup>
                    <small>TM</small>
                  </sup>
                </li>
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-shield-check font-size-15"></i>
                    <span>Benefit Status</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/benefit-status">Farmer Check</a>
                    </li>
                    <li>
                      <a href="/status" target="blank">
                        Service Providers Check
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-shield-alt font-size-15"></i>
                    <span>Benefits</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/benefits">All Benefits</a>
                    </li>
                    <li>
                      <a href="/create-benefit">Add New</a>
                    </li>
                    <li>
                      <a href="/benefit-category">Benefit Category</a>
                    </li>
                    <li>
                      <a href="/claim-management">Farmers Claims</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-cogs font-size-14"></i>
                    <span>Service Providers</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/service-providers">All Service Providers</a>
                    </li>
                    <li>
                      <a href="/new-service-provider">Add New</a>
                    </li>
                    <li>
                      <a href="/services">Services</a>
                    </li>
                    <li>
                      <a href="/service-category">Service Category</a>
                    </li>
                    <li>
                      <a href="/benefit-claims">Benefits Claims</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-university font-size-15"></i>
                    <span>Finance </span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    {/* <li>
                      <a href="/farmtrove/account-report">Account Report</a>
                    </li> */}
                    <li>
                      <a href="/farmtrove/fund-allocation">Fund Allocation</a>
                    </li>
                    <li>
                      <a href="/farmtrove/loan-provider">Loan Provider</a>
                    </li>
                    <li>
                      <a href="/farmtrove/loan">Loan Applications</a>
                    </li>
                    <li>
                      <a href="/farmtrove/withdraw-request">
                        Withdraw Requests
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="javascript: void(0);" className="waves-effect">
                    <i className="fal fa-piggy-bank green font-size-15"></i>
                    <span>Pensions</span>
                  </a>
                </li>
                <li>
                  <a href="javascript: void(0);" className="waves-effect">
                    <i className="fal fa-umbrella green font-size-15"></i>
                    <span>Insurance</span>
                  </a>
                </li>

                {/* Administration */}
                <li className="menu-title">Administration</li>
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="ri-mail-send-line"></i>
                    <span>Messages</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/inbox">Inbox</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-tools green font-size-15"></i>
                    <span>Settings</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/users">Users</a>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul className="metismenu list-unstyled" id="side-menu"></ul>
            </div>
            {/* <!-- Sidebar --> */}
          </div>
        </div>
      ) : null}

      {/* ===============================================
      ===================================================
      ==============Manufacturer Side Bar================
      ===================================================
      =============================================== */}
      {userInfo && userInfo.account === 'manufacturer' ? (
        <div className="vertical-menu">
          <div data-simplebar className="h-100">
            {/* <!--- Sidemenu --> */}
            <div id="sidebar-menu">
              {/* <!-- Left Menu Start --> */}
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">Menu</li>
                <li>
                  <a href="/dashboard" className="waves-effect">
                    <i className="fal fa-analytics font-size-13"></i>
                    <span>Dashboard</span>
                  </a>
                </li>

                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-tags font-size-14"></i>
                    <span>Tag Management</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/manufacturer/activate-tag">Activate Tag</a>
                    </li>
                    <li>
                      <a href="/manufacturer/tag-request">Tag Requests</a>
                    </li>
                  </ul>
                </li>

                {/* Products Dropdown */}
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-box-check font-size-14"></i>
                    <span>Products</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/manufacturer/products">All Products</a>
                    </li>
                  </ul>
                </li>

                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-cogs font-size-14"></i>
                    <span>Service Providers</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/manufacturer/service-providers">
                        All Service Providers
                      </a>
                    </li>
                    <li>
                      <a href="/manufacturer/new-service-provider">Add New</a>
                    </li>
                  </ul>
                </li>

                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-shopping-cart green font-size-14"></i>
                    <span>Orders</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/manufacturer/all-orders">All Orders</a>
                    </li>
                  </ul>
                </li>

                <li>
                  <a href="/manufacturer/messages" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span>Messages</span>
                  </a>
                </li>

                {/* Farmtrove */}
                <li className="menu-title">
                  Farmtrove
                  <sup>
                    <small>TM</small>
                  </sup>
                </li>
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal  fa-wallet green font-size-14"></i>
                    <span>Finance</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/farmtrove/wallet">Wallet</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            {/* <!-- Sidebar --> */}
          </div>
        </div>
      ) : null}

      {/* ===============================================
      ===================================================
      =================Farmer Side Bar===================
      ===================================================
      =============================================== */}
      {userInfo && userInfo.account === 'farmer' ? (
        <div className="vertical-menu">
          <div data-simplebar className="h-100">
            {/* <!--- Sidemenu --> */}
            <div id="sidebar-menu">
              {/* <!-- Left Menu Start --> */}
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">Menu</li>
                <li>
                  <a href="/dashboard" className="waves-effect">
                    <i className="fal fa-analytics font-size-13"></i>
                    <span>Dashboard</span>
                  </a>
                </li>
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal  fa-tractor green font-size-14"></i>
                    <span>Farm Cycle</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/user/create-farm-cycle">Add New</a>
                    </li>
                    <li>
                      <a href="/user/all-farm-cycle">All Farm Cycle</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-tags green font-size-13"></i>
                    <span>Validate Product</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/user/activate-tag">Verify / Activate Product</a>
                    </li>
                  </ul>
                </li>

                <li>
                  <a href="/user/messages" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span>Messages</span>
                  </a>
                </li>

                {/* <li>
                  <a href="/user/farmgpt" className="waves-effect">
                    <i className="fal fa-comments"></i>
                    <span>FarmGPT</span>
                  </a>
                </li> */}

                {/* Farmtrove */}
                <li className="menu-title">
                  Farmtrove
                  <sup>
                    <small>TM</small>
                  </sup>
                </li>
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-university green font-size-14"></i>
                    <span>Finance</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    {/* <li>
                      <a href="/farmtrove/account">Account</a>
                    </li> */}
                    <li>
                      <a href="/farmtrove/wallet">Wallet</a>
                    </li>
                    {/* <li>
                      <a href="javascript: void(0);">Pay Bills</a>
                    </li> */}
                    <li>
                      <a href="/farmtrove/loan-application">Loan</a>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal  fa-umbrella green font-size-14"></i>
                    <span>Farmtrove Insurance</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="javascript: void(0);">Health Protection</a>
                    </li>
                    <li>
                      <a href="javascript: void(0);">Farm Protection</a>
                    </li>
                    <li>
                      <a href="javascript: void(0);">Market Protection</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal  fa-piggy-bank green font-size-14"></i>
                    <span>Farmtrove Pensions</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="javascript: void(0);">My Account</a>
                    </li>
                    <li>
                      <a href="javascript: void(0);">My Pension Journey</a>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </div>
            {/* <!-- Sidebar --> */}
          </div>
        </div>
      ) : null}

      {/* ===============================================
      ===================================================
      ==========Service Proviider Side Bar===============
      ===================================================
      =============================================== */}
      {userInfo && userInfo.account === 'service' ? (
        <div className="vertical-menu">
          <div data-simplebar className="h-100">
            {/* <!--- Sidemenu --> */}
            <div id="sidebar-menu">
              {/* <!-- Left Menu Start --> */}
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">Menu</li>
                <li>
                  <a href="/dashboard" className="waves-effect">
                    <i className="fal fa-analytics font-size-13"></i>
                    <span>Dashboard</span>
                  </a>
                </li>

                {/* Farmtrove */}
                <li className="menu-title">
                  Farmsured
                  <sup>
                    <small>TM</small>
                  </sup>
                </li>

                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-shopping-cart green font-size-14"></i>
                    <span>Orders</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/service/product-orders">All Product Orders</a>
                    </li>
                    <li>
                      <a href="/service/new-order">Make a Request</a>
                    </li>
                  </ul>
                </li>

                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal fa-box-check green font-size-14"></i>
                    <span>Products/Services</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/service/all-services">My Products/Services</a>
                    </li>
                    <li>
                      <a href="/service/add-service">Add New</a>
                    </li>
                    <li>
                      <a href="/service/all-orders">My Orders</a>
                    </li>
                  </ul>
                </li>

                {/* Farmtrove */}
                <li className="menu-title">
                  Farmtrove
                  <sup>
                    <small>TM</small>
                  </sup>
                </li>
                <li>
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                  >
                    <i className="fal  fa-wallet green font-size-14"></i>
                    <span>Finance</span>
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="/farmtrove/wallet">Wallet</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            {/* <!-- Sidebar --> */}
          </div>
        </div>
      ) : null}
      {/* <!-- Left Sidebar End --> */}
    </div>
  )
}

export default LeftSidebar
