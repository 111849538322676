import React, { useState, useEffect } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import SweetAlert from 'react-bootstrap-sweetalert'
import { listProducts } from '../../../actions/adminActions/productActions/allProduct'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingBox, MessageBox } from '../../../components'
import { createEnterprise } from '../../../actions/adminActions/enterpriseActions/createEnterprise'
import { ENTERPRISE_CREATE_RESET } from '../../../constants/adminConstants/enterpriseConstant'
import Select from 'react-select'
import { allProductCategory } from '../../../actions/adminActions/productActions/productCategory'
import { allServiceCategory } from '../../../actions/adminActions/serviceActions/allServiceCategory'

function NewEnterpricePage() {
  const [alert, setAlert] = useState()
  const [name, setName] = useState('')
  const [category, setProductCategory] = useState('')
  const [duration, setDuration] = useState('')
  const [list, setinputList] = useState([
    { EOPItem: '', quantity: '', serviceCategory: '', product: [], notes: '', UoM: '' },
  ])

  console.log(list)

  // const productsList = useSelector((state) => state.productsList)
  // const { loading: loadingProduct, products } = productsList

  const enterpriseCreate = useSelector((state) => state.enterpriseCreate)
  const { loading, newEnterprise, error } = enterpriseCreate

  const productCategory = useSelector((state) => state.productCategory)
  const { loading: loadingCategory, categorylist } = productCategory

  const serviceCategory = useSelector((state) => state.serviceCategory)
  const { loading: loadingServices, categorylist: services } = serviceCategory

  const dispatch = useDispatch()

  useEffect(() => {
    // dispatch(listProducts())
    dispatch(allProductCategory())
    dispatch(allServiceCategory())

    if (newEnterprise) {
      setinputList([
        { EOPItem: '', quantity: '', serviceCategory: '', product: [], notes: '', UoM: '' },
      ])
      setTimeout(() => {
        dispatch({ type: ENTERPRISE_CREATE_RESET })
      }, 2500)
    }

    if (error) {
      setinputList([
        { EOPItem: '', quantity: '', serviceCategory: '', product: [], notes: '', UoM: '' },
      ])
      setTimeout(() => {
        dispatch({ type: ENTERPRISE_CREATE_RESET })
      }, 2500)
    }
  }, [dispatch, newEnterprise, error])

  const handleinputchange = (e, index) => {
    const { name, value } = e
    const listItem = [...list]
    listItem[index][name] = value
    setinputList(listItem)
  }

  const handleremove = (index) => {
    const listItem = [...list]
    listItem.splice(index, 1)
    setinputList(listItem)
  }

  const handleAddClick = (e) => {
    e.preventDefault()
    const emptyInput = list.some(
      (item) =>
        item.EOPItem === '' ||
        item.quantity === '' ||
        item.serviceCategory === '' ||
        item.notes === '' ||
        item.UoM === '',
    )

    if (!emptyInput) {
      setinputList((newInput) => [
        ...newInput,
        { EOPItem: '', quantity: '', serviceCategory: '', product: [], notes: '', UoM: '' },
      ])
    } else {
      // Handle case where empty fields exist in the existing items
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty Field(s)"
          onConfirm={() => setAlert()}
          onCancel={() => setAlert()}
        >
          Please fill in all fields before adding a new item.
        </SweetAlert>,
      )
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!name || !duration || !category) {
      // Handle case where empty fields exist in the existing items
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty Field(s)"
          onConfirm={() => setAlert()}
          onCancel={() => setAlert()}
        >
          Please fill in all fields before adding a new item.
        </SweetAlert>,
      )
    } else {
      const inputList =
        Array.isArray(list) &&
        list.length > 0 &&
        list.map((item) => ({
          ...item,
          product: item.product.map((productItem) => productItem.value),
        }))

      dispatch(createEnterprise(name, duration, category, inputList))
    }
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Enterprise</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Enterprise</a>
                      </li>
                      <li className="breadcrumb-item active">New Enterprise</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <LoadingBox />
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      {error && (
                        <MessageBox variant="danger">{error}</MessageBox>
                      )}
                      {newEnterprise && (
                        <MessageBox variant="success">
                          {newEnterprise.message}
                        </MessageBox>
                      )}
                      <h4 className="card-title">Basic Information</h4>
                      <p className="card-title-desc">
                        Fill all information below
                      </p>
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-8 mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Enterprise Name
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enterprise Name"
                              id="example-text-input"
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-md-8 mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              What is the minimum duration(in days)?
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="What is the minimum duration?"
                              id="example-text-input"
                              onChange={(e) => setDuration(e.target.value)}
                              required
                            />
                          </div>

                          <div className="col-md-8">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                forHtml="contactPersonName"
                              >
                                Product Category
                              </label>
                              <select
                                onChange={(e) =>
                                  setProductCategory(e.target.value)
                                }
                                className="select2 form-select"
                                required
                              >
                                {loadingCategory ? (
                                  'Loading...'
                                ) : categorylist.length > 0 ? (
                                  <>
                                    <option>Select product category</option>
                                    {categorylist.map((item) => (
                                      <option value={item._id} key={item._id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </>
                                ) : (
                                  'there is no product category'
                                )}
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-12 mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Add the Economics of Production to this
                              Enterprise:
                            </label>
                            {list.map((x, i) => {
                              return (
                                <div key={i}>
                                  <div className="row">
                                    <div className="form-group col-md-8 mb-2">
                                      <div className="card bg-soft-info">
                                        <div className="card-body p-2">
                                          <p className="m-0 p-1">
                                            Item {i + 1}
                                          </p>
                                        </div>
                                      </div>
                                      <label
                                        htmlFor="example-text-input"
                                        className="col-md-12 col-form-label"
                                      >
                                        EOP Item/Activity
                                      </label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="EOPItem"
                                        placeholder="EOP Item/Activity"
                                        id="example-text-input"
                                        onChange={(e) =>
                                          handleinputchange(
                                            {
                                              name: e.target.name,
                                              value: e.target.value,
                                            },
                                            i,
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="mb-1">
                                    <div className="row">
                                      <div className="col-md-8">
                                        <label
                                          className="form-label"
                                          forHtml="serviceCategory"
                                        >
                                          Service Category
                                        </label>
                                        {loadingServices ? (
                                          'Loading...'
                                        ) : services.length === 0 ? (
                                          <div className='p-2 bg-soft-danger r-4'>No Service Category</div>
                                        ) : (
                                          <Select
                                            onChange={(opt) => handleinputchange(
                                              {
                                                name: 'serviceCategory',
                                                value: opt.value,
                                              },
                                              i,
                                            )}
                                            required
                                            name='serviceCategory'
                                            options={services.map((opt) => ({
                                              label: opt.name,
                                              value: opt._id,
                                            }))}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="form-group col-md-8 mb-2">
                                    <label
                                      htmlFor="example-text-input"
                                      className="col-md-12 col-form-label"
                                    >
                                      Product
                                    </label>
                                    <Select
                                      onChange={(opt) =>
                                        handleinputchange(
                                          {
                                            name: 'product',
                                            value: opt,
                                          },
                                          i,
                                        )
                                      }
                                      getOptionValue={(option) =>
                                        option.value
                                      }
                                      required
                                      name="product"
                                      isMulti
                                      options={
                                        loadingProduct ? (
                                          '.....'
                                        ) : products.length === 0 ? (
                                          <div>No products</div>
                                        ) : (
                                          products &&
                                          products.map((opt) => ({
                                            label: opt.name,
                                            value: opt._id,
                                          }))
                                        )
                                      }
                                    />
                                  </div> */}
                                  <div className="row">

                                    <div className="form-group col-md-4 mb-2">
                                      <label
                                        htmlFor="example-text-input"
                                        className="col-md-12 col-form-label"
                                      >
                                        Quantity
                                      </label>
                                      <input
                                        className="form-control"
                                        name="quantity"
                                        type="number"
                                        placeholder="Quantity"
                                        id="example-text-input"
                                        onChange={(e) =>
                                          handleinputchange(
                                            {
                                              name: e.target.name,
                                              value: e.target.value,
                                            },
                                            i,
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                    <div className="form-group col-md-4 mb-2">
                                      <label
                                        htmlFor="example-text-input"
                                        className="col-md-12 col-form-label"
                                      >
                                        Unit of Measurement
                                      </label>
                                      <select
                                        className="form-control form-select select2"
                                        name="UoM"
                                        placeholder="Unit of measurement"
                                        id="example-text-input"
                                        onChange={(e) =>
                                          handleinputchange(
                                            {
                                              name: e.target.name,
                                              value: e.target.value,
                                            },
                                            i,
                                          )
                                        }
                                        required
                                      >
                                        <option>Select measuring unit</option>
                                        <option value="Birds">Birds</option>
                                        <option value="Box(es)">Box(es)</option>
                                        <option value="Kg">kg</option>
                                        <option value="Units">Units</option>
                                        <option value="Animals">Animals</option>
                                        <option value="Bags">Bags</option>
                                        <option value="Tonnes">Tonnes</option>
                                        <option value="Litres">Litres</option>
                                        <option value="Items">Items</option>
                                        <option value="Hectares">
                                          Hectares
                                        </option>
                                        <option value="Acre">Acre</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-md-8">
                                      <label
                                        htmlFor="example-text-input"
                                        className="col-md-12 col-form-label"
                                      >
                                        Notes
                                      </label>
                                      <textarea
                                        type="text"
                                        name="notes"
                                        className="form-control"
                                        onChange={(e) =>
                                          handleinputchange(
                                            {
                                              name: e.target.name,
                                              value: e.target.value,
                                            },
                                            i,
                                          )
                                        }
                                        placeholder="Notes"
                                      />
                                      <hr className="mt-4 mb-3 my-4" />
                                    </div>
                                    <div className="form-group col-md-4 mt-4">
                                      {list.length !== 1 && (
                                        <button
                                          className="btn btn-danger mx-1"
                                          type="button"
                                          onClick={() => handleremove(i)}
                                        >
                                          -
                                        </button>
                                      )}
                                      {list.length - 1 === i && (
                                        <button
                                          className="btn btn-success"
                                          onClick={handleAddClick}
                                        >
                                          +
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>

                          <div className="col-lg-12">
                            <button
                              className="btn btn-sm btn-success"
                              type="submit"
                              onSubmit={handleSubmit}
                            >
                              Create Enterprise
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {alert}
      </div>
      <Footer />
    </div>
  )
}

export default NewEnterpricePage
