export const MANUFACTURER_ORDER_LIST_REQUEST =
  'MANUFACTURER_ORDER_LIST_REQUEST'
export const MANUFACTURER_ORDER_LIST_SUCCESS =
  'MANUFACTURER_ORDER_LIST_SUCCESS'
export const MANUFACTURER_ORDER_LIST_FAIL = 'MANUFACTURER_ORDER_LIST_FAIL'

export const ADD_ORDER_REQUEST = 'ADD_ORDER_REQUEST'
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS'
export const ADD_ORDER_FAIL = 'ADD_ORDER_FAIL'
export const ADD_ORDER_RESET = 'ADD_ORDER_RESET'
